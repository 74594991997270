<template>
  <BaseHasPermission permission="user_group.delete" :permission-level="user.levelId > level">
    <div @click="confirmDialog" class="cursor-pointer" v-bind="$attrs">
      <slot />
    </div>
  </BaseHasPermission>
</template>

<script>
import { useStore } from "vuex";
import useApi from "@use/useApi";
import useConfirmDialog from "@use/useConfirmDialog";

export default {
  name: "DeleteWrapper",

  inheritAttrs: false,

  props: {
    userId: {
      type: Number,
      required: true,
    },
    userLevel: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();

    const user = store.getters["usm/authUser/user"];
    const level = props.userLevel ? props.userLevel : 0;

    const { callApi } = useApi({
      module: "usm",
      method: "deleteUser",
      onSuccess: () => {
        emit("reload");
      },
    });

    const { confirmDialog } = useConfirmDialog({
      message: "usm.users.delete.dialog.message",
      header: "usm.users.delete.dialog.header",
      onAccept: () => {
        callApi({ userId: props.userId });
      },
    });

    return { confirmDialog, user, level };
  },
};
</script>
