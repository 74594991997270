<template>
  <div class="p-field mb-3">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseFormField",

  props: {
    hasLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
