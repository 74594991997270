<template>
  <BaseFormField>
    <BaseFieldLabel :for="id">{{ labelField }}</BaseFieldLabel>

    <Calendar
      showIcon
      :id="id"
      v-bind="$attrs"
      v-model="dateValue"
      :selectionMode="range ? 'range' : 'single'"
      class="w-full p-input-icon-right"
      :class="[{ 'p-inputtext-sm': small && !large }, { 'p-inputtext-lg': large && !small }]"
    />

    <div class="ml-1" :id="`${id}-help`">
      <FieldMessages :messages="messages" v-if="hasMessages && !hasErrors" :error="error" />
      <ErrorMessages :messages="errorMessages" v-if="hasErrors" />
    </div>
  </BaseFormField>
</template>

<script>
import Calendar from "primevue/calendar";
import { random } from "lodash";
import ErrorMessages from "./partials/ErrorMessages.vue";
import FieldMessages from "./partials/FieldMessages.vue";
import useErrorMessages from "@utils/use/useErrorMessages";
import dayjs from "dayjs";

export default {
  name: "DatePicker",

  inheritAttrs: false,

  setup(props) {
    const { errorMessages, hasErrors } = useErrorMessages(props.validation);

    return { errorMessages, hasErrors };
  },

  components: {
    Calendar,
    ErrorMessages,
    FieldMessages,
  },

  props: {
    modelValue: {
      type: undefined,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    small: {
      type: Boolean,
      required: false,
      default: false,
    },

    large: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    validation: {
      type: Object,
      required: false,
      default: () => {},
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    messages: {
      type: Array,
      required: false,
      default: () => [],
    },

    range: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    dateValue: {
      get() {
        return this.modelValue || this.initDate;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    initDate() {
      if (this.range) {
        return [new Date(), new Date(dayjs().add(7, "day"))];
      }

      return new Date();
    },

    id() {
      return `datePicker-${random(100)}`;
    },

    hasLabel() {
      return !!this.label;
    },

    hasMessages() {
      return this.messages.length > 0;
    },

    labelField() {
      const label = this.$t(this.label);
      const requiredLabel = `${label} *`;
      if (this.required) return requiredLabel;

      if (this.validation) {
        if ("required" in this.validation) {
          return requiredLabel;
        }
      }

      return label;
    },
  },
};
</script>
