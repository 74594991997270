<template>
  <DataTable
    :value="teachers"
    lazy
    paginator
    :rows="meta.per_page"
    :totalRecords="meta.total"
    :rowsPerPageOptions="[10, 20, 50]"
    :loading="loading"
    @page="changePage($event)"
    dataKey="id"
    rowHover
    autoLayout
    class="p-datatable-sm"
  >
    <template #empty>
      <div class="text-center py-4">{{ $t("utils.table.empty") }}</div>
    </template>

    <Column field="salutation" :header="$t('tm.teachers.list.header.salutation')">
      <template #body="slotProps">
        <span>
          {{ slotProps.data.salutation }}
        </span>
      </template>
    </Column>

    <Column field="firstName" :header="$t('tm.teachers.list.header.firstName')">
      <template #body="slotProps">
        <span>
          {{ slotProps.data.firstName }}
        </span>
      </template>
    </Column>

    <Column field="lastName" :header="$t('tm.teachers.list.header.lastName')">
      <template #body="slotProps">
        <span>
          {{ slotProps.data.lastName }}
        </span>
      </template>
    </Column>

    <Column field="isActive" :header="$t('tm.teachers.list.header.isActive')">
      <template #body="slotProps">
        <span>
          <BaseIcon
            :class="slotProps.data.isActive ? 'text-success' : 'text-danger'"
            :icon="slotProps.data.isActive ? 'pi pi-check' : 'pi pi-times'"
          />
        </span>
      </template>
    </Column>

    <Column field="userId" :header="$t('tm.teachers.list.header.userId')">
      <template #body="slotProps">
        <span>
          <BaseIcon
            :class="slotProps.data.userId ? 'text-success' : 'text-danger'"
            :icon="slotProps.data.userId ? 'pi pi-check' : 'pi pi-times'"
          />
        </span>
      </template>
    </Column>

    <Column field="createdAt" :header="$t('tm.teachers.list.header.createdAt')">
      <template #body="slotProps">
        {{ dateTime(slotProps.data.createdAt) }}
      </template>
    </Column>

    <Column field="view">
      <template #body="slotProps">
        <BaseIcon icon="pi pi-eye" class="cursor-pointer" @click="view(slotProps.data.id)" />
        <span class="ml-3">
          <BaseDeleteData
            v-if="canDeleteTeacher(slotProps.data.id)"
            update-module="tm"
            update-method="deleteTeacher"
            icon="pi pi-trash"
            :form-data="{ teacherId: slotProps.data.id }"
            :icon-only="true"
            deleteDialogMessage="tm.teachers.delete.dialog.message"
            deleteDialogHeader="tm.teachers.delete.dialog.header"
            outlined
            :on-deleted="refresh"
          />
        </span>
      </template>
    </Column>

    <template #paginatorLeft>
      <BaseButton
        icon="pi pi-refresh"
        rounded
        background="gray-100"
        color="gray-500"
        :loading="loading"
        @click="refresh($event)"
        v-tooltip="$t('tm.teachers.list.tooltip.refresh')"
      />
    </template>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import dateFilter from "@filter/dateFilter";
import { TEACHER_NOT_DELETABLE } from "../../../../../constants/TEACHER_NOT_DELETABLE";

export default {
  name: "ListTable",

  mixins: [dateFilter],

  components: {
    DataTable,
    Column,
  },

  props: {
    teachers: {
      type: Array,
      required: true,
      default: () => [],
    },

    meta: {
      type: Object,
      required: true,
      default: () => {
        return {
          // eslint-disable-next-line camelcase
          current_page: null,
          // eslint-disable-next-line camelcase
          per_page: null,
          total: null,
        };
      },
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    callApi: {
      type: Function,
      required: false,
    },
  },

  methods: {
    refresh() {
      this.callApi({ page: this.meta.current_page, itemsPerPage: this.meta.per_page });
    },

    changePage(event) {
      this.callApi({ page: event.page + 1, itemsPerPage: event.rows });
    },

    view(teacherId) {
      this.$router.push({ name: "ManagementTeacherShow", params: { teacherId } });
    },

    canDeleteTeacher(teacherId) {
      return !TEACHER_NOT_DELETABLE.includes(teacherId);
    },
  },
};
</script>
