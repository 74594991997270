import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEmpty } from "lodash";

/**
 * Perform API calls
 *
 * @param {object} params
 * @param {string} module Module name that is defined in api.js
 * @param {string} method Method name
 * @param {boolean} onMount Whether the api call should be performed on mount or not
 * @param {object} params Payload for api call
 * @param {boolean} showQueries Whether query params should be shown/loaded in/from url or not
 * @param {array} ignoreQueries Specify query params that should not be shown/loaded in/from url
 * @param {function} onSuccess Callback after api was called successfully
 * @param {function} onError Callback after api was called unsuccessfully
 * @param {function} onFinally Callback after api was called
 * @param {boolean} consoleLog Whether or not to show error as console.log()
 * @returns {object} { isLoading, callCount, callApi, docs, hasDocs, backendValidation}
 */
const useApi = ({
  module,
  method,
  onMount,
  params = {},
  onSuccess,
  onError,
  onFinally,
  consoleLog,
  showQueries,
  ignoreQueries = [],
}) => {
  const isLoading = ref(false);
  const callCount = ref(0);
  const docs = ref(null);
  const hasDocs = ref(false);
  const backendValidation = ref(null);
  const error = ref(null);
  const { $axios } = window;

  const router = useRouter();
  const route = useRoute();
  const { query } = route;

  /**
   * Call api
   *
   * @param {object} localParams
   * @param {boolean} shouldThrowError
   * @returns {any}
   */
  const callApi = async (localParams = {}, shouldThrowError = false) => {
    if (!(module in $axios))
      return console.error(
        `useApi: Module '${module}' was not found in $axios. Please provive valid module name.`
      );

    if (!(method in $axios[module]))
      return console.error(
        `useApi: Method '${method}' was not found in $axios. Please provide valid method name.`
      );

    isLoading.value = true;

    try {
      const removeIgnoredQueries = (params) => {
        if (params && !isEmpty(params)) {
          params = Object.keys(params)
            .filter((key) => !ignoreQueries.includes(key))
            .reduce((obj, key) => {
              obj[key] = params[key];

              return obj;
            }, {});
        }

        return params ?? {};
      };

      const parameters = () => {
        if (!showQueries) {
          return { ...params, ...localParams };
        }

        let queryParams = removeIgnoredQueries(query);

        return { ...queryParams, ...params, ...localParams };
      };

      if (showQueries) {
        await router.replace({ name: route.name, query: removeIgnoredQueries(parameters()) });
      }

      const { data } = await $axios[module][method](parameters());

      hasDocs.value = !!data;
      docs.value = data;
      onSuccess && onSuccess();

      return Promise.resolve();
    } catch (err) {
      if (err.response && err.response.status === 422) {
        backendValidation.value = err.response.data;
      }
      error.value = err;
      consoleLog && console.log(err);
      onError && onError();

      if (shouldThrowError) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    } finally {
      isLoading.value = false;
      callCount.value += 1;
      onFinally && onFinally();
    }
  };

  onMounted(async () => {
    if (onMount) {
      await callApi();
    }
  });

  return {
    isLoading,
    callCount,
    callApi,
    docs,
    hasDocs,
    backendValidation,
    error,
  };
};

export default useApi;
