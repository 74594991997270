<template>
  <BaseButton
    label="acm.management.courseRequests.show.approve.button"
    @click="confirmDialog"
    :loading="isLoading"
    :disabled="!canApprove"
    icon-left="pi pi-check"
  />
</template>

<script>
import useApi from "@use/useApi";
import { useRouter } from "vue-router";
import useNotifications from "@use/useNotifications";
import useConfirmDialog from "@use/useConfirmDialog";

export default {
  props: {
    courseRequest: {
      type: Object,
      required: true,
    },
    canApprove: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const { showSuccess } = useNotifications();

    const { isLoading, callApi } = useApi({
      module: "acm",
      method: "managementApproveCourseRequest",
      params: {
        courseRequestId: props.courseRequest.id,
      },
      onSuccess() {
        showSuccess(
          "acm.management.courseRequests.show.approve.success.summary",
          "acm.management.courseRequests.show.approve.success.details"
        );
        router.back();
      },
    });

    const { confirmDialog } = useConfirmDialog({
      header: "acm.management.courseRequests.show.approve.submit.header",
      message: "acm.management.courseRequests.show.approve.submit.message",
      onAccept: async () => await callApi(),
    });

    return {
      isLoading,
      confirmDialog,
    };
  },
};
</script>
