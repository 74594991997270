<template>
  <div v-if="url" class="thumb-wrapper">
    <img :src="url" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb-wrapper {
  max-width: 300px;
  width: 95%;

  img {
    width: 100%;
  }
}
</style>
