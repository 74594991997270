import axios from "axios";
import store from "@/store";
import router from "@/router";

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
    "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization",
  },
});

api.interceptors.request.use((req) => {
  req.headers.authorization = `Bearer ${store.getters["usm/auth/accessToken"]}`;

  return req;
});

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response) {
      const { response } = err;
      const { status } = response;
      if (response) {
        switch (status) {
          case 401:
            router.push({ name: "Login" });
            break;

          case 500:
            router.push({ name: "ServerError" });
            break;

          default:
            Promise.reject(err);
            break;
        }
      }
    }

    return Promise.reject(err);
  }
);

export default api;
