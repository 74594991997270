<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseBackButton />

      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12" v-if="isLoading" class="text-center py-4">
            <BaseProgressSpinner />
          </BaseCol>

          <template v-else-if="course">
            <BaseCol cols="12">
              <BaseHeadline size="1" fake="4">
                {{ $t("acm.management.courses.show.title", { title: course.title }) }}
              </BaseHeadline>
            </BaseCol>

            <BaseCol cols="12">
              <UpdateForm :course="course" />
            </BaseCol>
          </template>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import { ref, watch } from "vue";
import useApi from "@use/useApi";
import UpdateForm from "./partials/UpdateForm";
import HAWPageContent from "@/modules/haw-components-hac/Base/HAWPageContent.vue";
import { useRoute } from "vue-router";

export default {
  name: "ManagementCourseShow",

  components: {
    UpdateForm,
    HAWPageContent,
  },

  setup() {
    const course = ref(null);
    const route = useRoute();

    const { isLoading, docs, callApi } = useApi({
      module: "acm",
      method: "managementShowCourse",
      onMount: true,
      params: {
        courseId: route.params.courseId,
      },
      onSuccess: () => {
        course.value = docs.value.data;
      },
    });

    watch(
      () => route.params.courseId,
      () => {
        if (route.params.courseId) {
          callApi({
            courseId: route.params.courseId,
          });
        }
      }
    );

    return { course, isLoading };
  },
};
</script>
