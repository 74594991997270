<template>
  <BaseIcon
    :icon="isLoading ? 'pi pi-spinner' : icon"
    class="text-danger cursor-pointer"
    v-if="icon && iconOnly && confirmation"
    @click="confirmDialog"
  />
  <BaseIcon
    :icon="isLoading ? 'pi pi-spinner' : icon"
    class="text-danger cursor-pointer"
    v-if="icon && iconOnly && !confirmation"
    @click="deleteData"
  />
  <BaseButton
    class="p-button-danger"
    :class="{ 'p-button-outlined': outlined }"
    v-if="icon && !iconOnly && confirmation"
    @click="confirmDialog"
    :icon="icon"
    :label="buttonLabel ? $t(buttonLabel) : ''"
    :loading="isLoading"
  />
  <BaseButton
    class="p-button-danger"
    :class="{ 'p-button-outlined': outlined }"
    v-if="icon && !iconOnly && !confirmation"
    @click="deleteData"
    :icon="icon"
    :loading="isLoading"
  />
  <BaseRow v-if="!icon" class="justify-content-end align-content-center">
    <BaseCol cols="auto">
      <BaseButton
        class="p-button-danger"
        :class="{ 'p-button-outlined': outlined }"
        v-if="confirmation"
        @click="confirmDialog"
        type="text"
        :label="buttonLabel ? $t(buttonLabel) : ''"
        :loading="isLoading"
      />
      <BaseButton
        class="p-button-danger"
        :class="{ 'p-button-outlined': outlined }"
        v-else
        @click="deleteData"
        type="text"
        :icon="icon"
        :label="buttonLabel ? $t(buttonLabel) : ''"
        :loading="isLoading"
      />
    </BaseCol>
  </BaseRow>
</template>

<script>
import useApi from "@use/useApi";
import useConfirmDialog from "@use/useConfirmDialog";
import useNotifications from "@use/useNotifications";

export default {
  name: "BaseDeleteData",

  props: {
    updateModule: {
      type: String,
      required: true,
    },
    updateMethod: {
      type: String,
      required: true,
    },
    /**
     * formData can either be an data object or a method returning a data object
     */
    formData: {
      required: false,
      default: null,
    },
    buttonLabel: {
      type: String,
      required: false,
    },
    deleteDialogMessage: {
      type: String,
    },
    deleteDialogHeader: {
      type: String,
    },
    confirmation: {
      type: Boolean,
      default: true,
    },
    onDeleted: {
      type: Function,
    },
    icon: {
      type: String,
      required: false,
    },
    iconOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    // Setup notification
    const { showError } = useNotifications();

    const { callApi, backendValidation, isLoading } = useApi({
      module: props.updateModule,
      method: props.updateMethod,
      onSuccess: () => {
        emit("reload");
      },
      onError: () => showError(backendValidation.value.message, ""),
    });

    const { confirmDialog } = useConfirmDialog({
      message: props.deleteDialogMessage,
      header: props.deleteDialogHeader,
      onAccept: async () => {
        await deleteData();
        props.onDeleted && props.onDeleted();
      },
    });

    const deleteData = async () => {
      if (typeof props.formData === "function") {
        await callApi(props.formData());
      } else if (props.formData) {
        await callApi(props.formData);
      } else {
        await callApi();
      }
    };

    return {
      deleteData,
      confirmDialog,
      isLoading,
    };
  },
};
</script>
