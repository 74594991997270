<template>
  <div @mouseover="hover = true" @mouseleave="hover = false" v-bind="$attrs">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseHover",

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    hover: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
