<template>
  <div class="video-viewer-container">
    <VideoViewer :thumbnail="thumbnail" :video="video" />
  </div>
</template>

<script>
import VideoViewer from "@/modules/haw-components-hac/components/VideoViewer";

export default {
  components: { VideoViewer },
  props: {
    thumbnail: {
      type: String,
      required: false,
      default: null,
    },
    video: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.video-viewer-container {
  width: 75%;
  max-width: 600px;
  margin: 0 auto;
}
</style>
