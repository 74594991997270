<template>
  <EditDialog
    update-module="usm"
    update-method="updateRole"
    :validation="v$"
    :form-values="formValues"
    dialog-header="usm.users.update.data.base.title"
    :onHide="callApi"
  >
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.name"
          label="usm.roles.update.form.label.name"
          :validation="v$.name"
        />
      </BaseCol>
    </BaseRow>
  </EditDialog>
</template>

<script>
import { reactive } from "vue";
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import { useRoute } from "vue-router";
import EditDialog from "../../edit";

export default {
  name: "UpdateForm",

  components: {
    EditDialog,
  },

  setup() {
    // Setup validation
    const rules = {
      name: ["required"],
    };

    // Load data
    const route = useRoute();
    const { roleId } = route.params;

    const form = reactive({
      name: null,
      roleId,
    });

    const { docs, hasDocs, callApi } = useApi({
      module: "usm",
      method: "showRole",
      onMount: true,
      params: { roleId },
      onSuccess: () => {
        form.name = docs.value.name;
      },
    });

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const updateRole = () => {
      callApi({ formValues });
    };

    return {
      v$,
      formValues,
      hasDocs,
      callApi,
      updateRole,
    };
  },
};
</script>
