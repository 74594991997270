const loadLocaleMessages = () => {
  const regex = new RegExp(/(lang_)([a-z]*)([_]*)([A-Z]*)(\.json*)/);

  const locales = require.context("../../../", true, /(lang_)([a-z]*)([_]*)([A-Z]*)(\.json*)/);
  const messages = {};

  locales.keys().forEach((key) => {
    const matches = key.match(regex);
    const locale = matches[2];

    if (locale in messages) {
      messages[locale] = {
        ...messages[locale],
        ...locales(key),
      };
    } else {
      if ("default" in locales(key)) {
        messages[locale] = locales(key).default;
      } else {
        messages[locale] = locales(key);
      }
    }
  });

  return messages;
};

export default loadLocaleMessages;
