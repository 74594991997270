<template>
  <p v-bind="$attrs" class="line-height-3" :class="classes" :style="fontColor">
    <slot />
  </p>
</template>

<script>
export default {
  name: "BaseText",

  props: {
    size: {
      type: String,
      required: false,
    },

    dark: {
      type: Boolean,
      required: false,
      default: false,
    },

    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    classes() {
      return `${this.sizeClass} ${this.marginClass}`;
    },

    sizeClass() {
      if (this.size) {
        return `text-${this.size}`;
      }

      return "";
    },

    marginClass() {
      if (!this.noMargin) {
        return "mb-4";
      }

      return "";
    },

    fontColor() {
      return {
        color: this.dark ? "var(--text-white)" : "var(--text-color-secondary)",
      };
    },
  },
};
</script>
