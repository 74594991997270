<template>
  <ExternalVideoViewer :type-id="item.model.courseExternalVideoTypeId" :url="item.model.url" />
</template>

<script>
import ExternalVideoViewer from "@/modules/haw-components-hac/components/ExternalVideoViewer";

export default {
  components: { ExternalVideoViewer },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({
        model: {
          courseExternalVideoTypeId: null,
          url: null,
        },
      }),
    },
  },
};
</script>
