<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.courses.create.form.label.courseTitle"
          v-model="formValues.title"
          :validation="v$.title"
          :messages="bvShowErrors('title').messages"
          :error="bvShowErrors('title').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextArea
          label="acm.courses.create.form.label.courseDescription"
          v-model="formValues.description"
          :validation="v$.description"
          :messages="bvShowErrors('description').messages"
          :error="bvShowErrors('description').hasMessages"
          floatingLabel
          rows="12"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="acm.courses.create.form.label.submit" @click="submit" :loading="loading" />
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import { onMounted, reactive, watch } from "vue";
import useCaching from "@use/useCaching";
import useBackendValidation from "@use/useBackendValidation";

export default {
  name: "CreateForm",

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    backendValidation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    // Initialize form values
    const form = reactive({
      title: null,
      description: null,
    });

    // Setup validation
    const rules = {
      title: ["required"],
      description: ["required"],
    };

    const { updateValidation, bvMessage, bvHasErrors, bvShowErrors } = useBackendValidation();
    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    // Emit form values
    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      emit("submit", formValues);
      v$.value.$reset();
    };

    const { updateCache, cachedState } = useCaching();

    watch(formValues, () => {
      updateCache(formValues);
    });

    watch(props, () => {
      if (props.backendValidation) {
        updateValidation(props.backendValidation);
      }
    });

    const resetHandler = () => {
      form.title = null;
      form.description = null;
    };

    onMounted(() => {
      if (cachedState.value) {
        form.title = cachedState.value.title;
        form.description = cachedState.value.description;
      }
      emit("resetHandler", resetHandler);
    });

    return {
      v$,
      formValues,
      submit,
      bvMessage,
      bvHasErrors,
      bvShowErrors,
    };
  },
};
</script>
