<template>
  <BaseContentPreview
    v-for="(preview, index) in chapterItems"
    :key="index"
    :preview-data="preview"
  />
</template>

<script>
import { ref, watch } from "vue";
import useApi from "@use/useApi";
import { getContentType, getContentPreview, getPreviewRoute } from "../contentTypes";
import { useRoute } from "vue-router";

export default {
  name: "ChapterItems",

  setup() {
    const route = useRoute();
    const { courseRequestChapterId } = route.params;
    const chapterItems = ref([]);

    const { docs, hasDocs, isLoading, callApi } = useApi({
      module: "acm",
      method: "listCourseRequestChapterItems",
      onMount: true,
      params: { courseRequestChapterId },
      onSuccess: () => {
        if (hasDocs) {
          // no double items
          chapterItems.value = [];
          docs.value.data.forEach((itemPreview) => {
            const previewData = {
              courseRequestChapterItemId: itemPreview.id,
              displayName: itemPreview.displayName,
              contentType: getContentType(itemPreview.modelType),
              preview: getContentPreview(itemPreview.modelType, itemPreview.model),
              routeName: getPreviewRoute(itemPreview.modelType),
            };
            chapterItems.value.push(previewData);
          });
        }
      },
    });

    watch(route, () => {
      let courseRequestChapterId = route.params.courseRequestChapterId;
      chapterItems.value = [];

      if (!courseRequestChapterId) {
        return;
      }

      callApi({ courseRequestChapterId });
    });

    return {
      isLoading,
      chapterItems,
    };
  },
};
</script>
