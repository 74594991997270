<template>
  <i :class="icon" v-bind="$attrs" :style="{ ...iconSize, ...iconColor }"></i>
</template>

<script>
export default {
  name: "BaseIcon",

  props: {
    icon: {
      type: String,
      required: true,
    },

    size: {
      type: String,
      required: false,
      default: "1",
    },

    color: {
      type: String,
      required: false,
      default: "text-color-secondary",
    },
  },

  computed: {
    iconSize() {
      return { fontSize: `${this.size}rem` };
    },

    iconColor() {
      return { color: `var(--${this.color})` };
    },
  },
};
</script>
