<template>
  <BaseCard class="p-card--flat">
    <BaseHeadline size="1" fake="5">
      {{ item.model.displayName }}
    </BaseHeadline>

    <BaseRow>
      <QuizQuestion
        v-for="(question, index) in item.model.courseRequestQuizQuestions"
        :key="question.id"
        :question="question"
        :index="index"
        :count="item.model.courseRequestQuizQuestions.length"
      />
    </BaseRow>
  </BaseCard>
</template>

<script>
import QuizQuestion from "./partials/QuizQuestion";
export default {
  components: { QuizQuestion },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({
        model: {
          content: null,
        },
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.p-card--flat {
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>
