<template>
  <QuestionPreview
    v-for="(preview, index) in questionItems"
    :key="index"
    :question-data="preview"
  />
</template>

<script>
import QuestionPreview from "./QuestionPreview";

export default {
  name: "QuestionItems",

  components: {
    QuestionPreview,
  },

  props: {
    questionItems: {
      type: Array,
      required: true,
    },
  },
};
</script>
