<template>
  <BasePageContent>
    <template #header>
      <BaseHeadline size="2" fake="5" noMargin>{{ $t("usm.roles.update.title") }}</BaseHeadline>
    </template>

    <BaseRow no-gutter>
      <BaseCol cols="auto" class="flex align-items-center">
        <BaseHeadline no-margin size="2" fake="5" v-if="hasDocs">{{
          $t("usm.roles.show.title", { name: role.name })
        }}</BaseHeadline>
      </BaseCol>
      <BaseCol class="flex flex-grow-0 align-items-center ml-5">
        <BaseHasPermission permission="role.update">
          <UpdateForm @reload="refresh" />
        </BaseHasPermission>
      </BaseCol>
    </BaseRow>

    <BaseHasPermission permission="role.update">
      <TabView class="shadow-4 mt-6">
        <TabPanel :header="$t('usm.roles.assign.tabTitle.users')">
          <BaseHeadline size="2" fake="6">
            {{ $t("usm.roles.assign.subTitle.users") }}
          </BaseHeadline>
          <AssignUsers :roleId="roleId" />
        </TabPanel>
        <TabPanel :header="$t('usm.roles.assign.tabTitle.userGroups')">
          <BaseHeadline size="2" fake="6">
            {{ $t("usm.roles.assign.subTitle.userGroups") }}
          </BaseHeadline>
          <AssignUserGroups :roleId="roleId" />
        </TabPanel>
        <TabPanel :header="$t('usm.roles.assign.tabTitle.permissions')">
          <BaseHeadline size="2" fake="5">
            {{ $t("usm.roles.assign.subTitle.permissions") }}
          </BaseHeadline>
          <AssignPermissions :roleId="roleId" />
        </TabPanel>
      </TabView>
    </BaseHasPermission>
  </BasePageContent>
</template>

<script>
import useApi from "@use/useApi";
import UpdateForm from "./partials/UpdateForm.vue";
import { useRoute } from "vue-router";
import useNotifications from "@use/useNotifications";
import AssignUserGroups from "../assign/UserGroups";
import AssignPermissions from "../assign/Permissions";
import AssignUsers from "../assign/Users";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { ref } from "vue";

export default {
  name: "RoleUpdate",

  components: {
    UpdateForm,
    AssignPermissions,
    AssignUsers,
    AssignUserGroups,
    TabView,
    TabPanel,
  },

  setup() {
    const { showSuccess } = useNotifications();

    const role = ref(null);
    const route = useRoute();
    const { roleId } = route.params;

    const { hasDocs, docs, callApi } = useApi({
      module: "usm",
      method: "showRole",
      onMount: true,
      params: { roleId: parseInt(roleId, 10) },
      onSuccess: () => {
        role.value = docs.value;
      },
    });

    const refresh = async () => {
      await callApi();
      showSuccess(
        "usm.users.update.notification.success.summary",
        "usm.users.update.notification.success.detail"
      );
    };

    return { role, roleId, hasDocs, refresh };
  },
};
</script>
