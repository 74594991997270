<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />

      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12" class="flex justify-content-between">
            <BaseHeadline size="2" fake="5">
              {{ $t("acm.answers.update.title") }}
            </BaseHeadline>
            <div>
              <BaseDeleteData
                update-module="acm"
                update-method="deleteCourseRequestChapterItemQuizAnswer"
                :formData="{ courseRequestQuizQuestionAnswerId }"
                icon="pi pi-trash"
                deleteDialogMessage="acm.answers.delete.dialog.message"
                deleteDialogHeader="acm.answers.delete.dialog.header"
                outlined
                :onDeleted="goBack"
              />
            </div>
          </BaseCol>
          <BaseCol cols="12">
            <UpdateAnswerForm @refresh="goBack" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import UpdateAnswerForm from "./partials/UpdateAnswerForm";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

export default {
  name: "ViewVideo",

  components: {
    HAWPageContent,
    UpdateAnswerForm,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const courseRequestQuizQuestionAnswerId = ref(route.params.courseRequestQuizQuestionAnswerId);

    const goBack = () => {
      router.go(-1);
    };

    return {
      courseRequestQuizQuestionAnswerId,
      goBack,
    };
  },
};
</script>
