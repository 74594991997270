<template>
  <Button
    :label="labelField"
    v-bind="$attrs"
    :class="[
      { 'p-button-link': link },
      hasType && typeClassName,
      { 'p-button-raised': raised },
      { 'p-button-rounded': rounded },
      { 'p-button-text': text && !outlined },
      { 'p-button-outlined': outlined && !text },
      { 'p-button-sm': small && !large },
      { 'p-button-lg': large && !small },
      { 'w-full': fullWidth },
    ]"
    :iconPos="iconPosition"
    :icon="iconLeft || iconRight"
    :style="styles"
    :type="type"
  />
</template>

<script>
import Button from "primevue/button/sfc";

export default {
  name: "BaseButton",

  components: {
    Button,
  },

  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },

    link: {
      type: Boolean,
      required: false,
      default: false,
    },

    iconLeft: {
      type: String,
      required: false,
    },

    iconRight: {
      type: String,
      required: false,
    },

    type: {
      type: String,
      required: false,
    },

    raised: {
      type: Boolean,
      required: false,
      default: false,
    },

    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },

    text: {
      type: Boolean,
      required: false,
      default: false,
    },

    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },

    small: {
      type: Boolean,
      required: false,
      default: false,
    },

    large: {
      type: Boolean,
      required: false,
      default: false,
    },

    background: {
      type: String,
      required: false,
    },

    color: {
      type: String,
      required: false,
    },

    fullWidth: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    hasIconLeft() {
      return !!this.iconLeft;
    },

    hasIconRight() {
      return !!this.iconRight;
    },

    iconPosition() {
      if (this.hasIconLeft) return "left";
      if (this.hasIconRight) return "right";

      return null;
    },

    hasType() {
      return !!this.type;
    },

    typeClassName() {
      return `p-button-${this.type}`;
    },

    labelField() {
      if (this.label) {
        return this.$t(this.label);
      }

      return null;
    },

    styles() {
      const colors = {};

      if (this.color && !this.type) {
        colors.color = `var(--${this.color})`;
      }

      if (this.background && !this.type) {
        colors.background = `var(--${this.background})`;
        colors.borderColor = `var(--${this.background})`;
      }

      return colors;
    },
  },
};
</script>
<style>
.p-button-label {
  font-weight: bold;
}
</style>
