<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseCheckbox
          label="acm.management.courses.show.label.isActive"
          v-model="formValues.isActive"
          :validation="v$.isActive"
          :messages="bvShowErrors('isActive').messages"
          :error="bvShowErrors('isActive').hasMessages"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="acm.management.courses.show.submit" @click="submit" :loading="isLoading" />
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import { reactive, watch } from "vue";
import useBackendValidation from "@use/useBackendValidation";
import useApi from "@use/useApi";
import { useRouter } from "vue-router";

export default {
  name: "CreateForm",

  props: {
    course: {
      type: Object,
      required: true,
      default: null,
    },
  },

  setup(props) {
    const router = useRouter();

    const form = reactive({
      isActive: props.course.isActive,
    });

    const rules = {
      isActive: ["required"],
    };

    const { updateValidation, bvMessage, bvHasErrors, bvShowErrors } = useBackendValidation();
    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { isLoading, callApi, backendValidation } = useApi({
      module: "acm",
      method: "managementUpdateCourse",
      params: {
        courseId: props.course.id,
      },
      onSuccess: async () => {
        await router.push({
          name: "ManagementCourseList",
        });
      },
    });

    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
      v$.value.$reset();
    };

    watch(backendValidation, () => {
      updateValidation(backendValidation.value);
    });

    return {
      v$,
      formValues,
      submit,
      bvMessage,
      bvHasErrors,
      bvShowErrors,
      isLoading,
    };
  },
};
</script>
