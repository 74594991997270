import * as auth from "./auth";
import * as profile from "./profile";
import * as courses from "./courses";
import * as courseChapters from "./courseChapters";
import * as courseChapterItems from "./courseChapterItems";
import * as courseQuiz from "./courseQuiz";
import * as carts from "./carts";
import * as topics from "./topics";

export const module = "uc";

export default {
  ...auth,
  ...profile,
  ...courses,
  ...courseChapters,
  ...courseChapterItems,
  ...carts,
  ...topics,
  ...courseQuiz,
};
