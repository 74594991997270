import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Retrieve a list of all courses
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourses = (params) => api.get(ROUTE.LIST, { params });

/**
 * Retrieve specified course
 * @method
 * @param {object} params
 * @param {number} params.courseId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourse = ({ courseId }) => api.get(ROUTE.SHOW, { params: { courseId } });

/**
 * Retrieve the course with the latest opened chapter
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showLatestCourse = () => api.get(ROUTE.SHOW_LATEST);
