import api from "@/api";
import ROUTE from "../../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve information material
 * @method
 * @param {string} material
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateInformationMaterial = ({ material }) =>
  api.put(ROUTE.MANAGEMENT.INFORMATION_MATERIAL.UPDATE, { material });
