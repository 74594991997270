import menuType from "@data/menuTypes";

// Module sub pages
import Roles from "../views/Roles";
import RolesList from "../views/Roles/list";
import RoleCreate from "../views/Roles/create";
import RoleUpdate from "../views/Roles/update";

import UserGroups from "../views/UserGroups";
import UserGroupsList from "../views/UserGroups/list";
import UserGroupCreate from "../views/UserGroups/create";
import UserGroupUpdate from "../views/UserGroups/update";

import Users from "../views/Users";
import UserList from "../views/Users/list";
import UserUpdate from "../views/Users/update";

// Lazy loading for main routes
const UserManagement = () => import("../views");
const PageNotFound = () => import("../views/PageNotFound");

export default [
  {
    path: "/management",
    component: UserManagement,
    children: [
      {
        path: "roles",
        component: Roles,
        meta: { menuTypes: [menuType.get().administration], displayName: "usm.navigation.roles" },
        children: [
          {
            path: "",
            name: "Roles",
            component: RolesList,
          },
          {
            path: "create",
            name: "RoleCreate",
            component: RoleCreate,
            meta: { displayName: "usm.navigation.roleCreate" },
          },
          {
            path: "update/:roleId",
            name: "RoleUpdate",
            component: RoleUpdate,
            meta: { displayName: "usm.navigation.roleUpdate" },
          },
        ],
      },

      {
        path: "users",
        component: Users,
        meta: {
          menuTypes: [menuType.get().administration],
          displayName: "usm.navigation.users",
        },
        children: [
          {
            path: "",
            name: "User",
            component: UserList,
          },
          {
            path: "update/:userId",
            name: "UserUpdate",
            component: UserUpdate,
            meta: { displayName: "usm.navigation.userUpdate" },
          },
        ],
      },

      {
        path: "user-groups",
        component: UserGroups,
        meta: {
          menuTypes: [menuType.get().administration],
          displayName: "usm.navigation.userGroups",
        },
        children: [
          {
            path: "",
            name: "UserGroups",
            component: UserGroupsList,
          },
          {
            path: "create",
            name: "UserGroupCreate",
            component: UserGroupCreate,
            meta: { displayName: "usm.navigation.userGroupCreate" },
          },
          {
            path: "update/:userGroupId",
            name: "UserGroupUpdate",
            component: UserGroupUpdate,
            meta: { displayName: "usm.navigation.userGroupUpdate" },
          },
        ],
      },
    ],
  },

  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { menuTypes: [menuType.get().public] },
  },
];
