<template>
  <HAWPageContent :breadcrumb="false">
    <BaseHeadline size="1" fake="4">{{ $t("acm.courseProposals.title") }}</BaseHeadline>
    <ListTable
      v-if="isLoading || courseProposals.length > 0"
      :courseProposals="courseProposals"
      :meta="meta"
      :callApi="callApi"
      :loading="isLoading"
    />
    <div v-else class="text-center mt-8">
      <p style="color: var(--text-color-secondary)">
        {{ $t("acm.courseProposals.list.noData.label") }}
      </p>
      <BaseHasPermission permission="teacher.course_proposal.create">
        <BaseButton
          label="acm.courseProposals.list.noData.btn"
          class="mt-3"
          @click="proposeCourse"
        />
      </BaseHasPermission>
    </div>
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import HAWPageContent from "../../../../haw-components-hac/Base/HAWPageContent";
import ListTable from "./partials/ListTable.vue";
import { useRouter } from "vue-router";

export default {
  name: "CourseProposalsList",

  components: {
    HAWPageContent,
    ListTable,
  },

  setup() {
    const courseProposals = ref([]);
    const meta = ref({});
    const router = useRouter();

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "acm",
      method: "listCourseProposals",
      onMount: true,
      params: { page: 1, itemsPerPage: 10 },
      showQueries: true,
      onSuccess: () => {
        courseProposals.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    const proposeCourse = () => {
      router.push({ name: "CourseProposalCreate" });
    };

    return { courseProposals, meta, hasDocs, isLoading, callApi, proposeCourse };
  },
};
</script>
