import * as auth from "./auth";
import * as authUser from "./authUser";
import * as permissions from "./permissions";
import * as roles from "./roles";
import * as users from "./users";
import * as userGroups from "./userGroups";
import * as levels from "./levels";
import * as profile from "./profile";

export const module = "usm";

export default {
  ...auth,
  ...authUser,
  ...roles,
  ...permissions,
  ...users,
  ...userGroups,
  ...levels,
  ...profile,
};
