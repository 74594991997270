import menuName from "@data/menuNames";
import menuType from "@data/menuTypes";

export default [
  {
    label: "rp.navigation.reports",
    to: "/reports",
    icon: "pi pi-cog",
    menuName: menuName.get().management,
    types: [menuType.get().all],
    permissions: ["management.number_report.read"],
    order: 0,
  },
];
