<template>
  <span v-bind="$attrs" class="line-height-3" :class="sizeClass" :style="fontColor">
    <slot />
  </span>
</template>

<script>
export default {
  name: "BaseText",

  props: {
    size: {
      type: String,
      required: false,
    },

    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    sizeClass() {
      if (this.size) {
        return `text-${this.size}`;
      }

      return null;
    },

    fontColor() {
      return {
        color: this.dark ? "var(--text-white)" : "var(--text-color-secondary)",
      };
    },
  },
};
</script>
