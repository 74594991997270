import { reactive, computed } from "vue";
import useApi from "@use/useApi";
import nullifyObject from "../helper/nullifyObject";

/**
 * Use user
 *
 * @method
 * @param {object} params
 * @param {number} params.userId
 * @returns {object} { user }
 */
const useUser = ({ userId }) => {
  // Initialize form values
  const userAddress = reactive({
    street: null,
    city: null,
    country: null,
    zipcode: null,
  });

  const userContact = reactive({
    mobile: null,
    phone: null,
  });

  const userForm = reactive({
    salutation: null,
    title: null,
    firstName: null,
    lastName: null,
    email: null,
    userAddress,
    userContact,
    userId: null,
  });

  const adjustedFormValues = computed(() => {
    const formValuesCopy = Object.assign({}, userForm);

    formValuesCopy.userAddress = nullifyObject(userAddress);
    formValuesCopy.userContact = nullifyObject(userContact);

    return formValuesCopy;
  });

  const { docs, hasDocs, callApi } = useApi({
    module: "usm",
    method: "showUser",
    onMount: true,
    params: { userId },
    onSuccess: () => {
      userForm.salutation = docs.value.salutation;
      userForm.title = docs.value.title;
      userForm.firstName = docs.value.firstName;
      userForm.lastName = docs.value.lastName;
      userForm.email = docs.value.email;
      if (docs.value.userAddress) {
        userAddress.street = docs.value.userAddress.street;
        userAddress.city = docs.value.userAddress.city;
        userAddress.country = docs.value.userAddress.country;
        userAddress.zipcode = docs.value.userAddress.zipcode;
      }
      if (docs.value.userContact) {
        userContact.phone = docs.value.userContact.phone;
        userContact.mobile = docs.value.userContact.mobile;
      }
      userForm.userId = docs.value.id;
    },
  });

  return { hasDocs, callApi, userAddress, userContact, userForm, adjustedFormValues };
};

export default useUser;
