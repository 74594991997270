<template>
  <h1
    v-bind="$attrs"
    v-if="hSize === 1"
    :class="[fontSize, sharedClasses, marginClass]"
    :style="fontColor"
  >
    <slot />
  </h1>
  <h2
    v-bind="$attrs"
    v-if="hSize === 2"
    :class="[fontSize, sharedClasses, marginClass]"
    :style="fontColor"
  >
    <slot />
  </h2>
  <h3
    v-bind="$attrs"
    v-if="hSize === 3"
    :class="[fontSize, sharedClasses, marginClass]"
    :style="fontColor"
  >
    <slot />
  </h3>
  <h4
    v-bind="$attrs"
    v-if="hSize === 4"
    :class="[fontSize, sharedClasses, marginClass]"
    :style="fontColor"
  >
    <slot />
  </h4>
  <h5
    v-bind="$attrs"
    v-if="hSize === 5"
    :class="[fontSize, sharedClasses, marginClass]"
    :style="fontColor"
  >
    <slot />
  </h5>
  <h6
    v-bind="$attrs"
    v-if="hSize === 6"
    :class="[fontSize, sharedClasses, marginClass]"
    :style="fontColor"
  >
    <slot />
  </h6>
</template>

<script>
export default {
  name: "BaseHeadline",

  props: {
    size: {
      type: String,
      required: false,
      default: "1",
    },

    fake: {
      type: String,
      required: false,
    },

    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },

    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    hSize() {
      return parseInt(this.size, 10);
    },

    hSizeFake() {
      return parseInt(this.fake, 10);
    },

    sizeClasses() {
      return ["text-6xl", "text-5xl", "text-4xl", "text-3xl", "text-2xl", "text-xl"];
    },

    sharedClasses() {
      return "line-height-2 font-bold";
    },

    marginClass() {
      return { "mb-4": !this.noMargin };
    },

    fontColor() {
      return {
        color: !this.dark ? "var(--text-color)" : "var(--text-color-primary)",
      };
    },

    fontSize() {
      if (this.fake) {
        return this.sizeClasses[this.hSizeFake - 1];
      }

      if (this.size) {
        return this.sizeClasses[this.hSize - 1];
      }

      return this.sizeClasses[0];
    },
  },
};
</script>
