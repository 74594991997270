export const namespaced = true;

export const state = {
  statusFilter: null,
};

export const getters = {
  statusFilter: (state) => state.statusFilter,
};

export const mutations = {
  SET_STATUS_FILTER(state, { courseRequestStatusId }) {
    state.statusFilter = courseRequestStatusId;
  },
};

export const actions = {
  setStatusFilter({ commit }, courseRequestStatusId) {
    return new Promise((resolve) => {
      commit("SET_STATUS_FILTER", { courseRequestStatusId });
      resolve();
    });
  },
};
