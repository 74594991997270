export const namespaced = true;

export const state = {
  user: null,
  permissions: null,
  isLoading: false,
};

export const getters = {
  permissions: (state) => state.permissions && state.permissions.map(({ name }) => name),
  user: (state) => state.user && state.user,
  isLoading: (state) => state.isLoading,
  userFullName: (state) => state.user && `${state.user.firstName} ${state.user.lastName}`,
};

export const mutations = {
  SET_AUTH_USER_DATA(state, { user, permissions }) {
    state.user = user;
    state.permissions = permissions;
  },
  SET_IS_LOADING(state, { isLoading }) {
    state.isLoading = isLoading;
  },
};

export const actions = {
  saveData({ commit }, { user, permissions }) {
    return new Promise((resolve) => {
      commit("SET_AUTH_USER_DATA", { user, permissions });
      resolve();
    });
  },

  setIsLoading({ commit }, { isLoading }) {
    return new Promise((resolve) => {
      commit("SET_IS_LOADING", { isLoading });
      resolve();
    });
  },
};
