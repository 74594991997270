export const COURSE_PROPOSAL_STATUS = {
  1: "OPEN",
  2: "REVIEW",
  3: "APPROVED",
  4: "DISMISSED",
};

export default {
  OPEN: 1,
  REVIEW: 2,
  APPROVED: 3,
  DISMISSED: 4,
};
