<template>
  <BaseRow>
    <BaseCol cols="12">
      <BaseTextField
        label="acm.courseChapterItems.displayName"
        v-model="formValues.displayName"
        :validation="v$.displayName"
        floatingLabel
      />
    </BaseCol>
    <BaseCol cols="12">
      <ConfiguredRte
        :model-value="formValues.content"
        :validation="v$.content"
        :on-change="updateModel"
      />
    </BaseCol>
  </BaseRow>
  <BaseRow class="justify-content-end">
    <BaseCol class="flex-grow-0">
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </BaseCol>
  </BaseRow>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ConfiguredRte from "@/modules/haw-components-hac/Base/ConfiguredRte";

export default {
  name: "UpdateRTEContent",
  components: { ConfiguredRte },
  props: {
    rteInformation: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { showSuccess } = useNotifications();
    const router = useRouter();
    const store = useStore();

    // Initialize form values
    const form = reactive(props.rteInformation);

    // Setup validation
    const rules = {
      courseRequestContentId: ["required"],
      displayName: ["required"],
      content: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading } = useApi({
      module: "acm",
      method: "updateCourseRequestChapterItemContent",
      data: formValues,
      onSuccess: () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        store.dispatch("acm/chapters/reloadChapterList", true);
        router.go(-1);
      },
    });

    const updateModel = (event) => {
      form.content = event.htmlValue;
    };

    // Emit form values
    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      callApi(formValues);
    };

    return {
      v$,
      isLoading,
      formValues,
      updateModel,
      submit,
    };
  },
};
</script>
