<template>
  <BaseRow class="justify-content-end mt-8 p-2">
    <BaseCol cols="shrink" class="mr-1">
      <ApproveButton :can-approve="canApprove" :course-request="courseRequest" />
    </BaseCol>
    <BaseCol cols="shrink">
      <DismissButton
        :can-dismiss="canDismiss"
        :course-request="courseRequest"
        :course-request-chapter-ids="dismissed"
      />
    </BaseCol>
  </BaseRow>
</template>

<script>
import { computed, reactive } from "vue";
import useApi from "@use/useApi";
import useNotifications from "@use/useNotifications";
import { useRouter } from "vue-router";
import ApproveButton from "./ApproveButton";
import DismissButton from "./DismissButton";

export default {
  components: { DismissButton, ApproveButton },
  props: {
    courseRequest: {
      type: Object,
      required: true,
      default: () => ({
        courseRequestChapters: [],
      }),
    },
    approved: {
      type: Array,
      required: true,
      default: () => [],
    },
    dismissed: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  setup(props) {
    const { showSuccess } = useNotifications();
    const router = useRouter();

    const courseRequest = reactive(props.courseRequest);
    const approved = reactive(props.approved);
    const dismissed = reactive(props.dismissed);

    const canApprove = computed(() => {
      return approved.length === courseRequest.courseRequestChapters.length;
    });

    const canDismiss = computed(() => {
      return approved.length + dismissed.length === courseRequest.courseRequestChapters.length;
    });

    const dismissApi = useApi({
      module: "acm",
      method: "managementDismissCourseRequest",
      params: {
        courseRequestId: courseRequest.id,
        courseRequestChapterIds: dismissed,
        message: null,
      },
      onSuccess() {
        showSuccess(
          "acm.management.courseRequests.show.dismiss.success.summary",
          "acm.management.courseRequests.show.dismiss.success.details"
        );
        router.back();
      },
    });

    const isLoading = computed(() => {
      return dismissApi.isLoading;
    });

    return {
      canApprove,
      canDismiss,
      isLoading,
      dismissApi,
    };
  },
};
</script>
