import { nextTick, ref, watch } from "vue";
import useApi from "@use/useApi";
import { xorBy } from "lodash";
/**
 * Provides values for PrimeVue Picklist
 *
 * @method
 * @param {object} params
 * @param {number} params.itemId Id of item that is edited
 * @param {string} params.syncMethod Sync method of item
 * @param {string} params.syncModule Module where the sync method can be found
 * @param {string} params.syncIdsProperty Property name which will contain the ids which will be assigned to the item
 * @param {string} params.syncItemIdProperty Property name for item id
 * @param {string} params.listModule Api module which contains list method
 * @param {string} params.listMethod List method which lists available values to assign to item
 * @param {string} params.itendifier="id" Property name of id
 * @param {string} params.listFilterProperty Property name to filter assigned values
 * @returns {object} { picklistAssignableValues, isLoading, updateList }
 */
const useAssigner = ({
  itemId,
  syncMethod,
  syncModule,
  syncIdsProperty,
  syncItemIdProperty,
  listModule,
  listMethod,
  identifier = "id",
  listFilterProperty,
} = {}) => {
  const pickListValues = ref([[], []]);
  const assignedValues = ref([]);
  const availableValues = ref([]);
  const ready = ref(false);
  const isLoading = ref(false);
  const lockAvailableList = ref(false);

  const syncItemWithValues = useApi({
    module: syncModule,
    method: syncMethod,
  });

  watch(pickListValues, () => {
    if (ready.value && !lockAvailableList.value) {
      syncItemWithValues.callApi({
        [syncItemIdProperty]: itemId,
        [syncIdsProperty]: pickListValues.value[1].map((item) => item[identifier]),
      });
    }
  });

  const initValues = () => {
    if (!lockAvailableList.value) {
      pickListValues.value = [
        xorBy(assignedValues.value, availableValues.value, identifier),
        assignedValues.value,
      ];
      nextTick(() => {
        ready.value = true;
      });
    }
  };

  const listAssignedValues = useApi({
    module: listModule,
    method: listMethod,
    onMount: true,
    params: { itemsPerPage: 9999, page: 1, [listFilterProperty]: [itemId] },
    onSuccess: () => {
      isLoading.value = true;
      assignedValues.value = listAssignedValues.docs.value.data;
      listAssignableValues.callApi();
    },
  });

  const listAssignableValues = useApi({
    module: listModule,
    method: listMethod,
    params: { itemsPerPage: 9999, page: 1 },
    onSuccess: () => {
      availableValues.value = listAssignableValues.docs.value.data;
      nextTick(() => {
        initValues();
        isLoading.value = false;
      });
    },
  });

  const updateList = (params) => {
    lockAvailableList.value = true;

    nextTick(async () => {
      await listAssignableValues.callApi(params);
    });

    lockAvailableList.value = false;
  };

  return {
    pickListValues,
    isLoading,
    updateList,
  };
};

export default useAssigner;
