import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve a list of all chapter items
 *
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseRequestChapterItems = (params) =>
  api.get(ROUTE.COURSES.REQUEST.CHAPTER.ITEM.LIST, { params });

/**
 * Retrieve specified course chapter
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterItemId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourseRequestChapterItem = ({ courseRequestChapterItemId }) =>
  api.get(ROUTE.COURSES.REQUEST.CHAPTER.ITEM.SHOW, { params: { courseRequestChapterItemId } });
