<template>
  <BaseCard class="mb-3 p-card--flat">
    <template #header>
      <BaseRow class="justify-content-between align-items-center px-3 pt-3">
        <BaseCol class="font-bold">
          {{ previewData.displayName }}
        </BaseCol>
        <BaseCol class="flex align-items-center justify-content-end">
          <span class="text-primary mr-3">
            {{ previewData.contentType }}
          </span>
          <BaseButton @click="edit" icon="pi pi-pencil" />
        </BaseCol>
      </BaseRow>
    </template>
    <BaseText>
      {{ previewData.preview.displayName }}
    </BaseText>
  </BaseCard>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "BaseContentPreview",

  props: {
    /**
     Expects an Object like this:
     {
       courseRequestChapterItemId: 1,
       contentType: "video",
       displayName: "Test",
       preview: { displayName: "video 1", fileName: "video1.mp4", thumbnailPath: "path/thumb.jpeg" },
       routeName: "ViewVideo"
     }
     */
    previewData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const edit = () => {
      router.push({
        name: props.previewData.routeName,
        params: { courseRequestChapterItemId: props.previewData.courseRequestChapterItemId },
      });
    };

    return {
      edit,
    };
  },
};
</script>

<style lang="scss" scoped>
.p-card--flat {
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>
