import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_permissions
 */

/**
 * Retrieve list with permissions
 * @method
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listPermissions = (params) => api.get(ROUTE.PERMISSIONS.LIST, { params });
