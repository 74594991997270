<template>
  <div v-for="(message, index) of messages" :key="index" class="flex mt-2">
    <small class="align-self-center text-xs" :class="{ 'p-error': error }">{{ $t(message) }}</small>
  </div>
</template>

<script>
export default {
  name: "FieldMessages",

  props: {
    messages: {
      type: Array,
      required: true,
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
