<template>
  <div class="mb-2">
    <label v-bind="$attrs" class="font-bold" :style="{ color: 'var(--text-color)' }">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "BaseFieldLabel",

  inheritAttrs: false,
};
</script>
