<template>
  <BaseRow class="justify-content-end">
    <BaseCol sm="12" cols="8">
      <StatusFilter />
    </BaseCol>
  </BaseRow>

  <BaseRow v-if="isLoading" cols="12">
    <BaseCol cols="12" class="flex justify-content-center py-3">
      <BaseProgressSpinner />
    </BaseCol>
  </BaseRow>

  <BaseRow v-else-if="meta.total === 0">
    <BaseCol cols="12" class="text-center py-6">
      <BaseParagraph>{{ $t("acm.courseRequest.noData") }}</BaseParagraph>
    </BaseCol>
  </BaseRow>

  <DataView
    v-bind="$attrs"
    layout="grid"
    :value="courses"
    :paginator="true"
    :lazy="true"
    :rows="12"
    :total-records="meta.total"
    @page="onPage($event)"
    v-else
  >
    <template #grid="slotProps">
      <BaseCol class="course-list-col flex">
        <BaseCard class="px-3 m-3 w-full">
          <BaseRow>
            <BaseCol cols="12">
              <div class="thumb-wrapper">
                <img
                  v-if="slotProps.data.thumbnailPath"
                  :src="slotProps.data.thumbnailUrl"
                  class="w-full h-auto"
                  :alt="$t('acm.courses.list.imgAlt', { title: slotProps.data.title })"
                />
                <img
                  v-else
                  src="../assets/img/placeholder-image.jpg"
                  :alt="$t('acm.courses.list.placeholderImgAlt')"
                  class="w-full h-auto"
                />
              </div>
            </BaseCol>
            <BaseCol cols="12">
              <BaseTag class="py-2 mt-2" :severity="slotProps.data.courseRequestStatus.severity">
                {{ slotProps.data.courseRequestStatus.displayName }}
              </BaseTag>
            </BaseCol>
            <BaseCol cols="12">
              <BaseHeadline size="6" no-margin>{{ slotProps.data.title }}</BaseHeadline>
            </BaseCol>
            <BaseCol cols="12">
              <ChaptersCompletedLabel class="mt-1" :course="slotProps.data" />
            </BaseCol>
          </BaseRow>
          <template #footer>
            <BaseRow v-if="courseRequestIsEditable(slotProps.data.courseRequestStatusId)">
              <BaseCol cols="12">
                <BaseRow class="flex justify-content-between" :no-gutter="true">
                  <BaseCol cols="shrink" class="flex">
                    <CourseDelete
                      :courseRequestId="slotProps.data.id"
                      @reload="refresh"
                      v-slot:default="localProps"
                    >
                      <BaseButton
                        icon="pi pi-trash"
                        outlined
                        color="gc-color-danger"
                        :loading="localProps.loading"
                      />
                    </CourseDelete>
                    <BaseButton
                      icon="pi pi-eye"
                      text
                      class="ml-1"
                      @click="preview({ courseRequestId: slotProps.data.id })"
                    />
                  </BaseCol>
                  <BaseCol cols="auto">
                    <BaseButton
                      label="acm.courses.list.btn.edit"
                      @click="edit({ courseRequestId: slotProps.data.id })"
                    />
                  </BaseCol>
                </BaseRow>
              </BaseCol>
            </BaseRow>
          </template>
        </BaseCard>
      </BaseCol>
    </template>
  </DataView>
</template>

<script>
import DataView from "primevue/dataview";
import CourseDelete from "../../delete";
import { useRouter } from "vue-router";
import { ref, watch, computed } from "vue";
import useApi from "@use/useApi";
import StatusFilter from "./StatusFilter.vue";
import { useStore } from "vuex";
import ChaptersCompletedLabel from "./ChaptersCompletedLabel";
import { COURSE_REQUEST_STATUS_EDITABLE } from "@/modules/advanced-course-management-acm/constants/COURSE_REQUEST_STATUS";

export default {
  name: "CourseRequestList",

  components: {
    ChaptersCompletedLabel,
    DataView,
    CourseDelete,
    StatusFilter,
  },

  setup() {
    const router = useRouter();
    const courses = ref([]);
    const meta = ref({});
    const store = useStore();

    const { docs, callApi, isLoading } = useApi({
      module: "acm",
      method: "listCourseRequests",
      onMount: true,
      params: { page: 1, itemsPerPage: 12 },
      showQueries: true,
      ignoreQueries: ["hasCourseRequestStatusIds"],
      onSuccess: () => {
        courses.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    const statusFilter = computed(() => store.getters["acm/courseRequests/statusFilter"]);

    const onPage = (event) => {
      callApi({ page: event.page + 1 });
    };

    const refresh = () => {
      callApi();
    };

    const edit = (params) => {
      router.push({ name: "CourseUpdate", params });
    };

    const preview = (params) => {
      router.push({ name: "CourseRequestPreview", params });
    };

    const courseRequestIsEditable = (status) => {
      return COURSE_REQUEST_STATUS_EDITABLE.includes(status);
    };

    watch(statusFilter, (newVal) => {
      if (newVal) {
        callApi({ hasCourseRequestStatusIds: [newVal] });
      } else {
        callApi();
      }
    });

    return {
      courses,
      meta,
      onPage,
      refresh,
      edit,
      preview,
      courseRequestIsEditable,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.thumb-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 58.787878787878788%;
  overflow: hidden;
  border-radius: 15px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 101%;
    height: auto;
  }
}

.course-list-col {
  min-width: 100%;
  max-width: 100%;

  @media only screen and (min-width: 1000px) {
    min-width: 50%;
    max-width: 50%;
  }

  @media only screen and (min-width: 1600px) {
    min-width: 33%;
    max-width: 33%;
  }

  @media only screen and (min-width: 1800px) {
    min-width: 25%;
    max-width: 25%;
  }
}
</style>
