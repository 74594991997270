import store from "@/store";
import router from "@/router";

/**
 * @module registerModules
 */

/**
 * Register module routes
 */
const registerRoutes = () => {
  const files = require.context("../../../modules", true, /(?:routes.js)$/);

  files.keys().forEach((key) => {
    const moduleRoutes = files(key).default;

    moduleRoutes.forEach(({ name, path, component, children, meta, beforeEnter }) => {
      router.addRoute({
        name,
        path,
        component,
        children,
        meta,
        beforeEnter,
      });
    });
  });
};

/**
 * Register module stores
 */
const registerStores = () => {
  const files = require.context("../../../modules", true, /(?:store.js)$/);

  files.keys().forEach((key) => {
    const moduleStore = files(key).default;
    const storeName = moduleStore.name;
    delete moduleStore.name;
    moduleStore.namespaced = true;

    store.registerModule(storeName, moduleStore);
  });
};

/**
 * Register module apis
 */
const registerApis = (app) => {
  const files = require.context("../../../modules", true, /(?:api.js)$/);

  const api = {};

  files.keys().forEach((key) => {
    api[files(key).module] = {};
    Object.assign(api[files(key).module], files(key).default);
    delete api[files(key).module].module;
  });

  app.config.globalProperties.$axios = api;
  window.$axios = api;
};

/**
 * Register module naviation
 *
 */
const registerModuleNavigation = () => {
  const files = require.context("../../../modules", true, /(?:navigation.js)$/);

  const navigation = [];

  files.keys().forEach((key) => {
    navigation.push(...files(key).default);
  });

  window.$navigation = navigation;
};

const registerModules = (app) => {
  registerRoutes();
  registerStores();
  registerApis(app);
  registerModuleNavigation();
  Promise.resolve();
};

export default registerModules;
