<template>
  <BaseForm :validation="v$" ref="form">
    <BaseRow :no-gutter="true">
      <BaseCol cols="3" class="pr-3">
        <BaseTextField
          v-model="formValues.salutation"
          label="tm.teachers.update.form.label.salutation"
          :validation="v$.salutation"
          :messages="bvShowErrors('salutation').messages"
          :error="bvShowErrors('salutation').hasMessages"
          :maxLength="maxLength.USERS.SALUTATION"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="3">
        <BaseTextField
          v-model="formValues.title"
          label="tm.teachers.update.form.label.title"
          :validation="v$.title"
          :messages="bvShowErrors('title').messages"
          :error="bvShowErrors('title').hasMessages"
          :maxLength="maxLength.USERS.TITLE"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow :no-gutter="true">
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.firstName"
          label="tm.teachers.update.form.label.firstName"
          :validation="v$.firstName"
          :messages="bvShowErrors('firstName').messages"
          :error="bvShowErrors('firstName').hasMessages"
          :maxLength="maxLength.USERS.FIRST_NAME"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow :no-gutter="true">
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.lastName"
          label="tm.teachers.update.form.label.lastName"
          :validation="v$.lastName"
          :messages="bvShowErrors('lastName').messages"
          :error="bvShowErrors('lastName').hasMessages"
          :maxLength="maxLength.USERS.LAST_NAME"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow v-if="formValues.teacherApplication">
      <BaseCol cols="12">
        <BaseTextArea
          v-model="formValues.teacherApplication.shortApplication"
          label="tm.teachers.update.form.label.shortApplication"
          placeholder="prf.update.form.placeholder.shortApplication"
          :validation="v$.shortApplication"
          :messages="bvShowErrors('shortApplication').messages"
          :error="bvShowErrors('shortApplication').hasMessages"
          floatingLabel
          disabled
          rows="10"
        />
      </BaseCol>
    </BaseRow>
    <BaseRow :no-gutter="true">
      <BaseCol cols="12">
        <UserSelect
          v-model="formValues.userId"
          label="tm.teachers.update.form.label.user"
          :validation="v$.userId"
        />
      </BaseCol>
    </BaseRow>
    <BaseRow :no-gutter="true">
      <BaseCol cols="12" class="mt-2">
        <BaseCheckbox
          v-model="formValues.isActive"
          label="tm.teachers.update.form.label.isActive"
          :validation="v$.isActive"
          :messages="bvShowErrors('isActive').messages"
          :error="bvShowErrors('isActive').hasMessages"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseRow class="align-content-center justify-content-end">
        <BaseCol class="flex flex-grow-0">
          <BaseDeleteData
            update-module="tm"
            :formData="{ teacherId: formValues.teacherId }"
            icon="pi pi-trash"
            update-method="deleteTeacher"
            deleteDialogMessage="prf.delete.dialog.message"
            deleteDialogHeader="prf.delete.dialog.header"
            outlined
            :onDeleted="$router.back"
          />
        </BaseCol>
        <BaseCol class="flex flex-grow-0">
          <BaseButton label="prf.update.form.label.submit" @click="submit" :loading="isLoading" />
        </BaseCol>
      </BaseRow>
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import { reactive, watch } from "vue";
import UserSelect from "@/modules/teacher-management-tm/views/Management/Teacher/show/partials/UserSelect";
import useBackendValidation from "@use/useBackendValidation";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "TeacherForm",
  components: { UserSelect },
  props: {
    teacher: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        userId: null,
        salutation: null,
        title: null,
        firstName: null,
        lastName: null,
        isActive: null,
        teacherApplication: {
          shortApplication: null,
        },
      }),
    },
  },

  setup(props, { emit }) {
    // Setup validation
    const rules = {
      id: [],
      userId: [],
      salutation: ["required"],
      title: [],
      firstName: ["required"],
      lastName: ["required"],
      isActive: ["required"],
    };

    const formValues = reactive({
      teacherId: props.teacher.id,
      userId: props.teacher.userId,
      salutation: props.teacher.salutation,
      title: props.teacher.title,
      firstName: props.teacher.firstName,
      lastName: props.teacher.lastName,
      teacherApplication: props.teacher.teacherApplication,
      isActive: props.teacher.isActive,
    });

    const { v$ } = useFormValidation({ formValues, rules });

    const { callApi, backendValidation, isLoading } = useApi({
      module: "tm",
      method: "updateTeacher",
      onSuccess: () => {
        emit("reload");
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      callApi(formValues);
    };

    return {
      v$,
      formValues,
      callApi,
      isLoading,
      submit,
      bvShowErrors,
      maxLength,
    };
  },
};
</script>
