const ProfileManagement = () => import("../../haw-components-hac/views");
const Profile = () => import("../views/Profile");

export default [
  {
    path: "/profile",
    component: ProfileManagement,
    children: [
      {
        path: "",
        name: "Profile",
        component: Profile,
      },
    ],
  },
];
