<template>
  <div class="grid" :class="{ 'grid-nogutter': noGutter }">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseRow",

  props: {
    noGutter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
