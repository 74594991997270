import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_profile
 */

/**
 * Retrieve profile data
 *
 * @method
 * @returns {Promise<AxiosResponse<object>>}
 */
export const showProfile = () => api.get(ROUTE.PROFILE.SHOW);

/**
 * Update profile data with objects
 *
 * @method
 * @param {string} params.salutation
 * @param {string} params.title
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.password
 * @param {string} params.pasword_confirmation
 * @param {object} params.userAddress
 * @param {object} params.userContact
 * @returns {Promise<AxiosResponse<object>>}
 */
export const updateProfileObject = ({
  salutation,
  title,
  firstName,
  lastName,
  password = null,
  // eslint-disable-next-line camelcase
  password_confirmation = null,
  profileAddress,
  profileContact,
}) =>
  api.put(ROUTE.PROFILE.UPDATE, {
    salutation,
    title,
    firstName,
    lastName,
    password,
    // eslint-disable-next-line camelcase
    password_confirmation,
    ...profileAddress,
    ...profileContact,
  });

/**
 * Update profile data
 *
 * @method
 * @param {string} params.salutation
 * @param {string} params.title
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.password
 * @param {string} params.pasword_confirmation
 * @param {object} params.phone
 * @param {object} params.mobile
 * @param {object} params.street
 * @param {object} params.city
 * @param {object} params.zipcode
 * @param {object} params.country
 * @returns {Promise<AxiosResponse<object>>}
 */
export const updateProfile = ({
  salutation,
  title,
  firstName,
  lastName,
  password = null,
  // eslint-disable-next-line camelcase
  password_confirmation = null,
  phone = null,
  mobile = null,
  street = null,
  city = null,
  zipcode = null,
  country = null,
} = {}) =>
  api.put(ROUTE.PROFILE.UPDATE, {
    salutation,
    title,
    firstName,
    lastName,
    password,
    // eslint-disable-next-line camelcase
    password_confirmation,
    phone,
    mobile,
    street,
    city,
    zipcode,
    country,
  });

/**
 * Delete profile data
 *
 * @method
 * @returns {Promise<AxiosResponse<object>>}
 */
export const deleteProfile = () => api.delete(ROUTE.PROFILE.DELETE);
