<template>
  <BaseForm>
    <BaseForm :validation="v$">
      <BaseRow>
        <BaseCol cols="12">
          <BaseTextField
            v-model="formValues.name"
            label="usm.roles.create.form.label.name"
            :validation="v$.name"
          />
        </BaseCol>
      </BaseRow>

      <template #submit>
        <BaseButton label="usm.roles.create.form.label.btn" @click="submit" :loading="loading" />
      </template>
    </BaseForm>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import { onMounted, reactive, watch } from "vue";
import useCaching from "@use/useCaching";

export default {
  name: "CreateForm",

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    // Initialize form values
    const form = reactive({
      name: null,
    });

    // Setup validation
    const rules = {
      name: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    // Emit form values
    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      emit("submit", formValues);
      v$.value.$reset();
      form.name = null;
    };

    const { updateCache, cachedState } = useCaching();

    watch(formValues, () => {
      updateCache(formValues);
    });

    onMounted(() => {
      if (cachedState.value) {
        form.name = cachedState.value.name;
      }
    });

    return {
      v$,
      formValues,
      submit,
    };
  },
};
</script>
