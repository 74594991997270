import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_userGroups
 */

/**
 * Retrieve a list of all user groups
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listUserGroups = (params) => api.get(ROUTE.USER_GROUPS.LIST, { params });

/**
 * Retrieve specified user group
 * @method
 * @param {object} params
 * @param {number} params.userGroupId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showUserGroup = ({ userGroupId }) =>
  api.get(ROUTE.USER_GROUPS.SHOW, { params: { userGroupId } });

/**
 * Delete specified user group
 * @method
 * @param {object} params
 * @param {number} params.userGroupId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteUserGroup = ({ userGroupId }) =>
  api.delete(ROUTE.USER_GROUPS.DELETE, { data: { userGroupId } });

/**
 * Create a new user group
 * @method
 * @param {object} params
 * @param {string} params.displayName
 * @param {string} [params.description]
 * @param {levelId} params.levelId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createUserGroup = ({ displayName, description, levelId }) =>
  api.post(ROUTE.USER_GROUPS.CREATE, { displayName, description, levelId });

/**
 * Update specified user group
 * @method
 * @param {object} params
 * @param {string} params.userGroupId
 * @param {string} params.displayName
 * @param {string} [params.description]
 * @param {levelId} params.levelId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateUserGroup = ({ displayName, levelId, description, userGroupId }) =>
  api.put(ROUTE.USER_GROUPS.UPDATE, { displayName, levelId, description, userGroupId });

/**
 * Add or remove permissions from user group
 *
 * @method
 * @param {object} params
 * @param {number} params.userGroupId
 * @param {array<number>} params.permissionIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserGroupPermissions = ({ userGroupId, permissionIds }) =>
  api.put(ROUTE.USER_GROUPS.SYNC.PERMISSIONS, {
    userGroupId,
    permissionIds,
  });

/**
 * Add or remove roles from user group
 *
 * @method
 * @param {object} params
 * @param {number} params.userGroupId
 * @param {array<number>} params.roleIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserGroupRoles = ({ userGroupId, roleIds }) =>
  api.put(ROUTE.USER_GROUPS.SYNC.ROLES, {
    userGroupId,
    roleIds,
  });

/**
 * Add or remove users from user group
 *
 * @method
 * @param {object} params
 * @param {number} params.userGroupId
 * @param {array<number>} params.userIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserGroupUsers = ({ userGroupId, userIds }) =>
  api.put(ROUTE.USER_GROUPS.SYNC.USER, {
    userGroupId,
    userIds,
  });
