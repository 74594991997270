<template>
  <Tag
    :class="[{ 'w-full': fullWidth, 'opacity-20': isDisabled }, paddingClass]"
    :severity="severity"
  >
    <slot />
  </Tag>
</template>

<script>
import Tag from "primevue/tag";

export default {
  name: "BaseTag",

  components: {
    Tag,
  },

  props: {
    severity: {
      type: String,
      required: false,
      default: "null",
    },

    padding: {
      type: String,
      required: false,
      default: "3",
    },

    fullWidth: {
      type: Boolean,
      required: false,
    },

    isDisabled: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    paddingClass() {
      return `p-${this.padding}`;
    },
  },
};
</script>
