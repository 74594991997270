export const namespaced = true;

export const state = {
  reloadChapterItemsList: false,
};

export const getters = {
  reloadChapterItemsList: (state) => state.reloadChapterItemsList,
};

export const mutations = {
  SET_RELOAD_CHAPTER_ITEMS_LIST(state, { reloadChapterItemsList }) {
    state.reloadChapterItemsList = reloadChapterItemsList;
  },
};

export const actions = {
  reloadChapterItemsList({ commit }, reloadChapterItemsList) {
    return new Promise((resolve) => {
      commit("SET_RELOAD_CHAPTER_ITEMS_LIST", { reloadChapterItemsList });
      resolve();
    });
  },
};
