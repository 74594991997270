<template>
  <EditDialog
    update-module="usm"
    update-method="updateUser"
    :validation="v$"
    :form-values="adjustedFormValues"
    dialog-header="usm.users.update.data.base.title"
    :onHide="callApi"
  >
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.salutation"
          label="usm.users.update.form.label.salutation"
          :validation="v$.salutation"
        />
        <BaseTextField
          v-model="formValues.firstName"
          label="usm.users.update.form.label.firstName"
          :validation="v$.firstName"
        />
        <BaseTextField
          v-model="formValues.lastName"
          label="usm.users.update.form.label.lastName"
          :validation="v$.lastName"
        />
        <BaseTextField
          v-model="formValues.email"
          label="usm.users.update.form.label.email"
          :validation="v$.email"
        />
      </BaseCol>
    </BaseRow>
  </EditDialog>
</template>

<script>
import useUser from "@use/useUser";
import { useRoute } from "vue-router";
import useFormValidation from "@use/useFormValidation";

import EditDialog from "../../edit";

export default {
  name: "UpdateBaseData",

  components: {
    EditDialog,
  },

  setup() {
    // Setup validation
    const rules = {
      email: ["email", "required"],
      salutation: ["required"],
      firstName: ["required"],
      lastName: ["required"],
    };

    // Load data
    const route = useRoute();
    const { userId } = route.params;

    const { userForm, adjustedFormValues, callApi } = useUser({ userId });
    const { v$, formValues } = useFormValidation({ formValues: userForm, rules });

    return {
      v$,
      callApi,
      adjustedFormValues,
      formValues,
    };
  },
};
</script>
