<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.chapters.add.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <AddChapterForm />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import AddChapterForm from "./partials/AddChapterForm";

export default {
  name: "AddChapter",

  components: {
    HAWPageContent,
    AddChapterForm,
  },
};
</script>

<style scoped lang="scss">
.max-w-1024 {
  max-width: 1024px;
  margin: 0 auto;
}
</style>
