<template>
  <HAWPageContent :loading="isLoading" :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="goBack" icon="pi pi-angle-left" />
      <BaseCard v-if="proposalLoaded">
        <BaseRow class="mb-4">
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.courseProposals.show.title") }}
            </BaseHeadline>
          </BaseCol>
        </BaseRow>
        <BaseRow class="mb-4">
          <BaseCol cols="12">
            <BaseHeadline size="6" class="text-primary">
              {{ $t("acm.courseProposals.show.label.title") }}
            </BaseHeadline>
            <BaseText>{{ courseProposalTitle }}</BaseText>
          </BaseCol>
        </BaseRow>
        <BaseRow class="mb-6">
          <BaseCol cols="12">
            <BaseHeadline size="6" class="text-primary">
              {{ $t("acm.courseProposals.show.label.description") }}
            </BaseHeadline>
            <BaseText class="description">{{ courseProposalDescription }}</BaseText>
          </BaseCol>
        </BaseRow>
        <BaseRow
          class="mb-3"
          v-if="courseProposalStatus === 'APPROVED' || courseProposalStatus === 'DISMISSED'"
        >
          <BaseCol cols="12" v-if="courseProposalStatus === 'APPROVED'">
            <BaseMessage class="my-4" severity="success" :life="5000">
              {{ $t("acm.courseProposals.show.message.approved") }}
            </BaseMessage>
          </BaseCol>
          <BaseCol cols="12" v-if="courseProposalStatus === 'DISMISSED'">
            <BaseMessage class="my-4" severity="warn" :life="5000">
              {{ $t("acm.courseProposals.show.message.dismissed") }}
            </BaseMessage>
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CONSTANTS } from "../../../constants";
import useApi from "@use/useApi";
import HAWPageContent from "../../../../haw-components-hac/Base/HAWPageContent";

export default {
  name: "ManagementCourseProposalShow",

  components: {
    HAWPageContent,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const courseProposalId = ref(route.params.courseProposalId);
    const courseProposalTitle = ref(null);
    const courseProposalDescription = ref(null);
    const courseProposalStatus = ref(null);
    const courseProposalTeacher = ref(null);
    const proposalLoaded = ref(false);

    const { docs, hasDocs, isLoading, callApi } = useApi({
      module: "acm",
      method: "showCourseProposal",
      onMount: true,
      params: { courseProposalId: courseProposalId.value },
      onSuccess: () => {
        proposalLoaded.value = proposalLoaded.value || hasDocs;
        //Avoid FOUC
        courseProposalTitle.value = courseProposalTitle.value || docs.value.data.title;
        courseProposalDescription.value =
          courseProposalDescription.value || docs.value.data.description;
        courseProposalTeacher.value = courseProposalTeacher.value || docs.value.data.teacher;

        courseProposalStatus.value = CONSTANTS.getStatus(
          "COURSE_PROPOSAL_STATUS",
          docs.value.data.courseProposalStatusId
        );
      },
    });

    const reset = () => {
      proposalLoaded.value = false;
      courseProposalTitle.value = null;
      courseProposalDescription.value = null;
    };

    const refresh = () => {
      callApi();
    };

    watch(route, () => {
      courseProposalId.value = route.params.courseProposalId;
      reset();
      if (courseProposalId.value) {
        callApi({ courseProposalId: courseProposalId.value });
      }
    });

    const goBack = () => {
      router.push({ name: "CourseProposalsList" });
    };

    return {
      courseProposalId,
      proposalLoaded,
      courseProposalTitle,
      courseProposalDescription,
      courseProposalStatus,
      courseProposalTeacher,
      isLoading,
      refresh,
      goBack,
    };
  },
};
</script>

<style lang="scss" scoped>
.description {
  white-space: pre-line;
}
</style>
