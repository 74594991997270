<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.chapters.add.form.label.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          :messages="bvShowErrors('displayName').messages"
          :error="bvShowErrors('displayName').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextArea
          label="acm.chapters.add.form.label.description"
          v-model="formValues.description"
          :validation="v$.description"
          :messages="bvShowErrors('description').messages"
          :error="bvShowErrors('description').hasMessages"
          floatingLabel
          rows="12"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { reactive, watch } from "vue";
import useBackendValidation from "@use/useBackendValidation";

export default {
  name: "AddChapterForm",

  setup() {
    const { showSuccess } = useNotifications();
    const router = useRouter();
    const route = useRoute();
    const { courseRequestId } = route.params;
    const store = useStore();

    // Initialize form values
    const form = reactive({
      courseRequestId: parseInt(courseRequestId, 10),
      displayName: null,
      description: null,
      order: null,
    });

    // Setup validation
    const rules = {
      courseRequestId: ["required"],
      displayName: ["required"],
      description: ["required"],
      order: [],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, docs, backendValidation } = useApi({
      module: "acm",
      method: "storeCourseRequestChapter",
      onSuccess() {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        store.dispatch("acm/chapters/reloadChapterList", true);
        router.push({
          name: "ViewChapter",
          params: {
            courseRequestChapterId: docs.value.data.id,
          },
        });
        resetForm();
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => {
      updateValidation(backendValidation.value);
    });

    const resetForm = () => {
      formValues.displayName = null;
      formValues.description = null;
    };

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
      v$.value.$reset();
    };

    return {
      v$,
      isLoading,
      formValues,
      submit,
      bvShowErrors,
    };
  },
};
</script>
