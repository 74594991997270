<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.courseChapterItems.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          :messages="bvShowErrors('displayName').messages"
          :error="bvShowErrors('displayName').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <VideoViewerContainer
          :thumbnail="videoInformation.thumbnailUrl"
          :video="videoInformation.url"
        />
      </BaseCol>
      <BaseCol cols="12" class="mt-3">
        <BaseFileUpload
          @ref="setRef"
          name="videoFile"
          :thumbnail-handler="handleFile"
          :messages="bvShowErrors('file').messages"
          :error="bvShowErrors('file').hasMessages"
          :max-file-size="sizes['500MB']"
          accept="video/*"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import VideoViewerContainer from "@/modules/haw-components-hac/components/VideoViewerContainer";
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { reactive, watch } from "vue";
import { useStore } from "vuex";
import useBackendValidation from "@use/useBackendValidation";
import sizes from "@/modules/haw-components-hac/constants/sizes";

export default {
  name: "UpdateVideoForm",

  components: {
    VideoViewerContainer,
  },

  props: {
    videoInformation: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { showSuccess, showError } = useNotifications();
    const store = useStore();

    let fileUploadRef = null;

    // Initialize form values
    const form = reactive({
      courseRequestVideoId: props.videoInformation.courseRequestVideoId,
      displayName: props.videoInformation.displayName,
      videoFile: null,
    });

    // Setup validation
    const rules = {
      courseRequestVideoId: ["required"],
      displayName: ["required"],
      videoFile: [],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation, error } = useApi({
      module: "acm",
      method: "updateCourseRequestChapterItemVideo",
      onSuccess: async () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        emit("refresh");
        fileUploadRef.clear();
        await store.dispatch("acm/chapterItems/reloadChapterItemsList", true);
        await store.dispatch("acm/chapters/reloadChapterList", true);
      },
      onError: () => {
        if (error.value.response.status === 413) {
          showError(
            "acm.courses.update.notification.error.filesize.summary",
            "acm.courses.update.notification.error.filesize.detail"
          );
        }
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
    };

    const handleFile = (event) => {
      formValues.videoFile = event.files[0];
    };

    const setRef = (event) => {
      fileUploadRef = event;
    };

    watch(props.videoInformation, () => {
      formValues.courseRequestVideoId = props.videoInformation.courseRequestVideoId;
      formValues.displayName = props.videoInformation.displayName;
    });

    return {
      v$,
      isLoading,
      formValues,
      submit,
      handleFile,
      setRef,
      bvShowErrors,
      sizes,
    };
  },
};
</script>
