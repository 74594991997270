import { onMounted, ref, watch } from "vue";
import hasPermission from "@helper/hasPermission";
import objectValueIsTrue from "@helper/objectValueIsTrue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { orderBy } from "lodash";
import store from "@/store";

/**
 * Compile menu items
 *
 * @method
 * @param {object} params
 * @param {string} params.menuName
 * @param {string} params.menuTypes
 * @returns {object} { items, changeMenuTypes }
 */
const useMenuItems = ({ menuName, menuTypes } = {}) => {
  const navigationItems = ref(null);
  const items = ref([]);
  const route = useRoute();
  const { t } = useI18n();
  const types = ref([]);

  onMounted(() => {
    navigationItems.value = window.$navigation;
    types.value = menuTypes;
  });

  const changeMenuTypes = (menuTypes) => {
    types.value = menuTypes;
    compileItems();
  };

  // Filer navigation items based on arguments
  const filter = (item) => {
    const hasMenuName = item.menuName === menuName;
    if (!hasMenuName) return false;

    const isAuthenticated = store.getters["usm/auth/isAuthenticated"];
    const permissions = item.permissions || null;

    if (permissions && objectValueIsTrue(item, "requireNoneOfPermissions")) {
      return !permissions.some((permission) => hasPermission(permission));
    }

    if (!isAuthenticated) {
      if (item.requireAuth || permissions) {
        return false;
      }
    }

    if (isAuthenticated && objectValueIsTrue(item, "requireNotAuth")) {
      return false;
    }

    if (permissions) {
      if (objectValueIsTrue(item, "requireOnePermission")) {
        return permissions.some((permission) => hasPermission(permission));
      }

      return permissions.every((permission) => hasPermission(permission));
    }

    if (!objectValueIsTrue(item, "separator")) {
      const hasTypes = [];

      item.types.forEach((type) => {
        hasTypes.push(types.value.includes(type) || type === undefined);
      });

      return hasTypes.includes(true);
    }

    return true;
  };

  const retrieveItems = () => {
    const items = navigationItems.value.filter((item) => filter(item));

    return orderBy(items, ["order"], ["desc"]);
  };

  // Compile navigation items for use in components
  const compileItems = () => {
    items.value = [];

    // Item is active route
    const isActiveRoute = (item) => {
      if (item.to) {
        const routeInPath = route.matched.length > 1 ? route.matched[1].path === item.to : false;

        return route.path === item.to || routeInPath;
      }

      return false;
    };

    retrieveItems().forEach((item, index) => {
      if (objectValueIsTrue(item, "separator")) {
        items.value.push({
          separator: true,
          label: t(item.label),
        });
      } else {
        // 1st Stage add new properties & translate strings
        items.value.push({
          ...item,
          label: t(item.label),
          isActive: isActiveRoute(item),
          isLast: index + 1 === retrieveItems().length,
          isFirst: index === 0,
        });
      }
    });
  };

  onMounted(() => {
    if (route.meta.menuTypes) {
      changeMenuTypes(route.meta.menuTypes);
    } else {
      compileItems();
    }
  });

  watch(route, () => {
    if (route.meta.menuTypes) {
      changeMenuTypes(route.meta.menuTypes);
    } else {
      changeMenuTypes(menuTypes);
    }
  });

  return { items, changeMenuTypes, types };
};

export default useMenuItems;
