<template>
  <BaseHeadline size="1" fake="5">
    {{ item.model.displayName }}
  </BaseHeadline>
  <VideoViewerContainer :thumbnail="item.model.thumbnailUrl" :video="item.model.url" />
</template>

<script>
import VideoViewerContainer from "@/modules/haw-components-hac/components/VideoViewerContainer";

export default {
  components: { VideoViewerContainer },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({
        model: {
          thumbnailUrl: null,
          url: null,
          displayName: null,
        },
      }),
    },
  },
};
</script>
