import api from "@/api";
import ROUTE from "../../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve a list of all courses
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementListCourses = (params) => api.get(ROUTE.MANAGEMENT.COURSES.LIST, { params });

/**
 * Retrieve specified course
 * @method
 * @param {object} params
 * @param {number} params.courseId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementShowCourse = ({ courseId }) =>
  api.get(ROUTE.MANAGEMENT.COURSES.SHOW, { params: { courseId } });

/**
 * Update course
 * @method
 * @param {object} params
 * @param {number} params.courseId
 * @param {boolean} params.isActive
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementUpdateCourse = ({ courseId, isActive }) =>
  api.put(ROUTE.MANAGEMENT.COURSES.UPDATE, { courseId, isActive });
