import store from "../../../store/index.js";

export default (next, routeName = null) => {
  const isAuthenticated = store.getters["usm/auth/isAuthenticated"];

  if (!isAuthenticated) {
    next();

    return;
  }

  if (routeName) {
    next({ name: routeName }); // go to Route defined in meta routeName

    return;
  }

  const permissions = store.getters["usm/authUser/permissions"];
  if (permissions && permissions.includes("teacher.course_request.read")) {
    next({ name: "CourseRequestsList" });
  } else if (permissions && permissions.includes("teacher.course_request.read")) {
    next({ name: "CourseProposalCreate" });
  } else {
    next({ name: "MyDashboard" });
  }
};
