import * as courseRequests from "./courseRequests";
import * as chapter from "./chapter";
import * as chapterItem from "./chapterItem";
import * as chapterContent from "./chapterContent";
import * as chapterVideo from "./chapterVideo";
import * as chapterQuiz from "./chapterQuiz";
import * as informationMaterial from "./informationMaterial";
import * as topics from "./topics";

import * as courseProposals from "./management/courseProposals";
import * as managementCourses from "./management/courses";
import * as managementInformationMaterial from "./management/informationMaterial";
import * as managementCourseRequests from "./management/courseRequests";
import * as managementChapterItem from "./management/chapterItem";
import * as managementCourseTopics from "./management/topics";

export const module = "acm";

export default {
  ...courseRequests,
  ...chapter,
  ...chapterItem,
  ...chapterContent,
  ...chapterVideo,
  ...chapterQuiz,
  ...courseProposals,
  ...topics,
  ...informationMaterial,
  ...managementCourses,
  ...managementInformationMaterial,
  ...managementCourseRequests,
  ...managementChapterItem,
  ...managementCourseTopics,
};
