export default {
  SHOW: "/carts",
  CLEAR: "/carts/clear",
  CHECKOUT: "/carts/checkout",

  COURSES: {
    ADD: "/carts/course",
    REMOVE: "/carts/course",
  },
};
