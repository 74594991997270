<template>
  <HAWPageContent :loading="isLoading" :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.courses.update.courseInformation.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <CourseInformationForm v-if="hasDocs" :course-info="courseInfo" @refresh="refresh" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import useNotifications from "@use/useNotifications";
import useApi from "@use/useApi";
import CourseInformationForm from "./partials/CourseInformationForm";
import { reactive } from "vue";

export default {
  name: "CourseUpdate",

  components: {
    HAWPageContent,
    CourseInformationForm,
  },

  setup() {
    const { showSuccess } = useNotifications();
    const route = useRoute();
    const courseRequestId = ref(route.params.courseRequestId);
    const chapterId = ref(route.params.chapterId);

    const courseRequestTitle = ref(null);

    const courseInfo = reactive({
      courseRequestId: parseInt(courseRequestId.value, 10),
      courseTopics: [],
      customCourseTopics: [],
      title: null,
      subTitle: null,
      description: null,
      thumbnailPath: null,
    });

    const { docs, hasDocs, isLoading, callApi } = useApi({
      module: "acm",
      method: "showCourseRequest",
      onMount: true,
      params: { courseRequestId: parseInt(courseRequestId.value, 10) },
      onSuccess: () => {
        courseRequestTitle.value = docs.value.data.title;
        courseInfo.title = docs.value.data.title;
        courseInfo.subTitle = docs.value.data.subTitle;
        courseInfo.description = docs.value.data.description;
        courseInfo.thumbnailPath = docs.value.data.thumbnailPath;
        courseInfo.courseTopics = docs.value.data.courseTopics;
        courseInfo.customCourseTopics = docs.value.data.customCourseTopics;
      },
    });

    const refresh = async () => {
      await callApi();
      showSuccess(
        "acm.courses.update.notification.saved.summary",
        "acm.courses.update.notification.saved.detail"
      );
    };

    watch(route, () => {
      courseRequestId.value = route.params.courseRequestId;
      if (courseRequestId.value) {
        callApi({ courseRequestId: parseInt(courseRequestId.value, 10) });
      }
    });

    return {
      courseRequestTitle,
      courseRequestId,
      chapterId,
      refresh,
      courseInfo,
      hasDocs,
      isLoading,
    };
  },
};
</script>
