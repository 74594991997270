<template>
  <div class="rte-container">
    <!--    TODO: Add labello -->
    <Editor
      v-model="content"
      :editorStyle="editorStyle"
      :disabled="disabled"
      @text-change="onEditorChange($event)"
      @input="onEditorInput($event)"
    >
      <template #toolbar>
        <select
          v-for="(dropdown, name, index) in quillState.toolbar.dropdown"
          :key="index"
          :class="`ql-${name}`"
        >
          <option selected></option>
          <option v-for="(option, index) in dropdown" :value="option" :key="index"></option>
        </select>
        <span v-for="(group, index) in quillState.toolbar.formats" :key="index" class="ql-formats">
          <button
            v-for="(format, index) in group"
            :key="index"
            :class="isObject(format) ? `ql-${Object.keys(format)[0]}` : `ql-${format}`"
            :value="isObject(format) ? Object.values(format)[0] : ''"
          ></button>
        </span>
      </template>
    </Editor>
  </div>
</template>

<script>
/*
This Component uses the vue3 quill editor component:
https://www.npmjs.com/package/vue3-quill
Make sure this is installed.
 */
import Editor from "primevue/editor";
import { reactive, ref } from "vue";
import { isPlainObject } from "lodash";

export default {
  name: "BaseRte",

  components: {
    Editor,
  },

  props: {
    //Initialer content.
    // Wert mit vorhandenem HTML-String z.B. "<p>Hallo Welt!</p>" oder ""
    modelValue: {
      type: undefined,
      required: true,
    },
    toolbar: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    editorStyle: {
      type: String,
      required: false,
      default: "height: 320px;",
    },
    onInput: {
      type: Function,
      required: false,
      default: null,
    },
    onChange: {
      type: Function,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const defaultToolbar = Object.assign(
      {
        dropdown: {
          header: [1, 2, 3, 4, 5, 6],
        },
        formats: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          ["clean"],
        ],
      },
      props.toolbar
    );

    const content = ref(props.modelValue);

    const quillState = reactive({
      toolbar: defaultToolbar,
    });

    const onEditorChange = (event) => {
      if (props.onChange && typeof props.onChange === "function") {
        props.onChange(event);
      }
    };

    const onEditorInput = (event) => {
      if (props.onInput && typeof props.onInput === "function") {
        props.onInput(event);
      }
    };

    const isObject = (data) => {
      return isPlainObject(data);
    };

    return {
      quillState,
      content,
      onEditorChange,
      onEditorInput,
      isObject,
    };
  },
};
</script>

<style lang="scss">
.rte-container {
  font-family: sans-serif !important;

  strong,
  b {
    font-family: sans-serif !important;
    font-weight: bold;
  }

  em {
    font-family: sans-serif !important;
    font-style: italic;
  }

  u {
    font-family: sans-serif !important;
    text-decoration: underline;
  }
}
</style>
