<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.courseChapterItems.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          :messages="bvShowErrors('displayName').messages"
          :error="bvShowErrors('displayName').hasMessages"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { reactive, watch } from "vue";
import useBackendValidation from "@use/useBackendValidation";

export default {
  name: "AddQuizForm",

  setup() {
    const { showSuccess } = useNotifications();
    const route = useRoute();
    const router = useRouter();
    const { courseRequestChapterId } = route.params;
    const store = useStore();

    // Initialize form values
    const form = reactive({
      courseRequestChapterId: parseInt(courseRequestChapterId, 10),
      displayName: null,
    });

    // Setup validation
    const rules = {
      courseRequestChapterId: ["required"],
      displayName: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "storeCourseRequestChapterItemQuiz",
      onSuccess: async () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        resetForm();
        v$.value.$reset();
        await store.dispatch("acm/chapterItems/reloadChapterItemsList", true);
        await store.dispatch("acm/chapters/reloadChapterList", true);
        await router.go(-1);
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    const resetForm = () => {
      formValues.displayName = null;
    };

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
    };

    return {
      v$,
      isLoading,
      formValues,
      submit,
      bvShowErrors,
    };
  },
};
</script>
