<template>
  <div>
    <BaseText>
      {{ $t("acm.courses.update.form.label.customCourseTopics.description") }}<br />
      {{ $t("acm.courses.update.form.label.customCourseTopics.submitOwn") }}
    </BaseText>

    <div class="my-3 p-fluid w-full">
      <Chips v-model="customCourseTopics" :allowDuplicate="false" />
      <div class="pl-1">
        <ErrorMessages :messages="messages" v-if="error" />
      </div>
    </div>
  </div>
</template>

<script>
import Chips from "primevue/chips";
import ErrorMessages from "@/modules/base-components-bc/Base/partials/ErrorMessages";

export default {
  components: {
    ErrorMessages,
    Chips,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      required: false,
    },
    messages: {
      type: Array,
      required: false,
    },
  },
  computed: {
    customCourseTopics: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
