import { useI18n } from "vue-i18n";
import { ref, watchEffect } from "vue";

/**
 * Compile error messages as an array
 *
 * @method
 * @param {object} validation validation object from vuelidate
 * @return {object} { errorMessages, hasErrors }
 */
const useErrorMessages = (validation) => {
  const { t } = useI18n();

  const errorMessages = ref([]);
  const hasErrors = ref(false);

  const compileMessages = () => {
    if (validation) {
      errorMessages.value = validation.$errors.map(({ $params }) =>
        t(`utils.use.validation.${$params.type}`, {
          max: $params.max,
          min: $params.min,
          equalTo: $params.equalTo,
        })
      );

      hasErrors.value = validation.$errors.length > 0;
    }
  };

  watchEffect(() => {
    compileMessages();
  });

  return { errorMessages, hasErrors };
};

export default useErrorMessages;
