import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve a list of all course requests
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseRequests = (params) => api.get(ROUTE.COURSES.REQUEST.LIST, { params });

/**
 * Retrieve specified course request
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourseRequest = ({ courseRequestId }) =>
  api.get(ROUTE.COURSES.REQUEST.SHOW, { params: { courseRequestId } });

/**
 * Delete specified course request
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequest = ({ courseRequestId }) =>
  api.delete(ROUTE.COURSES.REQUEST.DELETE, { data: { courseRequestId } });

/**
 * Update specified course request
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @param {array} params.courseTopics
 * @param {array} params.customCourseTopics
 * @param {string} params.title
 * @param {string} params.subTitle
 * @param {string} params.description
 * @param {file} params.thumbnailImage
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequest = ({
  courseRequestId,
  courseTopics,
  customCourseTopics,
  title,
  subTitle,
  description,
  thumbnailImage,
}) => {
  const formData = new FormData();
  formData.append("courseRequestId", courseRequestId);
  formData.append("title", title);
  formData.append("description", description);

  courseTopics.forEach((courseTopicId, index) => {
    formData.append(`courseTopicIds[${index}]`, courseTopicId);
  });

  customCourseTopics.forEach((customCourseTopic, index) => {
    formData.append(`customCourseTopics[${index}]`, customCourseTopic);
  });

  if (subTitle) {
    formData.append("subTitle", subTitle);
  }

  if (thumbnailImage) {
    formData.append("thumbnailImage", thumbnailImage);
  }

  return api.post(ROUTE.COURSES.REQUEST.UPDATE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * Publish specified course request
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const publishCourseRequest = ({ courseRequestId }) =>
  api.post(ROUTE.COURSES.REQUEST.PUBLISH, { courseRequestId });
