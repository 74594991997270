<template>
  <BaseRow>
    <BaseCol v-for="(item, index) in menuItems" :key="index" class="">
      <BaseTag
        padding="2"
        :class="{ 'cursor-pointer': !isDisabled }"
        :is-disabled="isDisabled"
        :full-width="true"
        @click="onClick(item)"
      >
        {{ $t(item.label) }}
      </BaseTag>
    </BaseCol>
  </BaseRow>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "QuizMenu",

  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuItems: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
  },

  setup(props) {
    const router = useRouter();

    const onClick = (itemData) => {
      if (itemData.to && !props.isDisabled) {
        router.push(itemData.to);
      } else {
        return false;
      }
    };

    return {
      onClick,
    };
  },
};
</script>
