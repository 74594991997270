<template>
  <form submit.prevent>
    <slot />

    <BaseMessage severity="error" v-if="hasError" :closable="false">
      {{ errorMessage }}
    </BaseMessage>

    <BaseRow class="mt-3">
      <BaseCol cols="12" v-if="hasMandatoryFields">
        <p>* {{ $t("baseComponents.baseForm.mandatoryField") }}</p>
      </BaseCol>
      <BaseCol cols="12" class="text-right align-self-center">
        <slot name="submit" />
      </BaseCol>
    </BaseRow>
  </form>
</template>

<script>
export default {
  name: "BaseForm",

  props: {
    mandatoryFields: {
      type: Boolean,
      required: false,
      default: false,
    },

    validation: {
      type: Object,
      required: false,
      default: () => {},
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    message: {
      type: String,
      required: false,
    },

    hideMandatory: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    hasMandatoryFields() {
      if (this.hideMandatory) {
        return;
      } else {
        return this.mandatoryFields || !!this.validation;
      }
    },

    hasError() {
      if (!this.validation) return false;
      if (this.error) return true;

      return this.validation.$error;
    },

    errorMessage() {
      if (this.message) return this.$t(this.message);

      return this.$t("baseComponents.baseForm.hasErrors");
    },
  },
};
</script>
