<template>
  <BaseHasPermission permission="user_group.create" redirect>
    <BasePageContent>
      <template #header>
        <BaseHeadline size="2" fake="5" noMargin>{{
          $t("usm.userGroups.create.title")
        }}</BaseHeadline>
      </template>
      <BaseContainer align="start">
        <CreateForm @submit="callApi($event)" />
      </BaseContainer>
    </BasePageContent>
  </BaseHasPermission>
</template>

<script>
import useApi from "@use/useApi";
import CreateForm from "./partials/CreateForm.vue";
import { useRouter } from "vue-router";

export default {
  name: "UserGroupCreate",

  components: {
    CreateForm,
  },

  setup() {
    const router = useRouter();

    const { callApi } = useApi({
      module: "usm",
      method: "createUserGroup",
      onSuccess: () => {
        router.push({ name: "UserGroups" });
      },
    });

    return { callApi };
  },
};
</script>
