<template>
  <div v-if="hasPermission && permissionLevel">
    <slot v-bind="$attrs" />
  </div>
</template>

<script>
import hasPermission from "@utils/helper/hasPermission";

export default {
  name: "BaseHasPermission",

  inheritAttrs: false,

  props: {
    permission: {
      type: String,
      required: true,
    },

    permissionLevel: {
      type: Boolean,
      required: false,
      default: true,
    },

    redirect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    hasPermission() {
      return hasPermission(this.permission);
    },
  },

  methods: {
    redirectUser() {
      if (this.redirect && !this.hasPermission) {
        this.$router.push("/");
      }
    },
  },

  created() {
    this.redirectUser();
  },

  updated() {
    this.redirectUser();
  },
};
</script>
