<template>
  <ul v-bind="$attrs" class="mb-4 list-disc">
    <slot />
  </ul>
</template>

<script>
export default {
  name: "BaseUl",
};
</script>
