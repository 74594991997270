<template>
  <div class="rte-viewer" v-html="content" />
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.rte-viewer {
  color: var(--text-color-secondary);
  line-height: 1.5;

  strong,
  b {
    font-family: sans-serif !important;
    font-weight: bold;
  }

  em {
    font-family: sans-serif !important;
    font-style: italic;
  }

  u {
    font-family: sans-serif !important;
    text-decoration: underline;
  }

  img {
    width: 100%;
  }

  h1,
  h2,
  h3 {
    color: var(--headline-color);
  }

  h1 {
    font-size: 2rem !important;
  }

  h2 {
    font-size: 1.7rem !important;
  }

  h3 {
    font-size: 1.5rem !important;
  }

  ol,
  ul {
    margin-left: 2.5rem;
  }

  ol {
    list-style: decimal;

    li {
      padding-left: 0.5em;
    }
  }

  ul {
    list-style: disc;

    li {
      padding-left: 0.25em;
    }
  }

  a {
    color: var(--blue-500);
  }
}
</style>
