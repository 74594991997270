<template>
  <ToggleButton
    v-model="onClick"
    :id="id"
    v-bind="$attrs"
    :onIcon="onIcon || icon"
    :offIcon="offIcon || icon"
    :onLabel="$t(onLabel || label)"
    :offLabel="$t(offLabel || label)"
    :iconPos="iconPos"
  />
</template>

<script>
import ToggleButton from "primevue/togglebutton";
import { ref, watch } from "vue";
import { random } from "lodash";

export default {
  name: "BaseToggle",

  components: {
    ToggleButton,
  },

  setup(props, { emit }) {
    const onClick = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (value) => {
        onClick.value = value;
      }
    );

    watch(onClick, (value) => {
      emit("update:modelValue", value);
    });

    return { onClick };
  },

  props: {
    modelValue: {
      type: undefined,
      required: true,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    icon: {
      type: String,
      required: false,
      default: null,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    onIcon: {
      type: String,
      required: false,
      default: null,
    },

    offIcon: {
      type: String,
      required: false,
      default: null,
    },

    onLabel: {
      type: String,
      required: false,
      default: null,
    },

    offLabel: {
      type: String,
      required: false,
      default: null,
    },

    iconPos: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    id() {
      return `toggle-${random(100)}`;
    },
  },
};
</script>
