<template>
  <BootUserLoader>
    <router-view />
    <Toast />
    <ConfirmDialog />
  </BootUserLoader>
</template>

<script>
import { mapActions } from "vuex";
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import storageNames from "@data/storageNames";
import useTokenValidation from "@use/useTokenValidation";
import BootUserLoader from "@/modules/user-management-usm/components/BootUserLoader";

export default {
  name: "App",

  components: {
    BootUserLoader,
    Toast,
    ConfirmDialog,
  },

  setup() {
    useTokenValidation();
  },

  methods: {
    ...mapActions(["initializeState"]),
  },

  created() {
    this.initializeState();

    // Sync browser tabs
    window.addEventListener("storage", (event) => {
      if (event.key === storageNames.VUEX_STORE) {
        this.initializeState();
      }
    });
  },
};
</script>
