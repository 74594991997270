<template>
  <BaseCol cols="12" xl="6" :class="count !== index + 1 ? 'pb-4' : ''">
    <p class="line-height-3 text-primary">
      {{ question.questionText }}
    </p>

    <p v-if="question.questionTooltip" class="line-height-3 mt-1">
      {{ question.questionTooltip }}
    </p>

    <div class="mt-3">
      <QuizQuestionAnswer
        v-for="answer in question.courseRequestQuizQuestionAnswers"
        :key="answer.id"
        :answer="answer"
      />
    </div>
  </BaseCol>
</template>

<script>
import QuizQuestionAnswer from "./QuizQuestionAnswer";

export default {
  components: { QuizQuestionAnswer },
  props: {
    question: {
      type: Object,
      required: true,
      default: () => ({
        questionText: null,
        questionTooltip: null,
        courseRequestQuizQuestionAnswers: [],
      }),
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
    index: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>
