<template>
  <BaseTag v-for="topic in customCourseTopics" :key="topic.id" class="py-2 mr-1" severity="warning">
    {{ topic.displayName }}
  </BaseTag>
</template>

<script>
export default {
  props: {
    customCourseTopics: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
