<template>
  <div class="question-preview mb-3 border-radius--8">
    <BaseRow class="justify-content-between align-items-center px-3 pb-2 pt-3">
      <BaseCol>{{ questionData.questionText }}</BaseCol>
      <BaseCol class="flex-grow-0">
        <BaseButton @click="edit" icon="pi pi-pencil" />
      </BaseCol>
    </BaseRow>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "QuestionPreview",

  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const edit = () => {
      router.push({
        name: "ViewQuestion",
        params: Object.assign(props.questionData, {
          courseRequestQuizQuestionId: props.questionData.id,
        }),
      });
    };

    return {
      edit,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-preview {
  border: 1px solid #ced4da;
}
</style>
