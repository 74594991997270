<template>
  <Message v-bind="$attrs">
    <slot />
  </Message>
</template>

<script>
import Message from "primevue/message";

export default {
  name: "BaseMessage",

  components: {
    Message,
  },
};
</script>
