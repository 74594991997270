<template>
  <div v-if="hasTopics">
    <BaseMessage severity="warn" :closable="false">
      <div class="pl-3">
        <p class="font-bold mb-3">
          {{ $t("acm.management.courseRequests.show.customCourseTopics.title") }}
        </p>

        <p class="mb-3">
          {{ $t("acm.management.courseRequests.show.customCourseTopics.content") }}
        </p>

        <BaseTag
          v-for="topic in customCourseTopics"
          :key="topic.id"
          class="bg-white text-primary py-2 mr-1"
        >
          {{ topic.displayName }}
        </BaseTag>
      </div>
    </BaseMessage>
  </div>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    customCourseTopics: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props) {
    const hasTopics = computed(() => {
      return props.customCourseTopics && props.customCourseTopics.length > 0;
    });

    return {
      hasTopics,
    };
  },
};
</script>
