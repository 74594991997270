<template>
  <BaseDropdown
    v-model="selectValue"
    :options="options"
    optionLabel="displayName"
    optionValue="id"
    v-bind="$attrs"
  />
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";

export default {
  name: "LevelSelect",

  setup() {
    const options = ref([]);

    const { docs } = useApi({
      module: "usm",
      method: "listLevels",
      onMount: true,
      onSuccess: () => {
        options.value = docs.value.data;
      },
    });

    return { options };
  },

  props: {
    modelValue: {
      type: undefined,
      required: true,
    },
  },

  computed: {
    selectValue: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
