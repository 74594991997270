export default {
  AUTH: {
    CREATE: "/teachers",
    REGISTER: "/teachers/register",
    REGISTER_WITH_USER: "/teachers",
  },

  TEACHERS: {
    LIST: "/management/teachers",
    SHOW: "/management/teachers/show",
    CREATE: "/management/teachers",
    DELETE: "/management/teachers",
    UPDATE: "/management/teachers",
  },
};
