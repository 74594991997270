<template>
  <ProgressBar
    :value="value"
    :mode="mode"
    :showValue="showValue"
    :class="{ 'progress-bar--slim': slim }"
    v-bind="$attrs"
  />
</template>

<script>
import ProgressBar from "primevue/progressbar";

export default {
  components: {
    ProgressBar,
  },

  props: {
    indeterminate: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    showValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    slim: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    mode() {
      if (this.indeterminate) {
        return "indeterminate";
      }

      return "determinate";
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar--slim {
  height: 0.5em;
}
</style>
