<template>
  <HAWPageContent :breadcrumb="false">
    <BaseHeadline size="1" fake="4">{{ $t("acm.management.courses.title") }}</BaseHeadline>
    <ListTable :courses="courses" :meta="meta" :callApi="callApi" :loading="isLoading" />
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import ListTable from "./partials/ListTable.vue";

export default {
  name: "ManagementCourseList",

  components: {
    HAWPageContent,
    ListTable,
  },

  setup() {
    const courses = ref([]);
    const meta = ref({});

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "acm",
      method: "managementListCourses",
      onMount: true,
      showQueries: true,
      params: { page: 1, itemsPerPage: 10 },
      onSuccess: () => {
        courses.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    return { courses, meta, hasDocs, isLoading, callApi };
  },
};
</script>
