<template>
  <EditDialog
    update-module="usm"
    update-method="updateUser"
    :validation="v$"
    :form-values="adjustedFormValues"
    dialog-header="usm.users.update.data.contact.title"
    :onShow="callApi"
  >
    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.phone"
          label="usm.users.update.form.label.phone"
          :validation="v$.phone"
        />
      </BaseCol>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.mobile"
          label="usm.users.update.form.label.mobile"
          :validation="v$.mobile"
        />
      </BaseCol>
    </BaseRow>
  </EditDialog>
</template>

<script>
import useUser from "@use/useUser";
import { useRoute } from "vue-router";
import useFormValidation from "@use/useFormValidation";

import EditDialog from "../../edit";

export default {
  name: "UpdateContact",

  components: {
    EditDialog,
  },

  setup() {
    // Setup validation
    const rules = {
      phone: [],
      mobile: [],
    };

    // Load data
    const route = useRoute();
    const { userId } = route.params;

    const { adjustedFormValues, userContact, hasDocs, callApi } = useUser({
      userId,
      rules,
    });
    const { v$, formValues } = useFormValidation({ formValues: userContact, rules });

    return {
      v$,
      hasDocs,
      callApi,
      adjustedFormValues,
      formValues,
    };
  },
};
</script>
