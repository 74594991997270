<template>
  <BaseFormField>
    <BaseFieldLabel v-if="!floatingLabel" :for="id">{{ labelField }}</BaseFieldLabel>

    <span :class="[{ 'p-float-label mt-3': floatingLabel }]" class="w-full">
      <Dropdown
        v-if="!multiple"
        v-model="selectedValue"
        :placeholder="$t('baseComponents.baseDropdown.placeholder')"
        :optionLabel="optionLabel"
        v-bind="$attrs"
        class="w-full"
        :id="id"
        :class="[{ 'p-invalid': hasErrors || error }]"
        :loading="loading"
      />

      <MultiSelect
        v-if="multiple"
        v-model="selectedValue"
        :placeholder="$t('baseComponents.baseDropdown.placeholder')"
        :optionLabel="optionLabel"
        v-bind="$attrs"
        class="w-full"
        :id="id"
        :class="[{ 'p-invalid': hasErrors || error }]"
        :loading="loading"
        :showToggleAll="showToggleAll"
      />

      <BaseFieldLabelFloating :for="id" v-if="floatingLabel">
        {{ labelField }}
      </BaseFieldLabelFloating>
    </span>

    <div class="ml-1" :id="`${id}-help`">
      <FieldMessages :messages="messages" v-if="hasMessages && !hasErrors" :error="error" />
      <ErrorMessages :messages="errorMessages" v-if="hasErrors" />
    </div>
  </BaseFormField>
</template>

<script>
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import { random } from "lodash";
import ErrorMessages from "./partials/ErrorMessages.vue";
import FieldMessages from "./partials/FieldMessages.vue";
import useErrorMessages from "@utils/use/useErrorMessages";

export default {
  name: "BaseDropdown",

  setup(props) {
    const { errorMessages, hasErrors } = useErrorMessages(props.validation);

    return { errorMessages, hasErrors };
  },

  components: {
    Dropdown,
    ErrorMessages,
    FieldMessages,
    MultiSelect,
  },

  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },

    modelValue: {
      type: undefined,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    validation: {
      type: Object,
      required: false,
      default: () => {},
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    optionLabel: {
      type: String,
      required: false,
      default: "name",
    },

    messages: {
      type: Array,
      required: false,
      default: () => [],
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    floatingLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    showToggleAll: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    selectedValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    labelField() {
      const label = this.$t(this.label);
      const requiredLabel = `${label} *`;
      if (this.required) return requiredLabel;

      if (this.validation) {
        if ("required" in this.validation) {
          return requiredLabel;
        }
      }

      return label;
    },

    id() {
      return `dropdown-${random(100)}`;
    },

    hasLabel() {
      return !!this.label;
    },

    hasMessages() {
      return this.messages.length > 0;
    },
  },
};
</script>
