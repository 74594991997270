import * as chapters from "./modules/chapters";
import * as chapterItems from "./modules/chapterItems";
import * as courseRequests from "./modules/courseRequests";

export default {
  name: "acm",

  state: {},

  mutations: {},

  actions: {},

  getters: {},

  modules: {
    chapters,
    chapterItems,
    courseRequests,
  },
};
