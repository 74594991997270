<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12" class="pb-0">
        <BaseTextField
          label="acm.answers.add.form.label.answer"
          v-model="formValues.answerText"
          :validation="v$.answerText"
          :messages="bvShowErrors('answerText').messages"
          :error="bvShowErrors('answerText').hasMessages"
          :maxLength="maxLength.ANSWERS.TEXT"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseCheckbox
          v-if="canSetCorrect"
          v-model="formValues.isCorrect"
          label="acm.answers.add.form.label.isCorrect"
          :messages="bvShowErrors('isCorrect').messages"
          :error="bvShowErrors('isCorrect').hasMessages"
          :validation="v$.isCorrect"
        />
        <BaseText v-else>
          {{ $t("acm.answers.add.form.label.canBeCorrect") }}
        </BaseText>
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useBackendValidation from "@use/useBackendValidation";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "updateAnswerForm",

  setup(props, { emit }) {
    const route = useRoute();
    const { showSuccess } = useNotifications();
    const store = useStore();
    const courseRequestQuizQuestionAnswerId = ref(route.params.courseRequestQuizQuestionAnswerId);
    const canSetCorrect = ref(route.params.canSetCorrect === "true");

    // Initialize form values
    const form = reactive({
      courseRequestQuizQuestionAnswerId: courseRequestQuizQuestionAnswerId.value,
      answerText: route.params.answerText,
      isCorrect: route.params.isCorrect === "true",
    });

    // Setup validation
    const rules = {
      courseRequestQuizQuestionAnswerId: ["required"],
      answerText: ["required"],
      isCorrect: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "updateCourseRequestChapterItemQuizAnswer",
      onSuccess: async () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        emit("refresh");
        await store.dispatch("acm/chapterItems/reloadChapterItemsList", true);
        await store.dispatch("acm/chapters/reloadChapterList", true);
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
    };

    return {
      v$,
      isLoading,
      formValues,
      canSetCorrect,
      submit,
      bvShowErrors,
      maxLength,
    };
  },
};
</script>
