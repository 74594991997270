import { useVuelidate } from "@vuelidate/core";
import { password, mfaToken, checkBoxTrue, passwordConfirmation } from "@use/customValidators";
import {
  required,
  email,
  minLength,
  maxLength,
  between,
  minValue,
  maxValue,
  numeric,
  sameAs,
  not,
  and,
  or,
  url,
} from "@vuelidate/validators";
import { isArray } from "lodash";

/**
 * Validate form values
 *
 * @method
 * @param {object} params
 * @param {object} params.formValues Reactive object with all form values
 * @param {object} params.rules Object with validations rules for each form field
 * @returns {object} { formValue, v$ }
 */
const useFormValidation = ({ formValues, rules }) => {
  const validators = {
    required,
    email,
    minLength,
    maxLength,
    between,
    minValue,
    maxValue,
    numeric,
    sameAs,
    not,
    and,
    or,
    password,
    mfaToken,
    checkBoxTrue,
    url,
    passwordConfirmation,
  };

  const compiledRules = {};

  const validatorName = (key, params = null) => {
    if (!params) return validators[key];

    return validators[key](...params);
  };

  const getParams = (ruleArray) => {
    const params = [...ruleArray];
    params.shift();

    return params;
  };

  Object.keys(rules).forEach((key) => {
    const fieldRules = {};

    rules[key].forEach((ruleName) => {
      // Compile primitive rule name
      if (!isArray(ruleName)) {
        fieldRules[ruleName] = validatorName(ruleName);

        return;
      }

      // Compile rule with params
      if (isArray(ruleName)) {
        const ruleNameWithParams = ruleName[0];
        const ruleParams = getParams(ruleName);
        let nestedValidators;

        // Compile nested rules with one or multiple validators (or, and, not)
        if (isArray(ruleName[1])) {
          const nestedRules = getParams(ruleName);

          nestedValidators = nestedRules.map((validator) =>
            validatorName(validator[0], getParams(validator).length ? getParams(validator) : null)
          );
        }

        fieldRules[ruleNameWithParams] = validatorName(
          ruleNameWithParams,
          isArray(ruleName[1]) ? nestedValidators : ruleParams
        );
      }
    });

    compiledRules[key] = fieldRules;
  });

  const v$ = useVuelidate(compiledRules, formValues);

  return { formValues, v$ };
};
export default useFormValidation;
