<template>
  <AnswerPreview
    v-for="(preview, index) in answerItems"
    :key="index"
    :answer-data="preview"
    :can-set-correct="canSetCorrect(preview)"
  />
</template>

<script>
import AnswerPreview from "./AnswerPreview";

export default {
  name: "AnswerItems",

  components: {
    AnswerPreview,
  },

  props: {
    answerItems: {
      type: Array,
      required: true,
    },
    multipleAnswers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    canSetCorrect(preview) {
      return (
        (!preview.isCorrect &&
          this.answerItems.filter((answer) => answer.isCorrect).length === 0 &&
          !this.multipleAnswers) ||
        preview.isCorrect ||
        this.multipleAnswers
      );
    },
  },
};
</script>
