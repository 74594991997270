<template>
  <Breadcrumb v-bind="$attrs" :home="home" :model="items" v-if="items.length > 0" class="pt-2" />
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";

export default {
  name: "BaseBreadcrumb",

  components: {
    Breadcrumb,
  },

  data() {
    return {
      home: { icon: "pi pi-home", to: "/" },
    };
  },

  computed: {
    items() {
      const routes = this.$route.matched.filter((match) => "displayName" in match.meta);

      routes.pop();

      const breadcrumbItems = routes.map(({ meta, path }) => ({
        label: this.$t(meta.displayName),
        to: path,
      }));

      if ("displayName" in this.$route.meta) {
        breadcrumbItems.push({
          label: this.$t(this.$route.meta.displayName),
          to: this.$route.fullPath,
        });
      }

      return breadcrumbItems;
    },
  },
};
</script>
<style lang="scss">
.p-breadcrumb {
  border: none;
  padding: 0;

  .p-menuitem-text {
    font-size: 14px !important;
    color: var(--text-color-secondary) !important;
  }
}

.p-breadcrumb-chevron {
  color: var(--surface-500) !important;
  font-size: 12px;
}
</style>
