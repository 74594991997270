<template>
  <small
    class="text-right mr-1 mt-1 text-xs white-space-nowrap"
    :class="{ 'p-error': hasMaxLengthError }"
    v-if="maxLength"
  >
    {{
      $t("hac.fieldLength", {
        current: characterCount,
        max: maxLength,
      })
    }}
  </small>
</template>

<script>
export default {
  props: {
    value: {
      type: undefined,
      required: false,
      default: null,
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    characterCount() {
      if (!this.value) {
        return 0;
      }

      return this.value.length;
    },

    hasMaxLengthError() {
      return this.value && this.value.length > this.maxLength;
    },
  },
};
</script>
