<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.management.courseTopics.create.labels.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          :messages="bvShowErrors('displayName').messages"
          :error="bvShowErrors('displayName').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextArea
          label="acm.management.courseTopics.create.labels.description"
          v-model="formValues.description"
          :validation="v$.description"
          :messages="bvShowErrors('description').messages"
          :error="bvShowErrors('description').hasMessages"
          floatingLabel
          rows="12"
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseFileUpload
          name="icon"
          :messages="bvShowErrors('icon').messages"
          :error="bvShowErrors('icon').hasMessages"
          :thumbnail-handler="handleFile"
          :max-file-size="sizes['2MB']"
          accept="image/png,image/jpeg"
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseCheckbox
          label="acm.management.courseTopics.create.labels.isActive"
          v-model="formValues.isActive"
          :validation="v$.isActive"
          :messages="bvShowErrors('isActive').messages"
          :error="bvShowErrors('isActive').hasMessages"
          class="mt-2"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton
        label="acm.management.courseTopics.create.submit"
        @click="submit"
        :loading="loading"
      />
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import { reactive, watch } from "vue";
import useBackendValidation from "@use/useBackendValidation";
import sizes from "@/modules/haw-components-hac/constants/sizes";

export default {
  name: "CreateForm",

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    backendValidation: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const form = reactive({
      displayName: null,
      description: null,
      icon: null,
      isActive: true,
    });

    const rules = {
      displayName: ["required"],
      description: ["required"],
    };

    const { updateValidation, bvMessage, bvHasErrors, bvShowErrors } = useBackendValidation();
    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      emit("submit", formValues);
      v$.value.$reset();
    };

    const handleFile = (event) => {
      formValues.icon = event.files[0];
    };

    watch(props, () => {
      if (props.backendValidation) {
        updateValidation(props.backendValidation);
      }
    });

    return {
      v$,
      formValues,
      submit,
      handleFile,
      bvMessage,
      bvHasErrors,
      bvShowErrors,
      sizes,
    };
  },
};
</script>
