<template>
  <DataTable
    :value="courseProposals"
    lazy
    paginator
    :rows="meta.per_page"
    :totalRecords="meta.total"
    :rowsPerPageOptions="[10, 20, 50]"
    :loading="loading"
    @page="changePage($event)"
    dataKey="id"
    rowHover
    autoLayout
    class="p-datatable-sm"
  >
    <template #empty>
      <div class="text-center py-4">{{ $t("utils.table.empty") }}</div>
    </template>

    <Column field="title" :header="$t('acm.management.courseProposals.list.header.title')">
      <template #body="slotProps">
        <span>
          {{ slotProps.data.title }}
        </span>
      </template>
    </Column>

    <Column field="teacher" :header="$t('acm.management.courseProposals.list.header.teacher')">
      <template #body="slotProps">
        <span> {{ slotProps.data.teacher.firstName }} {{ slotProps.data.teacher.lastName }} </span>
      </template>
    </Column>

    <Column
      field="description"
      :header="$t('acm.management.courseProposals.list.header.description')"
    >
      <template #body="slotProps">
        <span>
          {{ shortenedDescription(slotProps.data.description) }}
        </span>
      </template>
    </Column>

    <Column field="status">
      <template #header>
        <BaseIcon icon="pi pi-check" />
      </template>
      <template #body="slotProps">
        <template
          v-for="(status, index) in [getDataByStatus(slotProps.data.courseProposalStatusId)]"
          :key="index"
        >
          <BaseIcon v-if="status" :class="status.class" :icon="status.icon" />
        </template>
      </template>
    </Column>

    <Column field="view">
      <template #header>
        <BaseIcon icon="pi pi-eye" />
      </template>
      <template #body="slotProps">
        <BaseIcon
          icon="pi pi-eye"
          class="cursor-pointer"
          @click="viewProposal(slotProps.data.id)"
        />
      </template>
    </Column>

    <Column field="createdAt" :header="$t('acm.management.courseProposals.list.header.createdAt')">
      <template #body="slotProps">
        {{ dateTime(slotProps.data.createdAt) }}
      </template>
    </Column>

    <template #paginatorLeft>
      <BaseButton
        icon="pi pi-refresh"
        rounded
        background="gray-100"
        color="gray-500"
        :loading="loading"
        @click="refresh($event)"
        v-tooltip="$t('acm.management.courseProposals.list.tooltip.refresh')"
      />
    </template>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import dateFilter from "@filter/dateFilter";
import COURSE_PROPOSAL_STATUS from "@/modules/advanced-course-management-acm/constants/COURSE_PROPOSAL_STATUS";
import { shortenStringToMaxLength } from "@/modules/advanced-course-management-acm/helpers/ShortenStringAtSpace";

export default {
  name: "ListTable",

  mixins: [dateFilter],

  components: {
    DataTable,
    Column,
  },

  props: {
    courseProposals: {
      type: Array,
      required: true,
      default: () => [],
    },

    meta: {
      type: Object,
      required: true,
      default: () => {
        return {
          // eslint-disable-next-line camelcase
          current_page: null,
          // eslint-disable-next-line camelcase
          per_page: null,
          total: null,
        };
      },
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    callApi: {
      type: Function,
      required: false,
    },
  },

  methods: {
    refresh() {
      this.callApi({ page: this.meta.current_page, itemsPerPage: this.meta.per_page });
    },

    changePage(event) {
      this.callApi({ page: event.page + 1, itemsPerPage: event.rows });
    },

    shortenedDescription(description) {
      return shortenStringToMaxLength(description, 100);
    },

    viewProposal(courseProposalId) {
      this.$router.push({ name: "ManagementCourseProposalShow", params: { courseProposalId } });
    },

    getDataByStatus(statusId) {
      if (statusId === COURSE_PROPOSAL_STATUS.APPROVED) {
        return { icon: "pi pi-check", class: "text-success" };
      }

      if (statusId === COURSE_PROPOSAL_STATUS.DISMISSED) {
        return { icon: "pi pi-times", class: "text-danger" };
      }

      return null;
    },
  },
};
</script>
