<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ chapterInformation.displayName }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <UpdateChapterForm
              v-if="hasDocs"
              :chapter-information="chapterInformation"
              @refresh="reload"
            />
          </BaseCol>
        </BaseRow>

        <BaseRow class="justify-content-end"> </BaseRow>
      </BaseCard>

      <BaseCard class="p-3 mt-4">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.chapters.items.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <ChapterItems />
          </BaseCol>
          <BaseCol cols="12">
            <ContentTypeMenu />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import UpdateChapterForm from "./partials/UpdateChapterForm";
import ChapterItems from "./partials/ChapterItems";
import ContentTypeMenu from "../addContent/partials/ContentTypeMenu";
import { useRoute } from "vue-router";
import { reactive, watch } from "vue";
import BaseCard from "@/modules/base-components-bc/Base/BaseCard";

export default {
  name: "ViewChapter",

  components: {
    BaseCard,
    HAWPageContent,
    UpdateChapterForm,
    ChapterItems,
    ContentTypeMenu,
  },

  setup() {
    const route = useRoute();
    const { courseRequestChapterId } = route.params;

    // Initialize form values
    const chapterInformation = reactive({
      courseRequestChapterId: parseInt(courseRequestChapterId, 10),
      displayName: null,
      description: null,
      order: null,
    });

    const { docs, callApi, isLoading, hasDocs } = useApi({
      module: "acm",
      method: "showCourseRequestChapter",
      params: { courseRequestChapterId },
      onMount: true,
      onSuccess: () => {
        chapterInformation.displayName = docs.value.data.displayName;
        chapterInformation.description = docs.value.data.description;
        chapterInformation.order = docs.value.data.order;
      },
    });

    // Emit form values
    const reload = async () => {
      await callApi({});
    };

    watch(route, () => {
      let id = parseInt(route.params.courseRequestChapterId, 10);

      if (!id) {
        return;
      }

      chapterInformation.courseRequestChapterId = id;
      callApi({ courseRequestChapterId: chapterInformation.courseRequestChapterId });
    });

    return {
      isLoading,
      chapterInformation,
      reload,
      hasDocs,
      courseRequestChapterId,
    };
  },
};
</script>

<style scoped lang="scss">
.max-w-1024 {
  max-width: 1024px;
  margin: 0 auto;
}
</style>
