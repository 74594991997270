<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />

      <BaseCard v-if="hasDocs" class="p-3 mb-3">
        <BaseRow>
          <BaseCol cols="12" class="flex justify-content-between">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.question.update.title") }}
            </BaseHeadline>
            <div>
              <BaseDeleteData
                update-module="acm"
                update-method="deleteCourseRequestChapterItemQuizQuestion"
                :formData="{ courseRequestQuizQuestionId }"
                icon="pi pi-trash"
                deleteDialogMessage="acm.question.delete.dialog.message"
                deleteDialogHeader="acm.question.delete.dialog.header"
                outlined
                :onDeleted="goBack"
              />
            </div>
          </BaseCol>
          <BaseCol cols="12">
            <UpdateQuestionForm :question-data="questionData" @refresh="reload" />
          </BaseCol>
        </BaseRow>
      </BaseCard>

      <BaseCard v-if="hasDocs" class="p-3 mt-4">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.answers.items.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <AnswerItems
              v-if="questionData.courseRequestQuizQuestionAnswers.length"
              :answer-items="questionData.courseRequestQuizQuestionAnswers"
              :multiple-answers="questionData.courseQuizQuestionTypeId === 2"
            />
            <BaseText v-else>
              {{ $t("acm.answers.items.label.noAnswers") }}
            </BaseText>
          </BaseCol>
          <BaseCol cols="12">
            <AnswerMenu :menu-items="answerMenuItems" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import UpdateQuestionForm from "../viewQuestion/partials/UpdateQuestionForm";
import AnswerItems from "./partials/AnswerItems";
import AnswerMenu from "./partials/AnswerMenu";
import useNotifications from "@use/useNotifications";
import { useRoute, useRouter } from "vue-router";
import { reactive, ref } from "vue";

export default {
  name: "ViewQuestion",

  components: {
    HAWPageContent,
    UpdateQuestionForm,
    AnswerItems,
    AnswerMenu,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { showError } = useNotifications();
    const courseRequestQuizQuestionId = ref(parseInt(route.params.courseRequestQuizQuestionId, 10));
    const questionData = ref({});

    let { courseRequestChapterItemId } = route.params;

    const answerMenuItems = reactive([]);

    const { docs, callApi, hasDocs, backendValidation } = useApi({
      module: "acm",
      method: "showCourseRequestChapterItem",
      params: { courseRequestChapterItemId },
      onMount: true,
      onSuccess: () => {
        questionData.value = docs.value.data.model.courseRequestQuizQuestions.filter((question) => {
          return question.id === courseRequestQuizQuestionId.value;
        })[0];

        if (!answerMenuItems.length) {
          answerMenuItems.push({
            label: "acm.answers.label.add",
            to: {
              name: "AddAnswer",
              params: { courseRequestQuizQuestionId },
            },
          });
        }
      },
      onError: () => showError(backendValidation.value.message, ""),
    });

    // Emit load quiz
    const reload = async () => {
      await callApi({});
    };

    const goBack = () => {
      router.go(-1);
    };

    return {
      courseRequestQuizQuestionId,
      questionData,
      answerMenuItems,
      hasDocs,
      goBack,
      reload,
    };
  },
};
</script>

<style scoped lang="scss">
.max-w-1024 {
  max-width: 1024px;
  margin: 0 auto;
}
</style>
