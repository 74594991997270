<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12" class="pb-0">
        <BaseTextField
          label="acm.question.update.form.label.question"
          v-model="formValues.questionText"
          :validation="v$.questionText"
          :messages="bvShowErrors('questionText').messages"
          :error="bvShowErrors('questionText').hasMessages"
          :maxLength="maxLength.QUESTIONS.TEXT"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12" class="pb-0">
        <BaseTextField
          label="acm.question.update.form.label.tip"
          v-model="formValues.questionTooltip"
          :validation="v$.questionTooltip"
          :messages="bvShowErrors('questionTooltip').messages"
          :error="bvShowErrors('questionTooltip').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseText v-if="singleChoice">
          {{ $t("acm.question.update.form.label.singleChoice") }}
        </BaseText>
        <BaseText v-else>{{ $t("acm.question.update.form.label.multipleChoice") }}</BaseText>
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { reactive, watch } from "vue";
import { useStore } from "vuex";
import useBackendValidation from "@use/useBackendValidation";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "UpdateQuestionForm",

  props: {
    questionData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { showSuccess } = useNotifications();
    const store = useStore();
    const singleChoice = parseInt(props.questionData.courseQuizQuestionTypeId, 10) === 1;

    // Initialize form values
    const form = reactive({
      courseRequestQuizQuestionId: parseInt(props.questionData.id, 10),
      questionText: props.questionData.questionText,
      questionTooltip: props.questionData.questionTooltip,
      order: props.questionData.order,
    });

    // Setup validation
    const rules = {
      courseRequestQuizQuestionId: ["required"],
      questionText: ["required"],
      questionTooltip: [],
      order: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "updateCourseRequestChapterItemQuizQuestion",
      onSuccess: async () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        emit("refresh");
        await store.dispatch("acm/chapterItems/reloadChapterItemsList", true);
        await store.dispatch("acm/chapters/reloadChapterList", true);
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
    };

    return {
      v$,
      isLoading,
      formValues,
      singleChoice,
      submit,
      bvShowErrors,
      maxLength,
    };
  },
};
</script>
