<template>
  <div class="video-viewer">
    <div v-if="!shouldShowVideo && thumbnail" class="video-viewer__thumbnail">
      <img :src="thumbnail" alt="Thumbnail" @click="showVideo" />
      <div class="play-button" @click="showVideo">
        <BaseIcon icon="pi pi-play" size="3" class="pl-2" color="gray-800" />
      </div>
    </div>

    <div v-else class="video-viewer__video">
      <video class="w-full" controls :autoplay="thumbnail ? '1' : ''" controlsList="nodownload">
        <source :src="video" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    thumbnail: {
      type: String,
      required: false,
      default: null,
    },
    video: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      shouldShowVideo: false,
    };
  },

  methods: {
    showVideo() {
      this.shouldShowVideo = true;
    },
  },
};
</script>

<style lang="scss">
.video-viewer {
  &__thumbnail {
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
    }

    .play-button {
      width: 80px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
