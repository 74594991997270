import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * @module API_hac_countries
 */

/**
 * List countries
 * @method
 * @param {object} params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCountries = ({ page, itemsPerPage, search }) =>
  api.get(ROUTE.LIST, {
    params: {
      page,
      itemsPerPage,
      search,
    },
  });
