<template>
  <BaseHasPermission permission="user_group.delete">
    <div @click="confirmDialog" class="cursor-pointer" v-bind="$attrs">
      <slot />
    </div>
  </BaseHasPermission>
</template>

<script>
import useApi from "@use/useApi";
import useConfirmDialog from "@use/useConfirmDialog";

export default {
  name: "DeleteWrapper",

  inheritAttrs: false,

  props: {
    userGroupId: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { callApi } = useApi({
      module: "usm",
      method: "deleteUserGroup",
      onSuccess: () => {
        emit("reload");
      },
    });

    const { confirmDialog } = useConfirmDialog({
      message: "usm.userGroups.delete.dialog.message",
      header: "usm.userGroups.delete.dialog.header",
      onAccept: () => {
        callApi({ userGroupId: props.userGroupId });
      },
    });

    return { confirmDialog };
  },
};
</script>
