import store from "@/store";

/**
 * Check if user has permissions
 *
 * @param {string} modulePermission
 *
 * @returns {boolean}
 */
const hasPermission = (modulePermission) => {
  const userPermissions = store.getters["usm/authUser/permissions"];

  if (!userPermissions) {
    return false;
  }

  return userPermissions.includes(modulePermission);
};

export default hasPermission;
