import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_roles
 */

/**
 * Retrieve a list of all roles
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listRoles = (params) => api.get(ROUTE.ROLES.LIST, { params });

/**
 * Retrieve specified role
 * @method
 * @param {object} params
 * @param {number} params.roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showRole = ({ roleId }) => api.get(ROUTE.ROLES.SHOW, { params: { roleId } });

/**
 * Delete specified role
 * @method
 * @param {object} params
 * @param {number} params.roleId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteRole = ({ roleId }) => api.delete(ROUTE.ROLES.DELETE, { data: { roleId } });

/**
 * Create a new role
 * @method
 * @param {object} params
 * @param {string} params.name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createRole = ({ name }) => api.post(ROUTE.ROLES.CREATE, { name });

/**
 * Update specified role
 * @method
 * @param {object} params
 * @param {string} params.roleId
 * @param {string} params.name
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateRole = ({ name, roleId }) => api.put(ROUTE.ROLES.UPDATE, { name, roleId });

/**
 * Add or remove permissions from role
 *
 * @method
 * @param {object} params
 * @param {number} params.roleId
 * @param {array<number>} params.permissionIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncRolePermissions = ({ roleId, permissionIds }) =>
  api.put(ROUTE.ROLES.SYNC.PERMISSIONS, {
    roleId,
    permissionIds,
  });

/**
 * Add or remove userGroup from role
 *
 * @method
 * @param {object} params
 * @param {number} params.roleId
 * @param {array<number>} params.userGroupIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncRoleUserGroups = ({ roleId, userGroupIds }) =>
  api.put(ROUTE.ROLES.SYNC.USER_GROUPS, {
    roleId,
    userGroupIds,
  });

/**
 * Add or remove users from role
 *
 * @method
 * @param {object} params
 * @param {number} params.roleId
 * @param {array<number>} params.userIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncRoleUsers = ({ roleId, userIds }) =>
  api.put(ROUTE.ROLES.SYNC.USER, {
    roleId,
    userIds,
  });
