<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />
      <BaseCard class="p-3">
        <BaseRow v-if="hasDocs">
          <BaseCol cols="12" class="flex justify-content-between">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.contentTypes.rte.title") }}
            </BaseHeadline>
            <div>
              <BaseDeleteData
                update-module="acm"
                update-method="deleteCourseRequestChapterItemContent"
                :formData="{ courseRequestContentId: rteInformation.courseRequestContentId }"
                icon="pi pi-trash"
                deleteDialogMessage="acm.content.delete.dialog.message"
                deleteDialogHeader="acm.content.delete.dialog.header"
                outlined
                :onDeleted="goBack"
              />
            </div>
          </BaseCol>
          <BaseCol cols="12" class="mb-4">
            <UpdateRTEContent :rte-information="rteInformation" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import { useRoute, useRouter } from "vue-router";
import { reactive } from "vue";
import UpdateRTEContent from "./partials/UpdateRTEContent";
import useNotifications from "@use/useNotifications";

export default {
  name: "CourseUpdate",

  components: {
    HAWPageContent,
    UpdateRTEContent,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { showError } = useNotifications();
    let { courseRequestChapterItemId } = route.params;

    // Initialize form values
    const rteInformation = reactive({
      courseRequestContentId: null,
      displayName: null,
      preview: null,
      content: null,
      order: null,
    });

    const { docs, callApi, hasDocs, backendValidation } = useApi({
      module: "acm",
      method: "showCourseRequestChapterItem",
      params: { courseRequestChapterItemId },
      onMount: true,
      onSuccess: () => {
        rteInformation.courseRequestContentId = docs.value.data.model.id;
        rteInformation.displayName = docs.value.data.displayName;
        rteInformation.preview = docs.value.data.model.preview;
        rteInformation.content = docs.value.data.model.content;
        rteInformation.order = docs.value.data.order;
      },
      onError: () => showError(backendValidation.value.message, ""),
    });

    // Emit form values
    const reload = async () => {
      await callApi({});
    };

    const goBack = () => {
      router.go(-1);
    };

    return {
      rteInformation,
      hasDocs,
      reload,
      goBack,
    };
  },
};
</script>
