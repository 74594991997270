import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve information material
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showInformationMaterial = () => api.get(ROUTE.INFORMATION_MATERIAL.READ);
