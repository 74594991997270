export const namespaced = true;

export const state = {
  expandedNavigation: [],
};

export const getters = {
  expandedNavigation: (state) => state.expandedNavigation,
};

export const mutations = {
  SET_EXPANDED_NAVIGATION(state, { expandedNavigation }) {
    if (!state.expandedNavigation.includes(expandedNavigation)) {
      state.expandedNavigation = [...state.expandedNavigation, expandedNavigation];
    }
  },
  TOGGLE_EXPANDED_NAVIGATION(state, { expandedNavigation }) {
    if (state.expandedNavigation.includes(expandedNavigation)) {
      state.expandedNavigation = state.expandedNavigation.filter((item) => {
        return item !== expandedNavigation;
      });
    } else {
      state.expandedNavigation = [...state.expandedNavigation, expandedNavigation];
    }
  },
  CLOSE_ALL_EXPANDED_NAVIGATION(state) {
    state.expandedNavigation = [];
  },
};

export const actions = {
  expand({ commit }, expandedNavigation) {
    return new Promise((resolve) => {
      commit("SET_EXPANDED_NAVIGATION", { expandedNavigation });
      resolve();
    });
  },
  toggle({ commit }, expandedNavigation) {
    return new Promise((resolve) => {
      commit("TOGGLE_EXPANDED_NAVIGATION", { expandedNavigation });
      resolve();
    });
  },
  closeAll({ commit }) {
    return new Promise((resolve) => {
      commit("CLOSE_ALL_EXPANDED_NAVIGATION");
      resolve();
    });
  },
};
