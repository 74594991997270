<template>
  <div v-if="!isLoading">
    <CourseRequestChapterItem
      v-for="item in courseRequestChapterItems"
      :key="item.id"
      :course-request-chapter-item-id="item.id"
    />
  </div>
  <div v-else class="flex justify-content-center py-5">
    <BaseProgressSpinner size="50" strokeWidth="3" />
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useApi from "@use/useApi";
import CourseRequestChapterItem from "./CourseRequestChapterItem";

export default {
  props: {
    courseRequestChapterId: {
      type: undefined,
      required: true,
      default: null,
    },
  },

  components: { CourseRequestChapterItem },

  setup(props) {
    const courseRequestChapterId = ref(props.courseRequestChapterId);
    const courseRequestChapterItems = ref({});

    const { callApi, isLoading, docs } = useApi({
      module: "acm",
      method: "managementListChapterItems",
      onMount: true,
      params: {
        courseRequestChapterId: courseRequestChapterId.value,
      },
      onSuccess() {
        courseRequestChapterItems.value = docs.value.data;
      },
    });

    watch(courseRequestChapterId, () => {
      callApi();
    });

    return {
      isLoading,
      courseRequestChapterItems,
    };
  },
};
</script>
