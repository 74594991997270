<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.contentTypes.rte.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12" class="mb-4">
            <AddRTEContent :courseRequestChapterId="courseRequestChapterId" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import { useRoute } from "vue-router";
import { ref } from "vue";
import AddRTEContent from "./partials/AddRTEContent";

export default {
  name: "CourseUpdate",

  components: {
    HAWPageContent,
    AddRTEContent,
  },

  setup() {
    const route = useRoute();
    const courseRequestId = ref(route.params.courseRequestId);
    const courseRequestChapterId = ref(parseInt(route.params.courseRequestChapterId, 10));

    return {
      courseRequestId,
      courseRequestChapterId,
    };
  },
};
</script>
