<template>
  <ProgressSpinner v-bind="$attrs" :style="styleComputed" />
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";
export default {
  name: "BaseProgressSpinner",

  components: {
    ProgressSpinner,
  },

  props: {
    size: {
      type: undefined,
      required: false,
      default: null,
    },
  },

  computed: {
    styleComputed() {
      if (this.size) {
        return `width: ${this.size}px; height: ${this.size}px;`;
      }

      return null;
    },
  },
};
</script>
