const Base = () => import("../../haw-components-hac/views");
const Reports = () => import("../views/Reports");

export default [
  {
    path: "/reports",
    component: Base,
    children: [
      {
        path: "",
        name: "Reports",
        component: Reports,
      },
    ],
  },
];
