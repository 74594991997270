import { createStore } from "vuex";
import vuexStoreCaching from "@utils/helper/vuexStoreCaching";
import storageHelper from "@utils/helper/localStorage";
import storageNames from "@utils/data/storageNames";

export default createStore({
  state: {},

  mutations: {
    INITIALIZE_STATE(state, cachedState) {
      Object.assign(state, cachedState);
    },
  },

  actions: {
    initializeState({ state, commit }) {
      const cachedState = storageHelper.read(storageNames.VUEX_STORE);
      if (cachedState) {
        commit("INITIALIZE_STATE", vuexStoreCaching.read(state, cachedState));
      }
    },
  },

  modules: {},
});
