import menuType from "@data/menuTypes";
import menuName from "@data/menuNames";

export default [
  {
    label: "tm.navigation.teachers",
    to: "/management/teachers",
    permissions: ["management.teacher.read"],
    icon: "pi pi-user",
    menuName: menuName.get().management,
    types: [menuType.get().all],
    order: 10,
  },
];
