<template>
  <BaseRte v-bind="$attrs" :toolbar="toolbarOptions" />
</template>

<script>
export default {
  name: "ConfiguredRte",
  data() {
    return {
      toolbarOptions: {
        dropdown: {
          header: [1, 2, 3],
        },
        formats: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          ["clean"],
        ],
      },
    };
  },
};
</script>
