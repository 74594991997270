<template>
  <div class="question-preview mb-3 border-radius--8">
    <BaseRow class="justify-content-between align-items-center px-3 pb-2 pt-3">
      <BaseCol>
        {{ answerData.answerText }}
        <BaseIcon v-if="answerData.isCorrect" icon="pi pi-check" color="gc-color-success" />
      </BaseCol>
      <BaseCol class="flex-grow-0">
        <BaseButton @click="edit" icon="pi pi-pencil" />
      </BaseCol>
    </BaseRow>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "AnswerPreview",

  props: {
    answerData: {
      type: Object,
      required: true,
    },
    canSetCorrect: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props) {
    const router = useRouter();

    const edit = () => {
      router.push({
        name: "ViewAnswer",
        params: Object.assign(props.answerData, {
          courseRequestQuizQuestionAnswerId: props.answerData.id,
          canSetCorrect: props.canSetCorrect,
        }),
      });
    };

    return {
      edit,
    };
  },
};
</script>

<style lang="scss" scoped>
.question-preview {
  border: 1px solid #ced4da;
}
</style>
