<template>
  <HAWPageContent :breadcrumb="false">
    <BaseHeadline size="1" fake="4">{{ $t("acm.management.courseProposals.title") }}</BaseHeadline>
    <ListTable
      :courseProposals="courseProposals"
      :meta="meta"
      :callApi="load"
      :loading="isLoading"
    />
    <BaseCheckbox
      v-model="showAll"
      label="acm.courseProposals.list.checkbox.showAll"
      class="mt-2"
    />
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref, watch } from "vue";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import ListTable from "./partials/ListTable.vue";
import COURSE_PROPOSAL_STATUS from "@/modules/advanced-course-management-acm/constants/COURSE_PROPOSAL_STATUS";

export default {
  name: "CourseProposalsList",

  components: {
    HAWPageContent,
    ListTable,
  },

  setup() {
    const courseProposals = ref([]);
    const meta = ref({});
    const showAll = ref(false);

    const load = async (params = {}) => {
      if (showAll.value) {
        params.courseProposalStatusIds = null;
      }
      callApi(params);
    };

    watch(showAll, () => {
      load();
    });

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "acm",
      method: "managementListCourseProposals",
      params: {
        page: 1,
        itemsPerPage: 10,
        courseProposalStatusIds: [COURSE_PROPOSAL_STATUS.REVIEW],
      },
      onMount: true,
      showQueries: true,
      ignoreQueries: ["courseProposalStatusIds"],
      onSuccess: () => {
        courseProposals.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    return { courseProposals, meta, hasDocs, isLoading, callApi, showAll, load };
  },
};
</script>
