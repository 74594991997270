<template>
  <div>
    <label class="ml-3" for="id" v-html="labelField"></label>
    <InputSwitch v-model="checkboxValue" :id="id" v-bind="$attrs" :disabled="disabled" />
    <div class="ml-1" :id="`${id}-help`">
      <FieldMessages :messages="messages" v-if="hasMessages && !hasErrors" />
      <ErrorMessages :messages="errorMessages" v-if="hasErrors" />
    </div>
  </div>
</template>

<script>
import InputSwitch from "primevue/inputswitch";
import { random } from "lodash";
import ErrorMessages from "./partials/ErrorMessages.vue";
import FieldMessages from "./partials/FieldMessages.vue";
import useErrorMessages from "@utils/use/useErrorMessages";

export default {
  name: "BaseInputSwitch",
  inheritAttrs: false,

  components: {
    InputSwitch,
    ErrorMessages,
    FieldMessages,
  },

  setup(props) {
    const { errorMessages, hasErrors } = useErrorMessages(props.validation);

    return { errorMessages, hasErrors };
  },

  props: {
    modelValue: {
      type: undefined,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    validation: {
      type: Object,
      required: false,
      default: () => {},
    },

    messages: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    checkboxValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    id() {
      return `switch-${random(100)}`;
    },

    labelField() {
      const label = this.$t(this.label);
      if (this.required) return `${label} *`;

      return label;
    },

    hasMessages() {
      return this.messages.length > 0;
    },
  },
};
</script>
