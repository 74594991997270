const HAWBase = () => import("../../haw-components-hac/views");
const HAWBrowseContent = () => import("@/modules/haw-components-hac/Shared/HAWBrowseContent");
const HAWCourseShow = () => import("../components/layouts/HAWCourseShow");

const StartPage = () => import("../views/StartPage");

const Courses = () => import("../views/Course/list");
const CoursesShow = () => import("../views/Course/show");
const Cart = () => import("../views/Cart/show");
const CartCheckout = () => import("../views/Cart/checkout");
const CartCheckoutSuccess = () => import("../views/Cart/success");

const MyDashboard = () => import("../views/My/Dashboard");
const MyCourses = () => import("../views/My/Course/list");
const MyCoursesShow = () => import("../views/My/Course/show");
const MyCoursesShowChapter = () => import("../views/My/Course/Chapter/show/index");
const MyCoursesShowChapterItem = () => import("../views/My/Course/Chapter/Item/show/index");

export default [
  {
    path: "/",
    component: HAWBrowseContent,
    children: [
      {
        path: "",
        name: "StartPage",
        component: StartPage,
      },
      {
        path: "/courses",
        name: "Courses",
        component: Courses,
      },
      {
        path: "/courses/:courseId",
        name: "CoursesShow",
        component: CoursesShow,
      },
      {
        path: "/cart",
        name: "Cart",
        component: Cart,
      },
      {
        path: "/cart/checkout",
        name: "CartCheckout",
        component: CartCheckout,
      },
      {
        path: "/cart/checkout/success",
        name: "CartCheckoutSuccess",
        component: CartCheckoutSuccess,
      },
    ],
  },

  {
    path: "/my",
    component: HAWBase,
    children: [
      {
        path: "",
        name: "MyDashboard",
        component: MyDashboard,
      },
      {
        path: "courses",
        name: "MyCourses",
        component: MyCourses,
      },
    ],
  },

  {
    path: "/my",
    component: HAWCourseShow,
    children: [
      {
        path: "courses/:courseId",
        name: "MyCoursesShow",
        component: MyCoursesShow,
      },
      {
        path: "courses/:courseId/chapters/:courseChapterId",
        name: "MyCoursesShowChapter",
        component: MyCoursesShowChapter,
      },
      {
        path: "courses/:courseId/chapters/:courseChapterId/items/:courseChapterItemId",
        name: "MyCoursesShowChapterItem",
        component: MyCoursesShowChapterItem,
      },
    ],
  },
];
