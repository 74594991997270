import api from "@/api";
import ROUTE from "../../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve a list of all course proposals
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementListCourseProposals = (params) =>
  api.get(ROUTE.MANAGEMENT.COURSES.PROPOSAL.LIST, { params });

/**
 * Retrieve a list of all course proposals
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseProposals = (params) => api.get(ROUTE.COURSES.PROPOSAL.LIST, { params });

/**
 * Create a new course proposal
 * @method
 * @param {object} params
 * @param {string} params.title
 * @param {string} [params.description]
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createCourseProposal = ({ title, description }) =>
  api.post(ROUTE.COURSES.PROPOSAL.CREATE, { title, description });

/**
 * Retrieve specified course proposal
 * @method
 * @param {object} params
 * @param {number} params.courseProposalId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementShowCourseProposal = ({ courseProposalId }) =>
  api.get(ROUTE.MANAGEMENT.COURSES.PROPOSAL.SHOW, { params: { courseProposalId } });

/**
 * Retrieve specified course proposal
 * @method
 * @param {object} params
 * @param {number} params.courseProposalId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourseProposal = ({ courseProposalId }) =>
  api.get(ROUTE.COURSES.PROPOSAL.SHOW, { params: { courseProposalId } });

/**
 * Delete specified course proposal
 * @method
 * @param {object} params
 * @param {number} params.courseProposalId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseProposal = ({ courseProposalId }) =>
  api.delete(ROUTE.COURSES.PROPOSAL.DELETE, { data: { courseProposalId } });

/**
 * Update specified course proposal
 * @method
 * @param {object} params
 * @param {number} params.courseProposalId
 * @param {string} params.title
 * @param {string} params.description
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseProposal = ({ courseProposalId, title, description }) =>
  api.put(ROUTE.COURSES.PROPOSAL.UPDATE, {
    courseProposalId,
    title,
    description,
  });

/**
 * Approve specified course proposal
 * @method
 * @param {object} params
 * @param {string} params.courseProposalId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const approveCourseProposal = ({ courseProposalId }) =>
  api.put(ROUTE.MANAGEMENT.COURSES.PROPOSAL.APPROVE, { courseProposalId });

/**
 * Dismiss specified course proposal
 * @method
 * @param {object} params
 * @param {number} params.courseProposalId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const dismissCourseProposal = ({ courseProposalId }) =>
  api.put(ROUTE.MANAGEMENT.COURSES.PROPOSAL.DISMISS, { courseProposalId });
