import { ref, onMounted, onUnmounted } from "vue";
import useApi from "@use/useApi";
import { useStore } from "vuex";

/**
 * Validate access token
 *
 * @method
 * @returns {object} { tokenIsValid, tokenExpiresIn }
 */
export const useTokenValidation = () => {
  const tokenIsValid = ref(false);
  const tokenExpiresIn = ref(null);
  const checkTokenInInterval = ref(null);

  const store = useStore();

  const { docs, callApi } = useApi({
    module: "usm",
    method: "validateToken",
    onMount: true,
    onSuccess: () => {
      tokenIsValid.value = docs.value.authenticated;
      tokenExpiresIn.value = docs.value.expiresInSeconds;

      if (!docs.value.authenticated) {
        store.dispatch("usm/auth/removeToken");
      }
    },
  });

  const validate = () => {
    callApi();
  };

  onMounted(() => {
    checkTokenInInterval.value = setInterval(() => validate(), 60000);
  });

  onUnmounted(() => {
    clearInterval(checkTokenInInterval.value);
  });

  return { tokenIsValid, tokenExpiresIn };
};

export default useTokenValidation;
