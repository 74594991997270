<template>
  <BaseButton :label="label" :disabled="disabled" @click="submit" :loading="isLoading" />
</template>

<script>
import useApi from "@use/useApi";

export default {
  name: "ApprovalButton",

  props: {
    courseProposalId: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: false,
      default: "approveCourseProposal",
    },
    label: {
      type: String,
      required: false,
      default: "acm.management.courseProposals.show.label.approve",
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, { emit }) {
    const { callApi, isLoading } = useApi({
      module: "acm",
      method: props.method,
      onSuccess: () => {
        emit("refresh");
      },
    });

    const submit = () => {
      callApi({ courseProposalId: props.courseProposalId });
    };

    return { submit, isLoading };
  },
};
</script>
