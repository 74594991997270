import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_levels
 */

/**
 * Retrieve list with levels
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listLevels = () => api.get(ROUTE.LEVELS.LIST);
