<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <ExternalVideoTypeSelect
          v-model="formValues.courseExternalVideoTypeId"
          label="acm.externalVideos.add.form.label.videoType"
          :validation="v$.courseExternalVideoTypeId"
          :messages="bvShowErrors('courseExternalVideoTypeId').messages"
          :error="bvShowErrors('courseExternalVideoTypeId').hasMessages"
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.courseChapterItems.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.externalVideos.add.form.label.url"
          v-model="formValues.url"
          :validation="v$.url"
          :messages="bvShowErrors('url').messages"
          :error="bvShowErrors('url').hasMessages"
          :maxLength="maxLength.EXTERNAL_VIDEOS.URL"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import { useRoute, useRouter } from "vue-router";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { reactive, watch } from "vue";
import ExternalVideoTypeSelect from "@/modules/advanced-course-management-acm/components/ExternalVideoTypeSelect";
import { useStore } from "vuex";
import useBackendValidation from "@use/useBackendValidation";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "AddExternalVideoForm",
  components: { ExternalVideoTypeSelect },
  setup() {
    const { showSuccess } = useNotifications();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { courseRequestChapterId } = route.params;

    // Initialize form values
    const form = reactive({
      courseRequestChapterId: parseInt(courseRequestChapterId, 10),
      courseExternalVideoTypeId: null,
      displayName: null,
      url: null,
    });

    // Setup validation
    const rules = {
      courseRequestChapterId: ["required"],
      courseExternalVideoTypeId: ["required"],
      displayName: ["required"],
      url: ["required", "url"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "storeCourseRequestChapterItemVideoExternal",
      data: formValues,
      onSuccess: () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        store.dispatch("acm/chapters/reloadChapterList", true);
        resetForm();
        v$.value.$reset();
        router.go(-1);
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    const resetForm = () => {
      formValues.courseExternalVideoTypeId = null;
      formValues.url = null;
    };

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
    };

    return {
      v$,
      isLoading,
      formValues,
      submit,
      bvShowErrors,
      maxLength,
    };
  },
};
</script>
