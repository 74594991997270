<template>
  <BasePageContent :loading="isLoading">
    <template #header>
      <BaseHeadline size="2" fake="5" v-if="hasDocs" noMargin>
        {{ $t("usm.users.show.title", { name: `${user.firstName} ${user.lastName}` }) }}
      </BaseHeadline>
    </template>
    <BaseRow v-if="hasDocs">
      <BaseCol cols="12" class="mb-6">
        <BaseRow>
          <BaseCol class="flex flex-grow-0 align-items-center mr-3">
            <BaseIcon icon="pi pi-user" size="1.5"></BaseIcon>
          </BaseCol>
          <BaseCol cols="auto" class="flex align-items-center mr-3">
            <BaseHeadline size="6" no-margin>
              {{ user.salutation }} {{ user.firstName }} {{ user.lastName }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol class="flex flex-grow-0 align-items-center mr-3">
            <UpdateBaseData @reload="refresh" />
          </BaseCol>
        </BaseRow>
        <BaseRow :no-gutter="true">
          <BaseCol>
            <BaseText
              ><span class="font-bold">{{ $t("usm.users.show.label.email") }}:</span>
              {{ user.email }}
            </BaseText>
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <BaseCol cols="12" lg="6">
        <BaseRow :no-gutter="true">
          <BaseCol cols="auto" class="flex align-items-center mr-3 mb-3">
            <BaseHeadline size="6" no-margin>
              {{ $t("usm.users.show.label.userContact") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol class="flex flex-grow-0 align-items-center mr-3 mb-3">
            <UpdateContact @reload="refresh" />
          </BaseCol>
          <BaseCol cols="12" v-if="!user.userContact" class="mb-3">
            <BaseText>{{ $t("usm.users.show.label.noUserContact") }}</BaseText>
          </BaseCol>
          <BaseCol cols="12" v-if="user.userContact && user.userContact.phone" class="mb-3">
            <BaseText>
              <span class="font-bold">{{ $t("usm.users.show.label.userContactPhone") }}:</span>
              {{ user.userContact.phone }}
            </BaseText>
          </BaseCol>
          <BaseCol cols="12" v-if="user.userContact && user.userContact.mobile" class="mb-3">
            <BaseText>
              <span class="font-bold">{{ $t("usm.users.show.label.userContactMobile") }}:</span>
              {{ user.userContact.mobile }}
            </BaseText>
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <BaseCol cols="12" lg="6">
        <BaseRow :no-gutter="true">
          <BaseCol cols="auto" class="flex align-items-center mr-3 mb-3">
            <BaseHeadline size="6" no-margin>
              {{ $t("usm.users.show.label.userAddress") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol class="flex flex-grow-0 align-items-center mr-3 mb-3">
            <UpdateAddress @reload="refresh" />
          </BaseCol>
          <BaseCol cols="12" v-if="!user.userAddress" class="mb-3">
            <BaseText>{{ $t("usm.users.show.label.noUserAddress") }}</BaseText>
          </BaseCol>
          <BaseCol cols="12" v-if="user.userAddress && user.userAddress.street" class="mb-3">
            <BaseText>
              <span class="font-bold">{{ $t("usm.users.show.label.userAddressStreet") }}:</span>
              {{ user.userAddress.street }}
            </BaseText>
          </BaseCol>
          <BaseCol cols="12" v-if="user.userAddress && user.userAddress.city" class="mb-3">
            <BaseText>
              <span class="font-bold">{{ $t("usm.users.show.label.userAddressCity") }}:</span>
              {{ user.userAddress.city }}
            </BaseText>
          </BaseCol>
          <BaseCol cols="12" v-if="user.userAddress && user.userAddress.zipcode" class="mb-3">
            <BaseText>
              <span class="font-bold">{{ $t("usm.users.show.label.userAddressZipcode") }}:</span>
              {{ user.userAddress.zipcode }}
            </BaseText>
          </BaseCol>
          <BaseCol cols="12" v-if="user.userAddress && user.userAddress.country" class="mb-3">
            <BaseText>
              <span class="font-bold">{{ $t("usm.users.show.label.userAddressCountry") }}:</span>
              {{ user.userAddress.country }}
            </BaseText>
          </BaseCol>
        </BaseRow>
      </BaseCol>
    </BaseRow>

    <div v-if="isLoading">
      <BaseProgressSpinner />
    </div>

    <BaseHasPermission permission="user.update">
      <TabView class="shadow-4 mt-6">
        <TabPanel :header="$t('usm.users.assign.tabTitle.userGroups')">
          <BaseHeadline size="2" fake="6">
            {{ $t("usm.users.assign.subTitle.userGroups") }}
          </BaseHeadline>
          <AssignUserGroups :userId="userId" />
        </TabPanel>
        <TabPanel :header="$t('usm.users.assign.tabTitle.roles')">
          <BaseHeadline size="2" fake="6">
            {{ $t("usm.users.assign.subTitle.roles") }}
          </BaseHeadline>
          <AssignRoles :userId="userId" />
        </TabPanel>
        <TabPanel :header="$t('usm.users.assign.tabTitle.permissions')">
          <BaseHeadline size="2" fake="6">
            {{ $t("usm.users.assign.subTitle.permissions") }}
          </BaseHeadline>
          <AssignPermissions :userId="userId" />
        </TabPanel>
      </TabView>
    </BaseHasPermission>
  </BasePageContent>
</template>

<script>
import useApi from "@use/useApi";
import { useRoute } from "vue-router";
import { ref } from "vue";
import useNotifications from "@use/useNotifications";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import AssignPermissions from "../assign/Permissions";
import AssignRoles from "../assign/Roles";
import AssignUserGroups from "../assign/UserGroups";
import UpdateBaseData from "./partials/UpdateBaseData";
import UpdateAddress from "./partials/UpdateAddress";
import UpdateContact from "./partials/UpdateContact";

export default {
  name: "UserUpdate",

  components: {
    UpdateBaseData,
    UpdateAddress,
    UpdateContact,
    TabView,
    TabPanel,
    AssignPermissions,
    AssignRoles,
    AssignUserGroups,
  },

  setup() {
    const { showSuccess } = useNotifications();

    const user = ref(null);
    const route = useRoute();
    const { userId } = route.params;

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "usm",
      method: "showUser",
      onMount: true,
      params: { userId: parseInt(userId, 10) },
      onSuccess: () => {
        user.value = docs.value;
      },
    });

    const refresh = async () => {
      await callApi();
      showSuccess(
        "usm.users.update.notification.success.summary",
        "usm.users.update.notification.success.detail"
      );
    };

    return { user, hasDocs, isLoading, userId, refresh };
  },
};
</script>
