import store from "@/store";
import menuType from "@data/menuTypes";
import menuName from "@data/menuNames";
import router from "@/router";

export default [
  {
    label: "stp.navigation.contextMenu.label.logout",
    icon: "pi pi-sign-out",
    menuName: menuName.get().contextMenu,
    types: [menuType.get().all],
    command: async () => {
      try {
        await store.dispatch("usm/auth/removeToken");
        await store.dispatch("usm/authUser/saveData", {
          permissions: null,
          user: null,
        });

        await router.push({
          name: "Login",
        });
      } catch {
        return;
      }
    },
    order: 40,
  },

  {
    label: "stp.navigation.login",
    to: "/login",
    icon: "pi pi-unlock",
    menuName: menuName.get().user,
    types: [menuType.get().all],
    order: 150,
    requireNotAuth: true,
  },

  {
    label: "stp.navigation.register",
    to: "/register",
    icon: "pi pi-user",
    menuName: menuName.get().user,
    types: [menuType.get().all],
    order: 140,
    requireNotAuth: true,
  },

  {
    label: "stp.navigation.becomeTeacher",
    to: "/teacher",
    icon: "pi pi-briefcase",
    menuName: menuName.get().teacher,
    types: [menuType.get().all],
    order: 105,
    permissions: ["teacher.course_proposal.read"],
    requireNoneOfPermissions: true,
  },

  {
    label: "stp.navigation.registerTeacher",
    to: "/teacher/register",
    icon: "pi pi-pencil",
    menuName: menuName.get().teacher,
    types: [menuType.get().all],
    order: 104,
    permissions: ["teacher.course_proposal.read"],
    requireNoneOfPermissions: true,
  },
];
