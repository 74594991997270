import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Update profile data
 * @method
 * @param {object} params
 * @param {string} params.salutation
 * @param {string} params.title
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.street
 * @param {string} params.zipcode
 * @param {string} params.city
 * @param {string} params.country
 * @param {string} params.phone
 * @param {string} params.mobile
 * @returns {Promise<AxiosResponse<object>>}
 */
export const updateProfile = ({
  salutation,
  title,
  firstName,
  lastName,
  street,
  zipcode,
  city,
  country,
  phone,
  mobile,
}) =>
  api.put(ROUTE.UPDATE, {
    salutation,
    title,
    firstName,
    lastName,
    street,
    zipcode,
    city,
    country,
    phone,
    mobile,
  });
