<template>
  <BasePageContent>
    <template #header>
      <BaseHeadline size="2" fake="5" noMargin>{{ $t("usm.roles.list.title") }}</BaseHeadline>
    </template>
    <BaseHasPermission permission="role.create">
      <BaseButton
        label="usm.roles.list.label.createRole"
        @click="$router.push({ name: 'RoleCreate' })"
        class="mb-4"
      />
    </BaseHasPermission>

    <ListTable :roles="roles" :meta="meta" :callApi="callApi" :loading="isLoading" />
  </BasePageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import ListTable from "./partials/ListTable.vue";

export default {
  name: "RoleList",

  components: {
    ListTable,
  },

  setup() {
    const roles = ref([]);
    const meta = ref({});

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "usm",
      method: "listRoles",
      onMount: true,
      params: { page: 1, itemsPerPage: 10 },
      showQueries: true,
      onSuccess: () => {
        roles.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    return { roles, meta, hasDocs, isLoading, callApi };
  },
};
</script>
