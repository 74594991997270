<template>
  <div class="header-bar" :style="styles">
    <div class="header-bar__inner flex flex-wrap justify-content-between px-3">
      <div class="flex align-items-center pr-3">
        <slot name="left" />
      </div>
      <div class="flex align-items-center text-center">
        <slot name="center" />
      </div>
      <div class="flex align-items-center pl-3">
        <slot name="right" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseHeaderBar",
  props: {
    background: {
      type: String,
      required: false,
      default: "white",
    },
    fixed: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    styles() {
      let style = `background-color: ${this.background};`;

      if (this.fixed !== null) {
        style += "position: fixed;";
      }

      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.1);
  &__inner {
    min-height: 60px;
  }
}
</style>
