<template>
  <BaseForm>
    <BaseForm :validation="v$">
      <BaseRow>
        <BaseCol cols="12">
          <BaseTextField
            v-model="formValues.displayName"
            label="usm.userGroups.create.form.label.displayName"
            :validation="v$.displayName"
          />
        </BaseCol>

        <BaseCol cols="12">
          <BaseTextField
            v-model="formValues.description"
            label="usm.userGroups.create.form.label.description"
            :validation="v$.description"
          />
        </BaseCol>

        <BaseCol cols="12">
          <LevelSelect
            v-model="formValues.levelId"
            :validation="v$.levelId"
            label="usm.userGroups.create.form.label.levelId"
          />
        </BaseCol>
      </BaseRow>

      <template #submit>
        <BaseButton
          label="usm.userGroups.create.form.label.btn"
          @click="submit"
          :loading="loading"
        />
      </template>
    </BaseForm>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import { onMounted, reactive, watch } from "vue";
import useCaching from "@use/useCaching";
import LevelSelect from "../../partials/LevelSelect.vue";

export default {
  name: "CreateForm",

  components: {
    LevelSelect,
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    // Initialize form values
    const form = reactive({
      displayName: null,
      levelId: null,
      description: null,
    });

    // Setup validation
    const rules = {
      displayName: ["required"],
      levelId: ["required", "numeric"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    // Emit form values
    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      emit("submit", formValues);
      v$.value.$reset();
      form.displayName = null;
      form.levelId = null;
      form.description = null;
    };

    const { updateCache, cachedState } = useCaching();

    watch(formValues, () => {
      updateCache(formValues);
    });

    onMounted(() => {
      if (cachedState.value) {
        form.displayName = cachedState.value.displayName;
        form.levelId = cachedState.value.levelId;
        form.description = cachedState.value.description;
      }
    });

    return {
      v$,
      formValues,
      submit,
    };
  },
};
</script>
