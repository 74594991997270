import { createRouter, createWebHashHistory } from "vue-router";
import loggedInRedirect from "../modules/utilities-utls/helper/redirectAuth";

const routes = [];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if ("redirectAuth" in to.meta) {
    if (!to.meta.redirectAuth) return next();
    if ("redirectName" in to.meta) {
      loggedInRedirect(next, to.meta.redirectName);
    } else {
      loggedInRedirect(next);
    }
  } else {
    next();
  }
});

export default router;
