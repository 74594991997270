<template>
  <BasePageContent v-if="hasDocs">
    <template #header>
      <BaseHeadline size="2" fake="5" noMargin>{{
        $t("usm.userGroups.update.title")
      }}</BaseHeadline>
    </template>

    <BaseContainer align="start">
      <BaseRow no-gutter>
        <BaseCol cols="auto" class="flex align-items-center">
          <BaseHeadline no-margin size="2" fake="5">{{
            $t("usm.userGroups.show.title", { name: userGroup.displayName })
          }}</BaseHeadline>
        </BaseCol>
        <BaseCol class="flex flex-grow-0 align-items-center ml-5">
          <BaseHasPermission permission="user_group.update">
            <UpdateForm @reload="refresh" />
          </BaseHasPermission>
        </BaseCol>
      </BaseRow>

      <BaseRow no-gutter class="mt-6">
        <BaseCol cols="12" class="mb-4">
          {{ $t("usm.userGroups.show.label.level", { name: userGroup.level.displayName }) }}
        </BaseCol>
        <BaseCol cols="12">
          {{ userGroup.description }}
        </BaseCol>
      </BaseRow>
    </BaseContainer>

    <BaseHasPermission permission="user_group.update">
      <TabView class="shadow-4 mt-6">
        <TabPanel :header="$t('usm.userGroups.assign.tabTitle.users')">
          <BaseHeadline size="2" fake="6">
            {{ $t("usm.userGroups.assign.subTitle.users") }}
          </BaseHeadline>
          <AssignUsers :userGroupId="userGroupId" />
        </TabPanel>
        <TabPanel :header="$t('usm.userGroups.assign.tabTitle.roles')">
          <BaseHeadline size="2" fake="6">
            {{ $t("usm.userGroups.assign.subTitle.roles") }}
          </BaseHeadline>
          <AssignRoles :userGroupId="userGroupId" />
        </TabPanel>
        <TabPanel :header="$t('usm.userGroups.assign.tabTitle.permissions')">
          <BaseHeadline size="2" fake="5">
            {{ $t("usm.userGroups.assign.subTitle.permissions") }}
          </BaseHeadline>
          <AssignPermissions :userGroupId="userGroupId" />
        </TabPanel>
      </TabView>
    </BaseHasPermission>
  </BasePageContent>
</template>

<script>
import { ref } from "vue";
import useApi from "@use/useApi";
import UpdateForm from "./partials/UpdateForm.vue";
import { useRoute } from "vue-router";
import useNotifications from "@use/useNotifications";
import AssignPermissions from "../assign/Permissions";
import AssignRoles from "../assign/Roles";
import AssignUsers from "../assign/Users";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  name: "UserGroupUpdate",

  components: {
    UpdateForm,
    AssignPermissions,
    AssignRoles,
    AssignUsers,
    TabView,
    TabPanel,
  },

  setup() {
    const { showSuccess } = useNotifications();

    const userGroup = ref(null);
    const route = useRoute();
    const { userGroupId } = route.params;

    const { hasDocs, docs, callApi } = useApi({
      module: "usm",
      method: "showUserGroup",
      onMount: true,
      params: { userGroupId: parseInt(userGroupId, 10) },
      onSuccess: () => {
        userGroup.value = docs.value;
      },
    });

    const refresh = async () => {
      await callApi();
      showSuccess(
        "usm.userGroups.update.notification.success.summary",
        "usm.userGroups.update.notification.success.detail"
      );
    };

    return { userGroup, userGroupId, refresh, hasDocs };
  },
};
</script>
