export const namespaced = true;

export const state = {
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
};

export const getters = {
  isAuthenticated: (state) => state.isAuthenticated && state.accessToken !== null,
  accessToken: (state) => state.accessToken,
  refreshToken: (state) => state.refreshToken,
};

export const mutations = {
  SET_JWT_TOKEN(state, { accessToken, refreshToken }) {
    state.accessToken = accessToken;
    state.refreshToken = refreshToken;
    state.isAuthenticated = true;
  },

  REMOVE_JWT_TOKEN(state) {
    state.accessToken = null;
    state.refreshToken = null;
    state.isAuthenticated = false;
  },
};

export const actions = {
  saveToken({ commit }, { accessToken, refreshToken }) {
    return new Promise((resolve) => {
      commit("SET_JWT_TOKEN", { accessToken, refreshToken });
      resolve();
    });
  },

  removeToken({ commit }) {
    commit("REMOVE_JWT_TOKEN");
  },
};
