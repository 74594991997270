<template>
  <BasePageContent>
    <template #header>
      <BaseHeadline size="2" fake="5" noMargin>{{ $t("usm.users.list.title") }}</BaseHeadline>
    </template>

    <ListTable :users="users" :meta="meta" :callApi="callApi" :loading="isLoading" />
  </BasePageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import ListTable from "./partials/ListTable.vue";

export default {
  name: "UsersList",

  components: {
    ListTable,
  },

  setup() {
    const users = ref([]);
    const meta = ref({});

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "usm",
      method: "listUsers",
      onMount: true,
      params: { page: 1, itemsPerPage: 10 },
      showQueries: true,
      onSuccess: () => {
        users.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    return { users, meta, hasDocs, isLoading, callApi };
  },
};
</script>
