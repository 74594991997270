import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Retrieve a list of all course chapters
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseChapters = (params) => api.get(ROUTE.LIST, { params });

/**
 * Retrieve specified course chapter
 * @method
 * @param {object} params
 * @param {number} params.courseChapterId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourseChapter = ({ courseChapterId }) =>
  api.get(ROUTE.SHOW, { params: { courseChapterId } });

/**
 * Mark specific chapter as finished
 * @method
 * @param {object} params
 * @param {number} params.courseChapterId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const finishCourseChapter = ({ courseChapterId }) =>
  api.post(ROUTE.FINISH, { params: { courseChapterId } });
