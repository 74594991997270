import api from "@/api";
import ROUTE from "../../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve a list of all course requests
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementListChapterItems = (params) =>
  api.get(ROUTE.MANAGEMENT.COURSES.REQUEST.CHAPTER.ITEM.LIST, { params });

/**
 * Retrieve specified course requests
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterItemId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementShowChapterItem = ({ courseRequestChapterItemId }) =>
  api.get(ROUTE.MANAGEMENT.COURSES.REQUEST.CHAPTER.ITEM.SHOW, {
    params: { courseRequestChapterItemId },
  });
