import menuName from "@data/menuNames";
import menuType from "@data/menuTypes";

export default [
  {
    label: "uc.navigation.home",
    to: "/",
    icon: "pi pi-home",
    menuName: menuName.get().mainNavigation,
    types: [menuType.get().all],
    order: 200,
  },
  {
    label: "uc.navigation.findCourses",
    to: "/courses",
    icon: "pi pi-search",
    menuName: menuName.get().mainNavigation,
    types: [menuType.get().all],
    order: 190,
  },
  {
    label: "uc.navigation.cart",
    to: "/cart",
    icon: "pi pi-shopping-cart",
    menuName: menuName.get().mainNavigation,
    types: [menuType.get().all],
    order: 180,
    requireAuth: true,
  },

  {
    label: "uc.navigation.dashboard",
    to: "/my",
    menuName: menuName.get().user,
    types: [menuType.get().all],
    order: 160,
    requireAuth: true,
  },
  {
    label: "uc.navigation.courses",
    to: "/my/courses",
    icon: "pi pi-list",
    menuName: menuName.get().user,
    types: [menuType.get().all],
    order: 150,
    requireAuth: true,
  },
];
