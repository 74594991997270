<template>
  <BaseFormField>
    <Checkbox
      v-if="!triState"
      v-model="checkboxValue"
      :id="id"
      v-bind="$attrs"
      :binary="true"
      :disabled="disabled"
    />

    <TriStateCheckbox
      v-if="triState"
      v-model="checkboxValue"
      :id="id"
      v-bind="$attrs"
      :disabled="disabled"
    />
    <label
      class="ml-3 text-color-secondary"
      :for="id"
      v-html="labelField"
      :style="{ color: 'var(--text-color-secondary)' }"
    ></label>
    <div class="ml-1" :id="`${id}-help`">
      <FieldMessages :messages="messages" v-if="hasMessages && !hasErrors" :error="error" />
      <ErrorMessages :messages="errorMessages" v-if="hasErrors" />
    </div>
  </BaseFormField>
</template>

<script>
import Checkbox from "primevue/checkbox";
import TriStateCheckbox from "primevue/tristatecheckbox";
import { random } from "lodash";
import ErrorMessages from "./partials/ErrorMessages.vue";
import FieldMessages from "./partials/FieldMessages.vue";
import useErrorMessages from "@utils/use/useErrorMessages";

export default {
  name: "BaseCheckbox",

  components: {
    Checkbox,
    ErrorMessages,
    FieldMessages,
    TriStateCheckbox,
  },

  setup(props) {
    const { errorMessages, hasErrors } = useErrorMessages(props.validation);

    return { errorMessages, hasErrors };
  },

  props: {
    triState: {
      type: Boolean,
      required: false,
      default: false,
    },

    modelValue: {
      type: undefined,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    validation: {
      type: Object,
      required: false,
      default: () => {},
    },

    messages: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    checkboxValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },

    id() {
      return `checkbox-${random(100)}`;
    },

    labelField() {
      const label = this.$t(this.label);
      if (this.required) return `${label} *`;

      return label;
    },

    hasMessages() {
      return this.messages.length > 0;
    },
  },
};
</script>
