import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_teachers
 */

/**
 * Retrieve a list of all teachers
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listTeachers = (params) => api.get(ROUTE.TEACHERS.LIST, { params });

/**
 * Retrieve specified teacher
 * @method
 * @param {object} params
 * @param {number} params.teacherId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showTeacher = ({ teacherId }) =>
  api.get(ROUTE.TEACHERS.SHOW, { params: { teacherId } });

/**
 * Delete specified teacher
 * @method
 * @param {object} params
 * @param {number} params.teacherId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteTeacher = ({ teacherId }) =>
  api.delete(ROUTE.TEACHERS.DELETE, { data: { teacherId } });

/**
 * Update specified teacher
 * @method
 * @param {object} params
 * @param {string} params.teacherId
 * @param {string} params.userId
 * @param {string} params.salutation
 * @param {string} params.title
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {boolean} params.isActive
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateTeacher = ({
  teacherId,
  userId,
  salutation,
  title,
  firstName,
  lastName,
  isActive,
}) =>
  api.put(ROUTE.TEACHERS.UPDATE, {
    teacherId,
    userId,
    salutation,
    title,
    firstName,
    lastName,
    isActive,
  });
