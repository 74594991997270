<template>
  <div class="external-video-container" v-if="embedUrl">
    <iframe
      :src="embedUrl"
      width="100%"
      height="100%"
      title="YouTube Video"
      allow="clipboard-write; encrypted-media; picture-in-picture; fullscreen"
    />
  </div>
  <a v-else :href="url" target="_blank">
    {{ url }}
  </a>
</template>

<script>
import { computed } from "vue";

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const baseUrl = "https://www.youtube-nocookie.com/embed/";

    const getUrlFromPart = (part) => {
      const indexOfUrlPart = props.url.indexOf(part);

      if (indexOfUrlPart === -1) {
        return null;
      }

      return `${baseUrl}${props.url.substring(indexOfUrlPart + part.length)}`;
    };

    const embedUrl = computed(() => {
      return (
        getUrlFromPart("/embed/") ||
        getUrlFromPart("/watch/") ||
        getUrlFromPart("v=") ||
        getUrlFromPart("youtu.be/")
      );
    });

    return {
      embedUrl,
    };
  },
};
</script>
