<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="goBack" icon="pi pi-angle-left" />
      <BaseCard class="p-3">
        <CourseRequestPreview :course-request-id="courseRequestId" />
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import HAWPageContent from "../../../../haw-components-hac/Base/HAWPageContent";
import CourseRequestPreview from "./partials/CourseRequestPreview";

export default {
  name: "CourseRequestPreviewContainer",

  components: {
    HAWPageContent,
    CourseRequestPreview,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const { courseRequestId } = route.params;

    const goBack = () => {
      router.back();
    };

    return {
      goBack,
      courseRequestId,
    };
  },
};
</script>

<style lang="scss" scoped>
.description {
  white-space: pre-line;
}
</style>
