<template>
  <HAWPageContent :breadcrumb="false">
    <BaseHeadline size="1" fake="4">{{ $t("acm.courses.list.title") }}</BaseHeadline>

    <CourseRequestList />
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../haw-components-hac/Base/HAWPageContent";
import CourseRequestList from "./partials/CourseRequestList.vue";

export default {
  name: "listCourseRequests",

  components: {
    HAWPageContent,
    CourseRequestList,
  },
};
</script>

<style lang="scss">
.p-dataview {
  .p-dataview-content,
  .p-paginator {
    background: transparent;
    border: none;
  }
}
</style>
