<template>
  <div class="external-video-container" v-if="embedUrl">
    <iframe
      :src="embedUrl"
      width="100%"
      height="100%"
      title="DailyMotion Video"
      allow="fullscreen; picture-in-picture"
    />
  </div>
  <a v-else :href="url" target="_blank">
    {{ url }}
  </a>
</template>

<script>
import { isNaN } from "lodash";

export default {
  props: {
    url: {
      type: String,
      required: true,
      default: null,
    },
  },
  computed: {
    embedUrl() {
      const lastIndexOfSlash = this.url.lastIndexOf("/");
      if (lastIndexOfSlash === -1) return null;

      const id = this.url.substr(lastIndexOfSlash + 1);
      if (isNaN(id)) return null;

      return `https://www.dailymotion.com/embed/video/${id}`;
    },
  },
};
</script>
