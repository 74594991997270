import menuType from "@data/menuTypes";

// Module sub pages

import ManagementTeacherList from "../views/Management/Teacher/list";
import ManagementTeacherShow from "../views/Management/Teacher/show";

// Lazy loading for main routes

const ManagementTeacher = () => import("../../haw-components-hac/views");

export default [
  {
    path: "/management/teachers",
    name: "ManagementTeacher",
    component: ManagementTeacher,
    children: [
      {
        path: "",
        name: "ManagementTeacherList",
        component: ManagementTeacherList,
        meta: { menuTypes: [menuType.get().all], displayName: "tm.navigation.teachers" },
      },
      {
        path: ":teacherId",
        name: "ManagementTeacherShow",
        component: ManagementTeacherShow,
        meta: { menuTypes: [menuType.get().all], displayName: "tm.navigation.teachers" },
      },
    ],
  },
];
