import { useI18n } from "vue-i18n";
import { ref } from "vue";

/**
 * Use dialog
 *
 * @method
 * @param {object} params
 * @param {string} params.header
 * @param {string} [params.acceptLabel=utils.use.dialog.label.accept]
 * @param {string} [params.rejectLabel=utils.use.dialog.label.reject]
 * @param {string} params.icon
 * @param {function} [params.onAccept]
 * @param {function} [params.onReject]
 * @returns {object} { dialog }
 */
const useDialog = ({ header, acceptLabel, rejectLabel, icon, onAccept, onReject }) => {
  const { t } = useI18n();

  const dialogVisible = ref(false);

  const dialogSettings = {
    header: t(header),
    accept: t(acceptLabel),
    reject: t(rejectLabel),
    triggerIcon: icon,
  };

  /**
   * Show  dialog
   */
  const openDialog = () => {
    dialogVisible.value = true;
  };

  const closeDialog = () => {
    dialogVisible.value = false;
  };

  const accept = () => {
    onAccept && onAccept();
  };

  const reject = () => {
    closeDialog();
    onReject && onReject();
  };

  return { dialogVisible, openDialog, closeDialog, accept, reject, dialogSettings };
};

export default useDialog;
