<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />

      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12" class="flex justify-content-between">
            <BaseHeadline size="2" fake="5">
              {{ $t("acm.answers.add.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <AddAnswerForm @refresh="goBack" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import AddAnswerForm from "./partials/AddAnswerForm";
import { useRoute, useRouter } from "vue-router";
import { ref } from "vue";

export default {
  name: "ViewVideo",

  components: {
    HAWPageContent,
    AddAnswerForm,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const courseRequestQuizId = ref(route.params.courseRequestQuizId);

    const goBack = () => {
      router.go(-1);
    };

    return {
      courseRequestQuizId,
      goBack,
    };
  },
};
</script>

<style scoped lang="scss">
.max-w-1024 {
  max-width: 1024px;
  margin: 0 auto;
}
</style>
