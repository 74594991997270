import api from "@/api";
import ROUTE from "@/modules/advanced-course-management-acm/api/routes/apiRoutes";

/**
 * Retrieve a list of all course topics
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseTopics = (params) => api.get(ROUTE.COURSES.TOPICS.LIST, { params });

/**
 * Retrieve a list of all course topics for a course request
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @param {number} params.courseRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const teacherListCourseTopics = (params) => api.get(ROUTE.TEACHER.TOPICS.LIST, { params });
