<template>
  <HAWPageContent :breadcrumb="false">
    <BaseHeadline size="1" fake="4">{{ $t("acm.management.courseRequests.title") }}</BaseHeadline>
    <ListTable
      :courseRequests="courseRequests"
      :meta="meta"
      :callApi="callApi"
      :loading="isLoading"
    />
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import ListTable from "./partials/ListTable.vue";

export default {
  name: "ManagementCourseRequestList",

  components: {
    HAWPageContent,
    ListTable,
  },

  setup() {
    const courseRequests = ref([]);
    const meta = ref({});

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "acm",
      method: "managementListCourseRequests",
      onMount: true,
      showQueries: true,
      params: {
        page: 1,
        itemsPerPage: 10,
      },
      onSuccess: () => {
        courseRequests.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    return { courseRequests, meta, hasDocs, isLoading, callApi };
  },
};
</script>
