import { COURSE_PROPOSAL_STATUS } from "./COURSE_PROPOSAL_STATUS";

const CONSTANTS_SECTIONS = {
  COURSE_PROPOSAL_STATUS,
};

export const CONSTANTS = {
  getStatus: (statusSection, statusId) => {
    if (CONSTANTS_SECTIONS[statusSection]) {
      try {
        return CONSTANTS_SECTIONS[statusSection][statusId];
      } catch (error) {
        console.error(
          `statusId ${statusId} doesn't exist for statusSection ${statusSection}`,
          error
        );
      }
    } else {
      return new Error(`statusSection ${statusSection} is unknown.`);
    }
  },
};
