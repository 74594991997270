<template>
  <EditDialog
    update-module="usm"
    update-method="updateUserGroup"
    :validation="v$"
    :form-values="formValues"
    dialog-header="usm.userGroups.update.dialog.title"
    :onHide="callApi"
  >
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          v-model="formValues.displayName"
          label="usm.userGroups.update.form.label.displayName"
          :validation="v$.displayName"
        />
      </BaseCol>

      <BaseCol cols="12">
        <BaseTextArea
          v-model="formValues.description"
          label="usm.userGroups.update.form.label.description"
          :validation="v$.description"
        />
      </BaseCol>

      <BaseCol cols="12">
        <LevelSelect
          v-model="formValues.levelId"
          :validation="v$.levelId"
          label="usm.userGroups.update.form.label.levelId"
        />
      </BaseCol>
    </BaseRow>
  </EditDialog>
</template>

<script>
import { reactive } from "vue";
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import { useRoute } from "vue-router";
import EditDialog from "../../edit";
import LevelSelect from "../../partials/LevelSelect.vue";

export default {
  name: "UpdateForm",

  components: {
    EditDialog,
    LevelSelect,
  },

  setup() {
    // Setup validation
    const rules = {
      displayName: ["required"],
    };

    // Load data
    const route = useRoute();
    const { userGroupId } = route.params;

    const form = reactive({
      displayName: null,
      description: null,
      levelId: null,
      userGroupId,
    });

    const { docs, hasDocs, callApi } = useApi({
      module: "usm",
      method: "showUserGroup",
      onMount: true,
      params: { userGroupId },
      onSuccess: () => {
        form.displayName = docs.value.displayName;
        form.description = docs.value.description;
        form.levelId = docs.value.levelId;
      },
    });

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const updateUserGroup = () => {
      callApi({ formValues });
    };

    return {
      v$,
      formValues,
      hasDocs,
      callApi,
      updateUserGroup,
    };
  },
};
</script>
