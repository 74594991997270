<template>
  <DataTable
    :value="courseTopics"
    lazy
    paginator
    :rows="meta.per_page"
    :totalRecords="meta.total"
    :rowsPerPageOptions="[10, 20, 50]"
    :loading="loading"
    @page="changePage($event)"
    dataKey="id"
    rowHover
    autoLayout
    class="p-datatable-sm"
  >
    <template #empty>
      <div class="text-center py-4">{{ $t("utils.table.empty") }}</div>
    </template>

    <Column field="displayName" :header="$t('acm.management.courseTopics.list.header.displayName')">
      <template #body="slotProps">
        <span>
          {{ slotProps.data.displayName }}
        </span>
      </template>
    </Column>

    <Column field="description" :header="$t('acm.management.courseTopics.list.header.description')">
      <template #body="slotProps">
        <span>
          {{ shortenedDescription(slotProps.data.description) }}
        </span>
      </template>
    </Column>

    <Column field="isActive" :header="$t('acm.management.courseTopics.list.header.isActive')">
      <template #body="slotProps">
        <span>
          <BaseIcon
            :class="slotProps.data.isActive ? 'text-success' : 'text-danger'"
            :icon="slotProps.data.isActive ? 'pi pi-check' : 'pi pi-times'"
          />
        </span>
      </template>
    </Column>

    <Column field="createdAt" :header="$t('acm.management.courseTopics.list.header.createdAt')">
      <template #body="slotProps">
        {{ dateTime(slotProps.data.createdAt) }}
      </template>
    </Column>

    <Column field="view">
      <template #body="slotProps">
        <BaseIcon icon="pi pi-eye" class="cursor-pointer" @click="view(slotProps.data.id)" />
      </template>
    </Column>

    <template #paginatorLeft>
      <BaseButton
        icon="pi pi-refresh"
        rounded
        background="gray-100"
        color="gray-500"
        :loading="loading"
        @click="refresh($event)"
        v-tooltip="$t('acm.management.courseTopics.list.tooltip.refresh')"
      />
    </template>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import dateFilter from "@filter/dateFilter";
import { shortenStringToMaxLength } from "@/modules/advanced-course-management-acm/helpers/ShortenStringAtSpace";

export default {
  name: "ListTable",

  mixins: [dateFilter],

  components: {
    DataTable,
    Column,
  },

  props: {
    courseTopics: {
      type: Array,
      required: true,
      default: () => [],
    },

    meta: {
      type: Object,
      required: true,
      default: () => {
        return {
          // eslint-disable-next-line camelcase
          current_page: null,
          // eslint-disable-next-line camelcase
          per_page: null,
          total: null,
        };
      },
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    callApi: {
      type: Function,
      required: false,
    },
  },

  methods: {
    refresh() {
      this.callApi({ page: this.meta.current_page, itemsPerPage: this.meta.per_page });
    },

    changePage(event) {
      this.callApi({ page: event.page + 1, itemsPerPage: event.rows });
    },

    view(courseTopicId) {
      this.$router.push({ name: "ManagementCourseTopicsShow", params: { courseTopicId } });
    },

    shortenedDescription(description) {
      return shortenStringToMaxLength(description, 50);
    },
  },
};
</script>
