<template>
  <BaseHover v-model="hover">
    <a
      v-bind="$attrs"
      :style="styles"
      @click.prevent="goTo"
      class="cursor-pointer"
      :class="!hover ? 'no-underline' : 'underline'"
    >
      <slot />
    </a>
  </BaseHover>
</template>

<script>
export default {
  name: "BaseLink",

  inheritAttrs: false,

  data() {
    return {
      hover: false,
    };
  },

  props: {
    to: {
      type: undefined,
      required: false,
    },

    color: {
      type: String,
      required: false,
    },
  },

  methods: {
    goTo() {
      if (this.to) {
        this.$router.push(this.to);
      } else if (this.$attrs.target === "_blank") {
        window.open(this.$attrs.href);
      } else {
        location.assign(this.$attrs.href);
      }
    },
  },

  computed: {
    styles() {
      const styles = {};

      styles.color = !this.color ? "var(--gc-link-color)" : `var(--${this.color})`;

      return styles;
    },
  },
};
</script>
