export const shortenStringAtSpace = (string) => {
  const lastSpaceIndex = string.lastIndexOf(" ");
  if (lastSpaceIndex !== -1) {
    return `${string.slice(0, lastSpaceIndex)}...`;
  }

  return `${string}...`;
};

export const shortenStringToMaxLength = (string, maxLength) => {
  if (!string || string.length <= maxLength) {
    return string;
  }

  let cutString = string.slice(0, maxLength);

  return shortenStringAtSpace(cutString);
};

export default {
  shortenStringAtSpace,
  shortenStringToMaxLength,
};
