<template>
  <HAWPageContent :breadcrumb="false">
    <BaseHeadline size="1" fake="4">
      {{ $t("acm.management.courseTopics.list.title") }}
    </BaseHeadline>

    <BaseButton
      @click="pushToCreate"
      label="acm.management.courseTopics.list.create"
      class="mb-5"
    />

    <ListTable :courseTopics="courseTopics" :meta="meta" :callApi="callApi" :loading="isLoading" />
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import ListTable from "./partials/ListTable.vue";
import { useRouter } from "vue-router";

export default {
  name: "CourseProposalsList",

  components: {
    HAWPageContent,
    ListTable,
  },

  setup() {
    const router = useRouter();

    const courseTopics = ref([]);
    const meta = ref({});

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "acm",
      method: "managementListCourseTopics",
      onMount: true,
      params: { page: 1, itemsPerPage: 10 },
      showQueries: true,
      onSuccess: () => {
        courseTopics.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    const pushToCreate = () => {
      router.push({ name: "ManagementCourseTopicsCreate" });
    };

    return { courseTopics, meta, hasDocs, isLoading, callApi, pushToCreate };
  },
};
</script>
