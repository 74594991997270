export default [
  {
    label: "acm.contentTypes.rte.label",
    permission: null,
    isActive: true,
    to: { name: "AddContent" },
  },
  /*{
    label: "acm.contentTypes.video.label",
    permission: null,
    isActive: true,
    to: { name: "AddVideo" },
  },*/
  {
    label: "acm.contentTypes.externalVideo.label",
    permission: null,
    isActive: true,
    to: { name: "AddExternalVideo" },
  },
  {
    label: "acm.contentTypes.quiz.label",
    permission: null,
    isActive: true,
    to: { name: "AddQuiz" },
  },
];
