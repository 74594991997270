import { createI18n } from "vue-i18n";
import registerLangFiles from "./modules/utilities-utls/helper/registerLangFiles";

const isDevelop = process.env.NODE_ENV === "development";

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: registerLangFiles(),
  globalInjection: true,
  fallbackWarn: isDevelop,
  missingWarn: isDevelop,
});
