<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />
      <BaseCard class="p-3">
        <BaseRow v-if="hasDocs">
          <BaseCol cols="12" class="flex justify-content-between">
            <BaseHeadline size="1" fake="4">
              {{ videoInformation.displayName }}
            </BaseHeadline>
            <div>
              <BaseDeleteData
                update-module="acm"
                update-method="deleteCourseRequestChapterItemVideo"
                :formData="{ courseRequestVideoId: videoInformation.courseRequestVideoId }"
                icon="pi pi-trash"
                deleteDialogMessage="acm.videos.delete.dialog.message"
                deleteDialogHeader="acm.videos.delete.dialog.header"
                outlined
                :onDeleted="goBack"
              />
            </div>
          </BaseCol>
          <BaseCol cols="12">
            <UpdateVideoForm :video-information="videoInformation" @refresh="reload" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import UpdateVideoForm from "./partials/UpdateVideoForm";
import useNotifications from "@use/useNotifications";
import { useRoute, useRouter } from "vue-router";
import { reactive, watch } from "vue";

export default {
  name: "AddVideo",

  components: {
    HAWPageContent,
    UpdateVideoForm,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { showError } = useNotifications();

    let { courseRequestChapterItemId } = route.params;

    // Initialize form values
    const videoInformation = reactive({
      courseRequestVideoId: null,
      displayName: null,
      thumbnailPath: null,
      path: null,
    });

    const { docs, callApi, isLoading, hasDocs, backendValidation } = useApi({
      module: "acm",
      method: "showCourseRequestChapterItem",
      params: { courseRequestChapterItemId },
      onMount: true,
      onSuccess: () => {
        videoInformation.courseRequestVideoId = docs.value.data.model.id;
        videoInformation.displayName = docs.value.data.displayName;
        videoInformation.thumbnailPath = docs.value.data.model.thumbnailPath;
        videoInformation.path = docs.value.data.model.path;
        videoInformation.url = docs.value.data.model.url;
        videoInformation.thumbnailUrl = docs.value.data.model.thumbnailUrl;
      },
      onError: () => showError(backendValidation.value.message, ""),
    });

    // Emit form values
    const reload = async () => {
      await callApi({});
    };

    const goBack = () => {
      router.go(-1);
    };

    watch(route, () => {
      let { courseRequestChapterItemId } = route.params;
      if (courseRequestChapterItemId) {
        callApi({ courseRequestChapterItemId: courseRequestChapterItemId });
      }
    });

    return {
      isLoading,
      videoInformation,
      reload,
      hasDocs,
      goBack,
    };
  },
};
</script>
