<template>
  <HAWPageContent :breadcrumb="false">
    <BaseHeadline size="1" fake="4">{{ $t("tm.teachers.list.title") }}</BaseHeadline>
    <ListTable :teachers="teachers" :meta="meta" :callApi="callApi" :loading="isLoading" />
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import ListTable from "./partials/ListTable.vue";

export default {
  name: "CourseProposalsList",

  components: {
    HAWPageContent,
    ListTable,
  },

  setup() {
    const teachers = ref([]);
    const meta = ref({});

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "tm",
      method: "listTeachers",
      onMount: true,
      params: { page: 1, itemsPerPage: 10 },
      showQueries: true,
      onSuccess: () => {
        teachers.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    return { teachers, meta, hasDocs, isLoading, callApi };
  },
};
</script>
