import api from "@/api";
import ROUTE from "../../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve a list of all course requests
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementListCourseRequests = (params) =>
  api.get(ROUTE.MANAGEMENT.COURSES.REQUEST.LIST, { params });

/**
 * Retrieve specified course requests
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementShowCourseRequest = ({ courseRequestId }) =>
  api.get(ROUTE.MANAGEMENT.COURSES.REQUEST.SHOW, { params: { courseRequestId } });

/**
 * Approve specified course requests
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementApproveCourseRequest = ({ courseRequestId }) =>
  api.post(ROUTE.MANAGEMENT.COURSES.REQUEST.APPROVE, { courseRequestId });

/**
 * Dismiss specified course requests
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @param {number} params.courseRequestChapterIds
 * @param {number} params.message
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementDismissCourseRequest = ({
  courseRequestId,
  courseRequestChapterIds,
  message,
}) =>
  api.post(ROUTE.MANAGEMENT.COURSES.REQUEST.DISMISS, {
    courseRequestId,
    courseRequestChapterIds,
    message,
  });
