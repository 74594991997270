<template>
  <slot v-if="isReady" />

  <div v-else class="boot-user-loader min-h-screen flex justify-content-center align-items-center">
    <div>
      <div class="boot-user-loader__logo mx-auto">
        <img :src="logo" class="w-full h-auto" alt="Logo" />
      </div>

      <div class="text-center mt-5">
        <BaseProgressSpinner size="48" />
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/img/logo.svg";
import { useStore } from "vuex";
import { computed, watch } from "vue";
import useApi from "@use/useApi";

export default {
  setup() {
    const store = useStore();

    const isLoading = computed(() => {
      return store.getters["usm/authUser/isLoading"];
    });

    const isAuthenticated = computed(() => {
      return store.getters["usm/auth/isAuthenticated"];
    });

    const user = computed(() => {
      return store.getters["usm/authUser/user"];
    });

    const isReady = computed(() => {
      return !(isAuthenticated.value && !user.value) || isLoading.value;
    });

    const { docs, callApi } = useApi({
      module: "usm",
      method: "showAuthUser",
      onMount: !isReady.value,
      onSuccess: async () => {
        await store.dispatch("usm/authUser/saveData", docs.value);
      },
      onError: async () => {
        await store.dispatch("usm/auth/removeToken");
      },
    });

    watch(isReady, () => {
      if (!isReady.value && !isLoading.value) {
        callApi();
      }
    });

    return {
      isReady,
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
.boot-user-loader {
  &__logo {
    width: 90%;
    max-width: 350px;
  }
}
</style>
