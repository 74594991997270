import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import PrimeVue from "primevue/config";
import registerBaseComponents from "@/modules/utilities-utls/helper/registerBaseComponents";
import registerModules from "@/modules/utilities-utls/helper/registerModules";
import storageHelper from "@utils/helper/localStorage";
import storageNames from "@utils/data/storageNames";
import vuexStoreCaching from "@utils/helper/vuexStoreCaching";
import primeVueConfig from "@/config/primeVue";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import Vuelidate from "@vuelidate/core";
import "@/assets/fonts/fonts.scss";
import "../primevue-designer-3.5.0/src/assets/themes/mytheme/theme.scss";
import "@/styles/main.scss";

const app = createApp(App);

// Save vuex store to local storage
store.subscribe((mutation, state) => {
  storageHelper.write(vuexStoreCaching.write(state), storageNames.VUEX_STORE);
});

const mount = async () => {
  try {
    await registerModules(app);
    await registerBaseComponents(app);
  } finally {
    app
      .use(i18n)
      .use(store)
      .use(router)
      .use(PrimeVue, primeVueConfig)
      .use(ToastService)
      .use(ConfirmationService)
      .use(Vuelidate)
      .mount("#app");

    app.directive("tooltip", Tooltip);
  }
};

mount();
