import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_acm_courses
 */

/**
 * Retrieve a list of all chapters
 *
 * @param {object} params
 * @param {number} params.courseRequestId
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseRequestChapters = (params) =>
  api.get(ROUTE.COURSES.REQUEST.CHAPTER.LIST, { params });

/**
 * Retrieve specified course chapter
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourseRequestChapter = ({ courseRequestChapterId }) =>
  api.get(ROUTE.COURSES.REQUEST.CHAPTER.SHOW, { params: { courseRequestChapterId } });

/**
 * Delete specified course chapter
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequestChapter = ({ courseRequestChapterId }) =>
  api.delete(ROUTE.COURSES.REQUEST.CHAPTER.DELETE, { params: { courseRequestChapterId } });

/**
 * Update specified course chapter
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @param {string} params.displayName
 * @param {string} params.description
 * @param {number} params.order
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequestChapter = ({
  courseRequestChapterId,
  displayName,
  order,
  description,
}) =>
  api.put(ROUTE.COURSES.REQUEST.CHAPTER.UPDATE, {
    courseRequestChapterId,
    displayName,
    order,
    description,
  });

/**
 * Store specified course chapter
 * @method
 * @param {object} params
 * @param {number} params.courseRequestId
 * @param {string} params.displayName
 * @param {string} params.description
 * @param {number} params.order
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeCourseRequestChapter = ({ courseRequestId, displayName, order, description }) =>
  api.post(ROUTE.COURSES.REQUEST.CHAPTER.STORE, {
    courseRequestId,
    displayName,
    order,
    description,
  });

/**
 * Confirm specified course chapter
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const confirmCourseChapter = ({ courseRequestChapterId }) =>
  api.put(ROUTE.COURSES.REQUEST.CHAPTER.FINISH, {
    courseRequestChapterId,
  });
