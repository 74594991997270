<template>
  <DataTable
    :value="roles"
    lazy
    paginator
    :rows="meta.per_page"
    :totalRecords="meta.total"
    :rowsPerPageOptions="[10, 20, 50]"
    :loading="loading"
    @page="changePage($event)"
    dataKey="id"
    rowHover
    autoLayout
    class="p-datatable-sm"
  >
    <Column field="id" :header="$t('usm.roles.list.table.header.id')" />
    <Column field="name" :header="$t('usm.roles.list.table.header.name')">
      <template #body="slotProps">
        <span
          class="cursor-pointer"
          @click="$router.push({ name: 'RoleUpdate', params: { roleId: slotProps.data.id } })"
        >
          {{ slotProps.data.name }}
        </span>
      </template>
    </Column>
    <Column field="createdAt" :header="$t('usm.roles.list.table.header.createdAt')">
      <template #body="slotProps">
        {{ dateTime(slotProps.data.createdAt) }}
      </template>
    </Column>
    <Column field="updatedAt" :header="$t('usm.roles.list.table.header.updatedAt')">
      <template #body="slotProps">
        {{ dateTime(slotProps.data.updatedAt) }}
      </template>
    </Column>

    <Column>
      <template #body="slotProps">
        <div class="flex justify-content-end">
          <RoleDelete :roleId="slotProps.data.id" class="mr-3" @reload="refresh">
            <BaseButton
              icon="pi pi-trash"
              rounded
              background="gray-100"
              color="gray-500"
              v-tooltip="$t('usm.roles.list.tooltip.delete')"
            />
          </RoleDelete>
          <BaseButton
            icon="pi pi-pencil"
            rounded
            background="gray-100"
            color="gray-500"
            @click="$router.push({ name: 'RoleUpdate', params: { roleId: slotProps.data.id } })"
            v-tooltip="$t('usm.roles.list.tooltip.edit')"
          />
        </div>
      </template>
    </Column>

    <template #paginatorLeft>
      <BaseButton
        icon="pi pi-refresh"
        rounded
        background="gray-100"
        color="gray-500"
        :loading="loading"
        @click="refresh($event)"
        v-tooltip="$t('usm.roles.list.tooltip.refresh')"
      />
    </template>
  </DataTable>
</template>

<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import RoleDelete from "../../delete";
import dateFilter from "@filter/dateFilter";

export default {
  name: "ListTable",

  mixins: [dateFilter],

  props: {
    roles: {
      type: Array,
      required: true,
      default: () => [],
    },

    meta: {
      type: Object,
      required: true,
      default: () => {
        return {
          // eslint-disable-next-line camelcase
          current_page: null,
          // eslint-disable-next-line camelcase
          per_page: null,
          total: null,
        };
      },
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    callApi: {
      type: Function,
      required: false,
    },
  },

  components: {
    DataTable,
    Column,
    RoleDelete,
  },

  methods: {
    refresh() {
      this.callApi({ page: this.meta.current_page, itemsPerPage: this.meta.per_page });
    },

    changePage(event) {
      this.callApi({ page: event.page + 1, itemsPerPage: event.rows });
    },
  },
};
</script>
