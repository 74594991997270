<template>
  <BaseRow>
    <BaseCol v-for="(type, index) in types" :key="index" class="">
      <BaseTag
        padding="2"
        :class="{ 'cursor-pointer': !isDisabled }"
        :is-disabled="isDisabled"
        :full-width="true"
        @click="onClick(type)"
      >
        {{ $t(type.label) }}
      </BaseTag>
    </BaseCol>
  </BaseRow>
</template>

<script>
import contentTypes from "../contentTypes";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "ContentTypeMenu",

  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup() {
    const router = useRouter();
    const types = ref(contentTypes);

    const onClick = (typeData) => {
      if (typeData.to) {
        router.push(typeData.to);
      } else {
        return false;
      }
    };

    return {
      types,
      onClick,
    };
  },
};
</script>
