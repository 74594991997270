<template>
  <div v-for="(message, index) of messages" :key="index" class="flex p-error mt-2">
    <small class="align-self-center text-xs">{{ message }}</small>
  </div>
</template>

<script>
export default {
  name: "ErrorMessages",

  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
};
</script>
