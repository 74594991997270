import menuType from "@data/menuTypes";

// Module sub pages
import CourseRequestsList from "../views/CourseManagement/list";
import CourseRequestPreview from "../views/CourseRequest/preview";
import AddChapter from "../views/Course/views/addChapter";
import AddContent from "../views/Course/views/addContent";
import ViewContent from "../views/Course/views/viewContent";
import AddExternalVideo from "../views/Course/views/addExternalVideo";
import ViewExternalVideo from "../views/Course/views/viewExternalVideo";
import ViewChapter from "../views/Course/views/viewChapter";
import ViewVideo from "../views/Course/views/viewVideo";
import AddQuiz from "../views/Course/views/addQuiz";
import ViewQuiz from "../views/Course/views/viewQuiz";
import AddQuestion from "../views/Course/views/addQuestion";
import ViewQuestion from "../views/Course/views/viewQuestion";
import AddAnswer from "../views/Course/views/addAnswer";
import ViewAnswer from "../views/Course/views/viewAnswer";
import CourseHelp from "../views/Information/views/help";
import CourseHelpUpdate from "../views/Information/views/update";

//import AddVideo from "../views/Course/views/addVideo";

// Lazy loading for main routes

import CourseProposalCreate from "../views/CourseProposal/create";
import CourseProposalsList from "../views/CourseProposal/list";
import CourseProposalShow from "../views/CourseProposal/show";

import ManagementCourseProposalsList from "../views/Management/CourseProposal/list";
import ManagementCourseProposalShow from "../views/Management/CourseProposal/show";

import ManagementCourseTopicsList from "../views/Management/CourseTopic/list";
import ManagementCourseTopicsCreate from "../views/Management/CourseTopic/create";
import ManagementCourseTopicsShow from "../views/Management/CourseTopic/show";

import ManagementCourseRequestList from "../views/Management/CourseRequest/list";
import ManagementCourseRequestShow from "../views/Management/CourseRequest/show";

import ManagementCourseList from "../views/Management/Course/list";
import ManagementCourseShow from "../views/Management/Course/show";

const AdvancedCourseManagement = () => import("../../haw-components-hac/views");

const CourseDetail = () => import("../views/Course");
import CourseUpdate from "../views/Course/views/update";

export default [
  {
    path: "/course-requests",
    component: AdvancedCourseManagement,
    children: [
      {
        path: "",
        name: "CourseRequestsList",
        component: CourseRequestsList,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.courses" },
      },
      {
        path: ":courseRequestId/preview",
        name: "CourseRequestPreview",
        component: CourseRequestPreview,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.courses" },
      },
    ],
  },

  {
    path: "/course-requests",
    component: CourseDetail,
    children: [
      {
        path: ":courseRequestId",
        name: "CourseUpdate",
        component: CourseUpdate,
        meta: { displayName: "acm.navigation.courseUpdate" },
      },
      {
        path: ":courseRequestId/chapter/create",
        name: "AddChapter",
        component: AddChapter,
        meta: { displayName: "acm.navigation.addChapter" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId",
        name: "ViewChapter",
        component: ViewChapter,
        meta: { displayName: "acm.navigation.viewChapter" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/rte",
        name: "AddContent",
        component: AddContent,
        meta: { displayName: "acm.navigation.addContent" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/rte",
        name: "ViewContent",
        component: ViewContent,
        meta: { displayName: "acm.navigation.viewContent" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/external-video",
        name: "AddExternalVideo",
        component: AddExternalVideo,
        meta: { displayName: "acm.navigation.addExternalVideo" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/external-video",
        name: "ViewExternalVideo",
        component: ViewExternalVideo,
        meta: { displayName: "acm.navigation.viewExternalVideo" },
      },
      /*{
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/video",
        name: "AddVideo",
        component: AddVideo,
        meta: { displayName: "acm.navigation.addVideo" },
      },*/
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/video",
        name: "ViewVideo",
        component: ViewVideo,
        meta: { displayName: "acm.navigation.viewVideo" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/quiz",
        name: "AddQuiz",
        component: AddQuiz,
        meta: { displayName: "acm.navigation.addQuiz" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/quiz/",
        name: "ViewQuiz",
        component: ViewQuiz,
        meta: { displayName: "acm.navigation.viewQuiz" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/quiz/:courseRequestQuizId/question/",
        name: "AddQuestion",
        component: AddQuestion,
        meta: { displayName: "acm.navigation.addQuestion" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/quiz/:courseRequestQuizId/question/:courseRequestQuizQuestionId",
        name: "ViewQuestion",
        component: ViewQuestion,
        meta: { displayName: "acm.navigation.viewQuestion" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/quiz/:courseRequestQuizId/question/:courseRequestQuizQuestionId/answer",
        name: "AddAnswer",
        component: AddAnswer,
        meta: { displayName: "acm.navigation.addAnswer" },
      },
      {
        path: ":courseRequestId/chapter/:courseRequestChapterId/content/:courseRequestChapterItemId/quiz/:courseRequestQuizId/question/:courseRequestQuizQuestionId/answer/:courseRequestQuizQuestionAnswerId",
        name: "ViewAnswer",
        component: ViewAnswer,
        meta: { displayName: "acm.navigation.viewAnswer" },
      },
    ],
  },

  {
    path: "/course-proposals",
    name: "CourseProposal",
    component: AdvancedCourseManagement,
    children: [
      {
        path: "",
        name: "CourseProposalsList",
        component: CourseProposalsList,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.courseProposal" },
      },
      {
        path: ":courseProposalId",
        name: "CourseProposalShow",
        component: CourseProposalShow,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.proposalShow" },
      },
      {
        path: "create",
        name: "CourseProposalCreate",
        component: CourseProposalCreate,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.courseProposal" },
      },
    ],
  },

  {
    path: "/management/course-proposals",
    name: "CourseProposalManagement",
    component: AdvancedCourseManagement,
    children: [
      {
        path: "",
        name: "ManagementCourseProposalsList",
        component: ManagementCourseProposalsList,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.courseProposal" },
      },
      {
        path: ":courseProposalId",
        name: "ManagementCourseProposalShow",
        component: ManagementCourseProposalShow,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.proposalShow" },
      },
    ],
  },

  {
    path: "/management/course-topics",
    name: "CourseTopicManagement",
    component: AdvancedCourseManagement,
    children: [
      {
        path: "",
        name: "ManagementCourseTopicsList",
        component: ManagementCourseTopicsList,
        meta: { menuTypes: [menuType.get().all] },
      },
      {
        path: ":courseTopicId",
        name: "ManagementCourseTopicsShow",
        component: ManagementCourseTopicsShow,
        meta: { menuTypes: [menuType.get().all] },
      },
      {
        path: "create",
        name: "ManagementCourseTopicsCreate",
        component: ManagementCourseTopicsCreate,
        meta: { menuTypes: [menuType.get().all] },
      },
    ],
  },

  {
    path: "/management/course-requests",
    name: "CourseRequestManagement",
    component: AdvancedCourseManagement,
    children: [
      {
        path: "",
        name: "ManagementCourseRequestList",
        component: ManagementCourseRequestList,
        meta: {
          menuTypes: [menuType.get().all],
          displayName: "acm.navigation.management.courseRequestList",
        },
      },
      {
        path: ":courseRequestId",
        name: "ManagementCourseRequestShow",
        component: ManagementCourseRequestShow,
        meta: {
          menuTypes: [menuType.get().all],
          displayName: "acm.navigation.management.courseRequestShow",
        },
      },
    ],
  },

  {
    path: "/management/courses",
    name: "CourseManagement",
    component: AdvancedCourseManagement,
    children: [
      {
        path: "",
        name: "ManagementCourseList",
        component: ManagementCourseList,
        meta: { menuTypes: [menuType.get().all] },
      },
      {
        path: ":courseId",
        name: "ManagementCourseShow",
        component: ManagementCourseShow,
        meta: { menuTypes: [menuType.get().all] },
      },
    ],
  },

  {
    path: "/information",
    name: "Information",
    component: AdvancedCourseManagement,
    children: [
      {
        path: "",
        name: "CourseHelp",
        component: CourseHelp,
        meta: { menuTypes: [menuType.get().all], displayName: "acm.navigation.courseHelp" },
      },
      {
        path: "update",
        name: "CourseHelpUpdate",
        component: CourseHelpUpdate,
        meta: {
          menuTypes: [menuType.get().all],
          displayName: "acm.navigation.courseHelpUpdate",
        },
      },
    ],
  },
];
