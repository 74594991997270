<template>
  <PickList
    v-if="!isLoading.value"
    v-model="pickListValues"
    dataKey="id"
    class="hide-sort-controls w-full h-full mb-3"
  >
    <template #sourceHeader>
      <div class="flex flex-wrap justify-content-between h-2rem">
        <div class="align-self-center font-bold">
          {{ $t("usm.userGroups.assign.permissions.available") }}
        </div>

        <div class="align-self-center">
          <AutoComplete
            v-if="!isLoading"
            v-model="searchQuery"
            @complete="search($event)"
            @item-select="select($event)"
            @clear="clear"
            :suggestions="pickListValues[0]"
            field="displayName"
            :placeholder="$t('usm.userGroups.assign.search.placeholder.permission')"
          />
        </div>
      </div>
    </template>

    <template #targetHeader>
      <div class="h-2rem flex">
        <div class="align-self-center font-bold">
          {{ $t("usm.userGroups.assign.permissions.assigned") }}
        </div>
      </div>
    </template>

    <template #item="slotProps">
      {{ slotProps.item.displayName }}
    </template>
  </PickList>
</template>

<script>
import PickList from "primevue/picklist";
import useAssigner from "@use/useAssigner";
import { ref } from "vue";
import AutoComplete from "primevue/autocomplete";

export default {
  name: "UserGroupAssignPermissions",

  props: {
    userGroupId: {
      type: String,
      required: true,
    },
  },

  components: {
    PickList,
    AutoComplete,
  },

  setup(props) {
    const searchQuery = ref(null);

    const { pickListValues, isLoading, updateList } = useAssigner({
      itemId: props.userGroupId,
      syncMethod: "syncUserGroupPermissions",
      syncModule: "usm",
      syncItemIdProperty: "userGroupId",
      syncIdsProperty: "permissionIds",
      listModule: "usm",
      listMethod: "listPermissions",
      listFilterProperty: "inUserGroupIds",
    });

    const search = (event) => {
      updateList({ search: event.query });
    };

    const select = (event) => {
      updateList({ search: event.value.name });
    };

    const clear = () => {
      updateList({ search: null });
    };

    return {
      pickListValues,
      isLoading,
      searchQuery,
      search,
      select,
      clear,
    };
  },
};
</script>
<style lang="scss">
.hide-sort-controls .p-picklist-list {
  height: inherit !important;
}
</style>
