import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_users
 */

/**
 * Retrieve a list of all users
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listUsers = (params) => api.get(ROUTE.USERS.LIST, { params });

/**
 * Retrieve specified user
 * @method
 * @param {object} params
 * @param {number} params.userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showUser = ({ userId }) => api.get(ROUTE.USERS.SHOW, { params: { userId } });

/**
 * Delete specified user
 * @method
 * @param {object} params
 * @param {number} params.userId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteUser = ({ userId }) => api.delete(ROUTE.USERS.DELETE, { data: { userId } });

/**
 * Update specified user
 * @method
 * @param {object} params
 * @param {string} params.userId
 * @param {string} params.salutation
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {object} params.userAddress
 * @param {object} params.userContact
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateUser = ({ userId, salutation, firstName, lastName, userAddress, userContact }) =>
  api.put(ROUTE.USERS.UPDATE, {
    userId,
    salutation,
    firstName,
    lastName,
    ...userAddress,
    ...userContact,
  });

/**
 * Add or remove permissions from user
 *
 * @method
 * @param {object} params
 * @param {number} params.userId
 * @param {array<number>} params.permissionIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserPermissions = ({ userId, permissionIds }) =>
  api.put(ROUTE.USERS.SYNC.PERMISSIONS, {
    userId,
    permissionIds,
  });

/**
 * Add or remove roles from user
 *
 * @method
 * @param {object} params
 * @param {number} params.userId
 * @param {array<number>} params.roleIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserRoles = ({ userId, roleIds }) =>
  api.put(ROUTE.USERS.SYNC.ROLES, {
    userId,
    roleIds,
  });

/**
 * Add or remove user group from user
 *
 * @method
 * @param {object} params
 * @param {number} params.userId
 * @param {array<number>} params.userIds
 * @returns {Promise<AxiosResponse<any>>}
 */
export const syncUserUserGroups = ({ userId, userGroupIds }) =>
  api.put(ROUTE.USERS.SYNC.USER_GROUPS, {
    userId,
    userGroupIds,
  });
