export default {
  AUTH: {
    LOGIN: "/auth/login",
    REGISTER: "/auth/register",
    ACTIVATION: "/auth/activation",
    REQUEST_NEW_PASSWORD: "/auth/request-new-password",
    CREATE_NEW_PASSWORD: "/auth/create-new-password",
    UPDATE_PASSWORD: "/auth/password",
    VALIDATE_TOKEN: "/auth/ping",
  },

  AUTH_USER: {
    SHOW: "/auth",
  },

  PROFILE: {
    SHOW: "/auth/profile",
    UPDATE: "/auth/profile",
    DELETE: "/auth/profile",
  },

  ROLES: {
    LIST: "/management/roles",
    SHOW: "/management/roles/show",
    CREATE: "/management/roles",
    DELETE: "/management/roles",
    UPDATE: "/management/roles",
    SYNC: {
      PERMISSIONS: "/management/roles/sync/permissions",
      USER_GROUPS: "/management/roles/sync/user-groups",
      USER: "/management/roles/sync/users",
    },
  },

  USER_GROUPS: {
    LIST: "/management/user-groups",
    SHOW: "/management/user-groups/show",
    CREATE: "/management/user-groups",
    DELETE: "/management/user-groups",
    UPDATE: "/management/user-groups",
    SYNC: {
      PERMISSIONS: "/management/user-groups/sync/permissions",
      USER: "/management/user-groups/sync/users",
      ROLES: "/management/user-groups/sync/roles",
    },
  },

  USERS: {
    LIST: "/management/users",
    SHOW: "/management/users/show",
    DELETE: "/management/users",
    UPDATE: "/management/users",
    SYNC: {
      PERMISSIONS: "/management/users/sync/permissions",
      USER_GROUPS: "/management/users/sync/user-groups",
      ROLES: "/management/users/sync/roles",
    },
  },

  PERMISSIONS: {
    LIST: "/management/permissions",
  },

  LEVELS: {
    LIST: "/level",
  },
};
