<template>
  <div v-if="showContent">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BaseFrontdoor",

  props: {
    keepOut: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters("usm/auth", ["isAuthenticated"]),

    showContent() {
      if (this.keepOut && !this.isAuthenticated) return true;
      if (this.isAuthenticated) return true;

      return false;
    },
  },

  methods: {
    redirect() {
      if (this.keepOut && this.isAuthenticated) {
        this.$router.push("/");
      }

      if (!this.isAuthenticated && !this.keepOut) {
        this.$router.push({ name: "Login" });
      }
    },
  },

  created() {
    this.redirect();
  },

  updated() {
    this.redirect();
  },
};
</script>
