<template>
  <div :class="`justify-content-${align}`" class="flex flex-wrap flex-grow-1">
    <div :class="[{ 'max-w-full w-full': fluid }, hasWidth && !fluid && widthClassName]">
      <slot v-bind="$attrs" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseContainer",

  props: {
    width: {
      type: String,
      required: false,
      default: "5",
    },

    fluid: {
      type: Boolean,
      required: false,
      default: false,
    },

    align: {
      type: String,
      required: false,
      default: "center",
    },
  },

  computed: {
    hasWidth() {
      return !!this.width;
    },

    widthClassName() {
      return `w-${this.width}`;
    },
  },
};
</script>
