<template>
  <EditDialog
    update-module="usm"
    update-method="updateUser"
    :validation="v$"
    :form-values="adjustedFormValues"
    dialog-header="usm.users.update.data.address.title"
    :onHide="callApi"
  >
    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.street"
          label="usm.users.update.form.label.street"
          :validation="v$.street"
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.city"
          label="usm.users.update.form.label.city"
          :validation="v$.city"
        />
      </BaseCol>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.zipcode"
          label="usm.users.update.form.label.zipcode"
          :validation="v$.zipcode"
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.country"
          label="usm.users.update.form.label.country"
          :validation="v$.country"
        />
      </BaseCol>
    </BaseRow>
  </EditDialog>
</template>

<script>
import useUser from "@use/useUser";
import { useRoute } from "vue-router";
import useFormValidation from "@use/useFormValidation";

import EditDialog from "../../edit";

export default {
  name: "UpdateAddress",

  components: {
    EditDialog,
  },

  setup() {
    // Setup validation
    const rules = {
      street: ["required"],
      city: ["required"],
      country: ["required"],
      zipcode: ["required"],
    };

    // Load data
    const route = useRoute();
    const { userId } = route.params;

    const { adjustedFormValues, userAddress, hasDocs, callApi } = useUser({
      userId,
      rules,
    });

    const { v$, formValues } = useFormValidation({ formValues: userAddress, rules });

    return {
      v$,
      hasDocs,
      callApi,
      adjustedFormValues,
      userAddress,
      formValues,
    };
  },
};
</script>
