import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Retrieve a list of all course topics
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementListCourseTopics = (params = {}) => api.get(ROUTE.LIST, { params });

/**
 * Show a course topics
 *
 * @param {object} params
 * @param {number} params.courseTopicId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementShowCourseTopic = ({ courseTopicId }) =>
  api.get(ROUTE.SHOW, {
    params: {
      courseTopicId,
    },
  });

/**
 * Create a course topic
 *
 * @param {object} params
 * @param {string} params.displayName
 * @param {string} params.description
 * @param {boolean} params.isActive
 * @param {File|null} params.icon
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementCreateCourseTopic = ({
  displayName = null,
  description = null,
  isActive = false,
  icon = null,
} = {}) => {
  const formData = new FormData();

  formData.append("displayName", displayName);
  formData.append("description", description);
  formData.append("isActive", isActive);

  if (icon) {
    formData.append("icon", icon);
  }

  return api.post(ROUTE.CREATE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * Update a course topic
 *
 * @param {object} params
 * @param {number} params.courseTopicId
 * @param {string} params.displayName
 * @param {string} params.description
 * @param {boolean} params.isActive
 * @param {File|null} params.icon
 * @returns {Promise<AxiosResponse<any>>}
 */
export const managementUpdateCourseTopic = ({
  courseTopicId = null,
  displayName = null,
  description = null,
  isActive = false,
  icon = null,
} = {}) => {
  const formData = new FormData();

  formData.append("courseTopicId", courseTopicId);
  formData.append("displayName", displayName);
  formData.append("description", description);
  formData.append("isActive", isActive);

  if (icon) {
    formData.append("icon", icon);
  }

  return api.post(ROUTE.UPDATE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};
