<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.chapters.add.form.label.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          :messages="bvShowErrors('displayName').messages"
          :error="bvShowErrors('displayName').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextArea
          label="acm.chapters.add.form.label.description"
          v-model="formValues.description"
          :validation="v$.description"
          :messages="bvShowErrors('description').messages"
          :error="bvShowErrors('description').hasMessages"
          floatingLabel
          rows="12"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <div class="flex justify-content-between">
        <BaseButton
          label="acm.chapters.label.confirm.label"
          @click="submitAndConfirm"
          :loading="isLoading"
          icon-right="pi pi-check"
          class="mr-3"
        />
        <BaseButton @click="submit" :loading="isLoading" icon="pi pi-save" />
      </div>
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import useBackendValidation from "@use/useBackendValidation";

export default {
  name: "UpdateChapterForm",

  props: {
    chapterInformation: {
      type: Object,
      required: true,
      default: () => ({
        courseRequestChapterId: null,
        displayName: null,
        description: null,
        order: null,
      }),
    },
  },

  setup(props, { emit }) {
    const { showSuccess, showError } = useNotifications();
    const store = useStore();

    // Initialize form values
    const form = reactive({
      courseRequestChapterId: props.chapterInformation.courseRequestChapterId,
      displayName: props.chapterInformation.displayName,
      description: props.chapterInformation.description,
      order: props.chapterInformation.order,
    });

    // Setup validation
    const rules = {
      courseRequestChapterId: ["required"],
      displayName: ["required"],
      description: ["required"],
      order: [],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const saveChapter = useApi({
      module: "acm",
      method: "updateCourseRequestChapter",
    });

    const confirmChapter = useApi({
      module: "acm",
      method: "confirmCourseChapter",
      params: { courseRequestChapterId: props.chapterInformation.courseRequestChapterId },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(saveChapter.backendValidation, () => {
      updateValidation(saveChapter.backendValidation.value);
    });

    const isLoading = computed(() => {
      return saveChapter.isLoading.value || confirmChapter.isLoading.value;
    });

    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;

      try {
        await saveChapter.callApi(formValues);
        emit("refresh");

        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
      } catch (error) {
        return;
      }

      await store.dispatch("acm/chapters/reloadChapterList", true);
    };

    const submitAndConfirm = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;

      try {
        await saveChapter.callApi(formValues, true);
        await confirmChapter.callApi(formValues, true);
        emit("refresh");

        showSuccess(
          "acm.courses.update.notification.finished.summary",
          "acm.courses.update.notification.finished.detail"
        );
      } catch (error) {
        showError(
          "acm.chapters.label.confirm.error.header",
          `acm.backendValidation.courseRequestChapter.${confirmChapter.error.value.response.data.message}`
        );

        return;
      }

      await store.dispatch("acm/chapters/reloadChapterList", true);
    };

    watch(props.chapterInformation, () => {
      formValues.courseRequestChapterId = props.chapterInformation.courseRequestChapterId;
      formValues.displayName = props.chapterInformation.displayName;
      formValues.description = props.chapterInformation.description;
      formValues.order = props.chapterInformation.order;
    });

    return {
      v$,
      formValues,
      submit,
      submitAndConfirm,
      bvShowErrors,
      isLoading,
    };
  },
};
</script>
