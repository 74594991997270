import menuType from "@data/menuTypes";
import menuName from "@data/menuNames";

export default [
  // Teacher
  {
    label: "acm.navigation.courses",
    to: "/course-requests",
    permissions: ["teacher.course_request.read"],
    menuName: menuName.get().teacher,
    order: 102,
  },
  {
    label: "acm.navigation.courseProposalList",
    to: "/course-proposals",
    permissions: ["teacher.course_proposal.read"],
    icon: "pi pi-list",
    menuName: menuName.get().teacher,
    order: 101,
  },
  {
    label: "acm.navigation.courseProposal",
    to: "/course-proposals/create",
    permissions: ["teacher.course_proposal.create"],
    menuName: menuName.get().teacher,
    order: 100,
    icon: "pi pi-pencil",
  },
  {
    label: "acm.navigation.courseHelp",
    to: "/information",
    permissions: ["teacher.information_material.read"],
    icon: "pi pi-question-circle",
    menuName: menuName.get().teacher,
    order: 95,
  },

  // HAW Management
  {
    label: "acm.navigation.management.courseTopicList",
    to: "/management/course-topics",
    permissions: ["management.course_topic.read"],
    icon: "pi pi-map",
    menuName: menuName.get().management,
    types: [menuType.get().all],
    order: 8,
  },
  {
    label: "acm.navigation.management.courseProposalList",
    to: "/management/course-proposals",
    permissions: ["management.course_proposal.read"],
    icon: "pi pi-list",
    menuName: menuName.get().management,
    types: [menuType.get().all],
    order: 5,
  },
  {
    label: "acm.navigation.management.courseRequestList",
    to: "/management/course-requests",
    permissions: ["management.course_request.read"],
    icon: "pi pi-list",
    menuName: menuName.get().management,
    types: [menuType.get().all],
    order: 3,
  },
  {
    label: "acm.navigation.management.courseList",
    to: "/management/courses",
    permissions: ["management.course.read"],
    icon: "pi pi-list",
    menuName: menuName.get().management,
    types: [menuType.get().all],
    order: 1,
  },
];
