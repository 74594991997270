import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * @module API_rp_reports
 */

/**
 * Show report data
 * @method
 * @param {object} params
 * @param {int[]} params.types
 * @param {int} params.daysIntoPast
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showReportBarChart = ({ types, daysIntoPast }) =>
  api.get(ROUTE.CHART.BAR, {
    params: {
      types,
      daysIntoPast,
    },
  });
