export const namespaced = true;

export const state = {
  reloadChapterList: false,
};

export const getters = {
  reloadChapterList: (state) => state.reloadChapterList,
};

export const mutations = {
  SET_RELOAD_CHAPTER_LIST(state, { reloadChapterList }) {
    state.reloadChapterList = reloadChapterList;
  },
};

export const actions = {
  reloadChapterList({ commit }, reloadChapterList) {
    return new Promise((resolve) => {
      commit("SET_RELOAD_CHAPTER_LIST", { reloadChapterList });
      resolve();
    });
  },
};
