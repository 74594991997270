<template>
  <component :is="component" :url="url" />
</template>

<script>
import COURSE_EXTERNAL_VIDEO_TYPES from "@/modules/haw-components-hac/constants/COURSE_EXTERNAL_VIDEO_TYPES";
import YoutubeVideo from "./partials/YoutubeVideo";
import VimeoVideo from "./partials/VimeoVideo";
import DailyMotionVideo from "./partials/DailyMotionVideo";

export default {
  name: "ExternalVideoViewer",

  components: { DailyMotionVideo, VimeoVideo, YoutubeVideo },

  props: {
    typeId: {
      type: Number,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },

  computed: {
    component() {
      switch (this.typeId) {
        case COURSE_EXTERNAL_VIDEO_TYPES["VIMEO"]:
          return VimeoVideo;
        case COURSE_EXTERNAL_VIDEO_TYPES["DAILYMOTION"]:
          return DailyMotionVideo;
        default:
          return YoutubeVideo;
      }
    },
  },
};
</script>
