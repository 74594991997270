<template>
  <div>
    <div @click="isVisible = true" class="cursor-pointer" v-if="$slots.trigger">
      <slot name="trigger" />
    </div>
    <Dialog v-model:visible="isVisible" v-bind="$attrs" modal :style="{ width }">
      <template #header v-if="$slots.header">
        <slot name="header" />
      </template>

      <slot />

      <template #footer v-if="$slots.footer">
        <slot name="footer" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "primevue/dialog";

export default {
  name: "BaseModal",

  inheritAttrs: false,

  data() {
    return {
      isVisible: false,
    };
  },

  components: {
    Dialog,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    width: {
      type: String,
      required: false,
      default: "860px",
    },
  },

  watch: {
    isVisible(value) {
      this.$emit("update:modelValue", value);
    },

    modelValue(value) {
      this.isVisible = value;
    },
  },

  methods: {
    showModal() {
      this.isVisible = true;
    },
  },
};
</script>
