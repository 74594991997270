<template>
  <BaseHasPermission permission="user_group.update">
    <BaseModal :modelValue="dialogVisible" @show="onShow" @hide="onHide">
      <template #trigger>
        <BaseButton
          :icon="dialogSettings.triggerIcon"
          background="gray-100"
          color="gray-500"
          v-tooltip="$t('usm.userGroups.update.tooltip.edit')"
          @click="dialogVisible = true"
        />
      </template>

      <template #header>
        <BaseHeadline size="6" :dark="true" :no-margin="true">
          {{ dialogSettings.header }}
        </BaseHeadline>
      </template>

      <BaseForm :validation="validation" ref="form" :submit="submit" @submit="updateUser($event)">
        <slot />
      </BaseForm>

      <template #footer>
        <BaseButton outlined :label="dialogSettings.reject" @click="reject" />
        <BaseButton :label="dialogSettings.accept" @click="accept" />
      </template>
    </BaseModal>
  </BaseHasPermission>
</template>

<script>
import useApi from "@use/useApi";
import useDialog from "@use/useDialog";

import useNotifications from "@use/useNotifications";

export default {
  name: "EditWrapper",

  inheritAttrs: false,

  props: {
    dialogHeader: {
      type: String,
      default: "usm.dialog.label.header",
    },
    validation: {
      required: true,
    },
    formValues: {
      type: Object,
      required: true,
    },
    updateModule: {
      type: String,
      required: true,
    },
    updateMethod: {
      type: String,
      required: true,
    },
    onShow: {
      type: Function,
    },
    onHide: {
      type: Function,
    },
  },

  setup(props, { emit }) {
    // Setup notification
    const { showError } = useNotifications();

    const { callApi, backendValidation } = useApi({
      module: props.updateModule,
      method: props.updateMethod,
      onSuccess: () => {
        closeDialog();
        emit("reload");
      },
      onError: () => showError(backendValidation.value.message, ""),
    });

    const { dialogVisible, dialogSettings, openDialog, closeDialog, accept, reject } = useDialog({
      header: props.dialogHeader,
      acceptLabel: "usm.dialog.label.accept",
      rejectLabel: "usm.dialog.label.reject",
      icon: "pi pi-pencil",
      onAccept: () => {
        submit();
      },
      onReject: () => {
        closeDialog();
        props.validation.$reset();
      },
    });

    // Emit form values
    const submit = async () => {
      const isValid = await props.validation.$validate();
      if (!isValid) return;
      callApi({ ...props.formValues });
    };

    return {
      dialogVisible,
      dialogSettings,
      openDialog,
      closeDialog,
      accept,
      reject,
      submit,
    };
  },
};
</script>
