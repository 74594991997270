<template>
  <div style="color: var(--gray-500)">
    <BaseIcon :icon="icon" class="mr-2" />
    {{ label }}
  </div>
</template>

<script>
import BaseIcon from "@/modules/base-components-bc/Base/BaseIcon";
export default {
  components: { BaseIcon },
  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({
        courseRequestChaptersCount: null,
        courseRequestChaptersCountNotCompleted: null,
      }),
    },
  },
  computed: {
    label() {
      return this.course.courseRequestChaptersCount === 0
        ? this.$t("acm.courseRequest.chaptersCompletedLabel.noChapters")
        : this.$t("acm.courseRequest.chaptersCompletedLabel.label", {
            completed:
              this.course.courseRequestChaptersCount -
              this.course.courseRequestChaptersCountNotCompleted,
            chapters: this.course.courseRequestChaptersCount,
          });
    },
    icon() {
      if (this.course.courseRequestChaptersCount === 0) {
        return "pi pi-ellipsis-h";
      }

      if (this.course.courseRequestChaptersCountNotCompleted === 0) {
        return "pi pi-check";
      }

      return "pi pi-pencil";
    },
  },
};
</script>
