import localStorage from "@helper/localStorage";
import { onMounted, ref } from "@vue/runtime-dom";
import { useRoute } from "vue-router";
import storeNames from "@utils/data/storageNames";

/**
 * Cache local state
 *
 * @method
 * @returns {object} { updateCache, cachedState }
 */
const useCaching = () => {
  const cachedState = ref(null);

  const route = useRoute();
  const year = new Date().getYear();
  const uniqueKey = `${storeNames.STATE_STORE}${route.name}-${year}`;

  const writeCache = () => {
    if (cachedState.value) {
      localStorage.write(cachedState.value, uniqueKey);
    }
  };

  const updateCache = (state) => {
    cachedState.value = state;
    writeCache();
  };

  onMounted(() => {
    cachedState.value = localStorage.read(uniqueKey);
    writeCache();
  });

  return { updateCache, cachedState };
};

export default useCaching;
