<template>
  <HAWPageContent :breadcrumb="false" :context-menu="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="$router.back()" icon="pi pi-angle-left" />
      <BaseCard class="p-3 mb-3">
        <BaseRow v-if="hasDocs">
          <BaseCol cols="12" class="flex justify-content-between">
            <BaseHeadline size="1" fake="4">
              {{ quizInformation.displayName }}
            </BaseHeadline>
            <div>
              <BaseDeleteData
                update-module="acm"
                update-method="deleteCourseRequestChapterItemQuiz"
                :formData="{ courseRequestQuizId: quizInformation.courseRequestQuizId }"
                icon="pi pi-trash"
                deleteDialogMessage="acm.quiz.delete.dialog.message"
                deleteDialogHeader="acm.quiz.delete.dialog.header"
                outlined
                :onDeleted="goBack"
              />
            </div>
          </BaseCol>
          <BaseCol cols="12">
            <UpdateQuizForm :quiz-information="quizInformation" @refresh="reload" />
          </BaseCol>
        </BaseRow>
      </BaseCard>

      <BaseCard class="p-3 mt-4">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.question.items.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <QuestionItems :question-items="quizInformation.questionItems" />
          </BaseCol>
          <BaseCol cols="12">
            <QuizMenu :menu-items="quizMenuItems" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import UpdateQuizForm from "./partials/UpdateQuizForm";
import QuestionItems from "./partials/QuestionItems";
import QuizMenu from "./partials/QuizMenu";
import useNotifications from "@use/useNotifications";
import { useRoute, useRouter } from "vue-router";
import { reactive, watch } from "vue";

export default {
  name: "ViewVideo",

  components: {
    HAWPageContent,
    UpdateQuizForm,
    QuestionItems,
    QuizMenu,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { showError } = useNotifications();

    let { courseRequestChapterItemId } = route.params;

    // Initialize quiz values
    const quizInformation = reactive({
      courseRequestQuizId: null,
      displayName: null,
      questionItems: [],
    });

    const quizMenuItems = reactive([]);

    const { docs, callApi, isLoading, hasDocs, backendValidation } = useApi({
      module: "acm",
      method: "showCourseRequestChapterItem",
      params: { courseRequestChapterItemId },
      onMount: true,
      onSuccess: () => {
        quizInformation.courseRequestQuizId = docs.value.data.model.id;
        quizInformation.displayName = docs.value.data.displayName;
        quizInformation.questionItems = docs.value.data.model.courseRequestQuizQuestions;
        if (!quizMenuItems.length) {
          quizMenuItems.push({
            label: "acm.question.label.add",
            to: {
              name: "AddQuestion",
              params: { courseRequestQuizId: quizInformation.courseRequestQuizId },
            },
          });
        }
      },
      onError: () => showError(backendValidation.value.message, ""),
    });

    // Emit load quiz
    const reload = async () => {
      await callApi({});
    };

    const goBack = () => {
      router.go(-1);
    };

    watch(route, () => {
      let { courseRequestChapterItemId } = route.params;
      if (courseRequestChapterItemId) {
        callApi({ courseRequestChapterItemId: courseRequestChapterItemId });
      }
    });

    return {
      isLoading,
      quizInformation,
      quizMenuItems,
      reload,
      hasDocs,
      goBack,
    };
  },
};
</script>
