<template>
  <div id="contextMenuWrapper">
    <div
      class="flex flex-wrap cursor-pointer"
      @click="toggle"
      aria-haspopup="true"
      aria-controls="contextMenu"
    >
      <div class="flex align-items-center justify-content-center mr-1">
        <BaseText class="font-bold">
          {{ user.email }}
        </BaseText>
      </div>

      <div class="flex align-items-center justify-content-center mr-3">
        <BaseIcon :icon="isOpen ? 'pi pi-angle-up' : 'pi pi-angle-down'" />
      </div>
    </div>
    <Menu id="contextMenu" ref="menu" :model="items" :popup="true" class="mt-2" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Menu from "primevue/menu";
import useMenuItems from "@use/useMenuItems";
import menuName from "@data/menuNames";
import menuType from "@data/menuTypes";

export default {
  name: "ContextMenu",

  components: {
    Menu,
  },

  setup() {
    const { items } = useMenuItems({
      menuName: menuName.get().contextMenu,
      menuTypes: [menuType.get().common, menuType.get().governance, menuType.get().administration],
    });

    return { items };
  },

  computed: {
    ...mapGetters("usm/authUser", ["user"]),
  },

  data() {
    return {
      isOpen: false,
    };
  },

  methods: {
    ...mapActions("usm/auth", ["removeToken"]),

    toggle(event) {
      this.isOpen = !this.isOpen;
      this.$refs.menu.toggle(event);
    },

    logout() {
      this.removeToken();
      this.$router.push({ name: "Login" });
    },
  },

  mounted() {
    const element = document.getElementById("contextMenuWrapper");
    window.addEventListener("click", (event) => {
      const isClickInsideElement = element.contains(event.target);

      if (this.isOpen && !isClickInsideElement) {
        this.isOpen = false;
      }
    });
  },
};
</script>
