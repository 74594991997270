import * as chapters from "./modules/chapters";
import * as chapterItems from "./modules/chapterItems";

export default {
  name: "uc",

  state: {},

  mutations: {},

  actions: {},

  getters: {},

  modules: {
    chapters,
    chapterItems,
  },
};
