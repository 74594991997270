import * as auth from "./modules/auth";
import * as authUser from "./modules/authUser";

export default {
  name: "usm",

  state: {},

  mutations: {},

  actions: {},

  getters: {},

  modules: {
    auth,
    authUser,
  },
};
