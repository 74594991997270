<template>
  <Card v-bind="$attrs">
    <template #header v-if="this.$slots.header">
      <slot name="header" />
    </template>

    <template #title v-if="this.$slots.title">
      <slot name="title" />
    </template>

    <template #subTitle v-if="this.$slots.subTitle">
      <slot name="subTitle" />
    </template>

    <template #content>
      <slot />
    </template>

    <template #footer v-if="this.$slots.footer">
      <slot name="footer" />
    </template>
  </Card>
</template>

<script>
import Card from "primevue/card";

export default {
  name: "BaseCard",

  components: {
    Card,
  },
};
</script>
