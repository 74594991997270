<template>
  <BaseButton
    label="acm.management.courseRequests.show.dismiss.button"
    @click="dialogIsOpen = true"
    :loading="isLoading"
    :disabled="!canDismiss"
    icon-left="pi pi-times"
    outlined
  />

  <Dialog
    :header="$t('acm.management.courseRequests.show.dismiss.submit.header')"
    v-model:visible="dialogIsOpen"
    :style="{ width: '95%', 'max-width': '600px' }"
    :modal="true"
  >
    <div class="flex align-items-center mb-6">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span class="line-height-3">
        {{ $t("acm.management.courseRequests.show.dismiss.submit.message") }}<br />
        {{ $t("acm.management.courseRequests.show.dismiss.submit.optional") }}
      </span>
    </div>
    <BaseTextArea
      label="acm.management.courseRequests.show.dismiss.submit.customMessage"
      v-model="form.message"
      floatingLabel
      rows="8"
    />
    <template #footer>
      <BaseButton label="utils.use.confirmDialog.label.reject" @click="dialogIsOpen = false" text />
      <BaseButton label="utils.use.confirmDialog.label.accept" @click="dialogAccept" autofocus />
    </template>
  </Dialog>
</template>

<script>
import useApi from "@use/useApi";
import { useRouter } from "vue-router";
import useNotifications from "@use/useNotifications";
import { reactive, ref } from "vue";
import Dialog from "primevue/dialog";

export default {
  props: {
    courseRequest: {
      type: Object,
      required: true,
    },
    courseRequestChapterIds: {
      type: Array,
      required: true,
      default: () => [],
    },
    canDismiss: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    Dialog,
  },

  setup(props) {
    const router = useRouter();
    const { showSuccess } = useNotifications();

    const dialogIsOpen = ref(false);
    const form = reactive({
      message: null,
    });

    const { isLoading, callApi } = useApi({
      module: "acm",
      method: "managementDismissCourseRequest",
      params: {
        courseRequestId: props.courseRequest.id,
        courseRequestChapterIds: props.courseRequestChapterIds,
      },
      onSuccess() {
        showSuccess(
          "acm.management.courseRequests.show.dismiss.success.summary",
          "acm.management.courseRequests.show.dismiss.success.details"
        );
        router.back();
      },
    });

    const dialogAccept = () => {
      dialogIsOpen.value = false;
      callApi({
        message: form.message,
      });
    };

    return {
      isLoading,
      dialogAccept,
      dialogIsOpen,
      form,
    };
  },
};
</script>
