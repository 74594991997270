export const namespaced = true;

export const state = {
  reloadChapterList: false,
  chapterList: null,
  selectedChapter: null,
};

export const getters = {
  reloadChapterList: (state) => state.reloadChapterList,
  chapterList: (state) => state.chapterList,
  selectedChapter: (state) => state.selectedChapter,
};

export const mutations = {
  SET_RELOAD_CHAPTER_LIST(state, { reloadChapterList }) {
    state.reloadChapterList = reloadChapterList;
  },
  SET_CHAPTER_LIST(state, { chapterList }) {
    state.chapterList = chapterList;
  },
  SET_SELECTED_CHAPTER(state, { selectedChapter }) {
    state.selectedChapter = selectedChapter;
  },
};

export const actions = {
  reloadChapterList({ commit }, reloadChapterList) {
    return new Promise((resolve) => {
      commit("SET_RELOAD_CHAPTER_LIST", { reloadChapterList });
      resolve();
    });
  },
  setChapterList({ commit }, chapterList) {
    return new Promise((resolve) => {
      commit("SET_CHAPTER_LIST", { chapterList });
      resolve();
    });
  },
  setSelectedChapter({ commit }, selectedChapter) {
    return new Promise((resolve) => {
      commit("SET_SELECTED_CHAPTER", { selectedChapter });
      resolve();
    });
  },
};
