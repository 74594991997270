import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Retrieve specified chapter item
 * @method
 * @param {object} params
 * @param {number} params.courseChapterItemId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourseChapterItem = ({ courseChapterItemId }) =>
  api.get(ROUTE.SHOW, { params: { courseChapterItemId } });
