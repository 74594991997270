<template>
  <FileUpload
    ref="fileUpload"
    :name="name"
    :mode="mode"
    :multiple="multiple"
    :accept="accept"
    :disabled="disabled"
    :auto="auto"
    :max-file-size="maxFileSize"
    :invalid-file-size-message="$t(invalidFileSizeMessage)"
    :invalid-file-limit-message="$t(invalidFileLimitMessage)"
    :file-limit="fileLimit"
    :with-credentials="withCredentials"
    :preview-width="previewWidth"
    :choose-label="$t(chooseLabel)"
    :upload-label="$t(uploadLabel)"
    :cancel-label="$t(cancelLabel)"
    :custom-upload="customUpload"
    :show-upload-button="showUploadButton"
    :show-cancel-button="showCancelButton"
    @uploader="uploadHandler"
    @select="thumbnailHandler"
  >
    <template #empty>
      <BaseText>{{ $t(emptyMessage) }}</BaseText>
    </template>
  </FileUpload>
</template>

<script>
import FileUpload from "primevue/fileupload";

export default {
  name: "BaseFileUpload",

  mounted() {
    this.$emit("ref", this.$refs.fileUpload);
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: false,
      default: "advanced",
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    accept: {
      type: String,
      required: false,
      default: "image/*",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    auto: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxFileSize: {
      type: Number,
      required: false,
      default: null,
    },
    invalidFileSizeMessage: {
      type: String,
      required: false,
      default: "baseComponents.baseFileUpload.invalidFileSizeMessage",
    },
    invalidFileLimitMessage: {
      type: String,
      required: false,
      default: "baseComponents.baseFileUpload.invalidFileLimitMessage",
    },
    emptyMessage: {
      type: String,
      required: false,
      default: "baseComponents.baseFileUpload.empty",
    },
    fileLimit: {
      type: Number,
      required: false,
      default: 1,
    },
    withCredentials: {
      type: Boolean,
      required: false,
      default: false,
    },
    previewWidth: {
      type: Number,
      required: false,
      default: 120,
    },
    chooseLabel: {
      type: String,
      required: false,
      default: "baseComponents.baseFileUpload.chooseLabel",
    },
    uploadLabel: {
      type: String,
      required: false,
      default: "baseComponents.baseFileUpload.uploadLabel",
    },
    cancelLabel: {
      type: String,
      required: false,
      default: "baseComponents.baseFileUpload.cancelLabel",
    },
    customUpload: {
      type: Boolean,
      required: false,
      default: true,
    },
    showUploadButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    thumbnailHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
    uploadHandler: {
      type: Function,
      required: false,
      default: () => {},
    },
  },

  components: {
    FileUpload,
  },
};
</script>
