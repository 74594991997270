<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="goBack" icon="pi pi-angle-left" />
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.management.courseTopics.create.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12">
            <CreateForm
              @submit="submit"
              :loading="isLoading"
              :backend-validation="backendValidation"
            />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import { useRouter } from "vue-router";
import HAWPageContent from "@/modules/haw-components-hac/Base/HAWPageContent";
import CreateForm from "./partials/CreateForm";
import useApi from "@use/useApi";

export default {
  components: { CreateForm, HAWPageContent },
  setup() {
    const router = useRouter();

    const goBack = () => {
      router.push({ name: "ManagementCourseTopicsList" });
    };

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "managementCreateCourseTopic",
      onSuccess: () => {
        goBack();
      },
    });

    const submit = (formValues) => {
      callApi(formValues);
    };

    return {
      goBack,
      submit,
      isLoading,
      backendValidation,
    };
  },
};
</script>
