const CONTENT_TYPES = {
  CourseRequestContent: "RTE",
  CourseRequestVideo: "Video",
  CourseRequestExternalVideo: "External Video",
  CourseRequestQuiz: "Quiz",
};

const getContentType = (model) => {
  const modelChunks = model.split("\\");
  const contentType = modelChunks[modelChunks.length - 1];

  return CONTENT_TYPES[contentType];
};

const getContentPreview = (contentTypeModel, modelData) => {
  let preview = {
    displayName: null,
    fileName: null,
    thumbnailPath: null,
  };
  const contentType = getContentType(contentTypeModel);

  switch (contentType) {
    case "Video":
      preview.fileName = modelData.path;
      preview.thumbnailPath = modelData.thumbnailPath;
      break;
    case "External Video":
      preview.displayName = modelData.url;
      break;
    case "RTE":
      preview.displayName = modelData.preview;
      break;
  }

  return preview;
};

const getPreviewRoute = (contentTypeModel) => {
  const contentType = getContentType(contentTypeModel);
  let previewRoute = null;

  switch (contentType) {
    case "Video":
      previewRoute = "ViewVideo";
      break;
    case "External Video":
      previewRoute = "ViewExternalVideo";
      break;
    case "Quiz":
      previewRoute = "ViewQuiz";
      break;
    default:
      previewRoute = "ViewContent";
  }

  return previewRoute;
};

export { getContentType, getContentPreview, getPreviewRoute };
