import * as expandableNavigation from "./modules/expandableNavigation";

export default {
  name: "hac",

  state: {},

  mutations: {},

  actions: {},

  getters: {},

  modules: {
    expandableNavigation,
  },
};
