<template>
  <BaseDropdown
    v-bind="$attrs"
    multiple
    option-label="displayName"
    optionValue="id"
    :options="topics"
    :showToggleAll="false"
  />
</template>

<script>
import { ref } from "vue";
import useApi from "@use/useApi";

export default {
  name: "CourseInformationForm",

  props: {
    courseRequestId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const topics = ref([]);
    const { docs } = useApi({
      module: "acm",
      method: "teacherListCourseTopics",
      params: {
        courseRequestId: props.courseRequestId,
      },
      onMount: true,
      onSuccess: () => {
        topics.value = docs.value.data;
      },
    });

    return {
      topics,
    };
  },
};
</script>
