<template>
  <BaseForm :validation="v$">
    <BaseRow class="align-items-center">
      <BaseCol cols="4">
        <TeacherCourseTopicsSelect
          v-model="formValues.courseTopics"
          label="acm.courses.update.form.label.courseTopics"
          :validation="v$.courseTopics"
          :course-request-id="formValues.courseRequestId"
          :messages="bvShowErrors('courseTopicIds').messages"
          :error="bvShowErrors('courseTopicIds').hasMessages"
        />
      </BaseCol>
      <BaseCol cols="8">
        <BaseTag
          v-for="topic in courseInfo.courseTopics"
          :key="topic.id"
          padding="1"
          class="mx-1 px-2"
        >
          {{ topic.displayName }}
        </BaseTag>
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="12">
        <CustomCourseTopicForm
          v-model="formValues.customCourseTopics"
          :messages="bvShowErrors('customCourseTopics').messages"
          :error="bvShowErrors('customCourseTopics').hasMessages"
        />
      </BaseCol>
    </BaseRow>
    <BaseRow class="mt-1">
      <BaseCol cols="12">
        <BaseTextField
          label="acm.courses.update.form.label.courseTitle"
          v-model="formValues.title"
          :validation="v$.title"
          floatingLabel
          :messages="bvShowErrors('title').messages"
          :error="bvShowErrors('title').hasMessages"
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.courses.update.form.label.courseSubTitle"
          v-model="formValues.subTitle"
          :validation="v$.subTitle"
          floatingLabel
          :messages="bvShowErrors('subTitle').messages"
          :error="bvShowErrors('subTitle').hasMessages"
          :maxLength="maxLength.COURSES.SUBTITLE"
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextArea
          label="acm.courses.update.form.label.courseDescription"
          v-model="formValues.description"
          :validation="v$.description"
          floatingLabel
          rows="12"
          :messages="bvShowErrors('description').messages"
          :error="bvShowErrors('description').hasMessages"
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseHeadline size="6" class="text-primary" noMargin>
          {{ $t("acm.courses.update.form.label.courseImage") }}
        </BaseHeadline>
      </BaseCol>
      <BaseCol cols="12" v-if="courseInfo.thumbnailUrl">
        <img :src="courseInfo.thumbnailUrl" alt="" width="180" />
      </BaseCol>
      <BaseCol cols="12">
        <BaseFileUpload
          name="thumbnailImage"
          :max-file-size="sizes['2MB']"
          :thumbnail-handler="handleThumbnail"
          :messages="bvShowErrors('thumbnailImage').messages"
          :error="bvShowErrors('thumbnailImage').hasMessages"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import { reactive, onMounted, watch } from "vue";
import TeacherCourseTopicsSelect from "./TeacherCourseTopicsSelect";
import BaseTag from "../../../../../../base-components-bc/Base/BaseTag";
import useBackendValidation from "@use/useBackendValidation";
import CustomCourseTopicForm from "@/modules/advanced-course-management-acm/views/Course/views/update/partials/CustomCourseTopicForm";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";
import sizes from "@/modules/haw-components-hac/constants/sizes";

export default {
  name: "CourseInformationForm",

  components: {
    CustomCourseTopicForm,
    BaseTag,
    TeacherCourseTopicsSelect,
  },

  props: {
    courseInfo: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    // Initialize form values
    const form = reactive({
      courseRequestId: props.courseInfo.courseRequestId || null,
      courseTopics: [],
      customCourseTopics: props.courseInfo.customCourseTopics || [],
      title: props.courseInfo.title || null,
      subTitle: props.courseInfo.subTitle || null,
      description: props.courseInfo.description || null,
      thumbnailImage: null,
    });

    // Setup validation
    const rules = {
      courseRequestId: ["required"],
      courseTopics: [],
      title: ["required"],
      subTitle: [],
      description: ["required"],
      thumbnailImage: [],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "updateCourseRequest",
      onSuccess: () => {
        emit("refresh");
      },
    });

    const { updateValidation, bvShowErrors } = useBackendValidation();
    watch(backendValidation, () => {
      updateValidation(backendValidation.value);
    });

    const handleThumbnail = (event) => {
      formValues.thumbnailImage = event.files[0];
    };

    const selectedCourseTopics = () => props.courseInfo.courseTopics.map((topic) => topic.id);
    const selectedCustomCourseTopics = () =>
      props.courseInfo.customCourseTopics.map((topic) => topic.displayName);

    onMounted(() => {
      formValues.courseTopics = [].concat(selectedCourseTopics());
      formValues.customCourseTopics = [].concat(selectedCustomCourseTopics());
    });

    // Emit form values
    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      callApi(formValues);
    };

    return {
      v$,
      isLoading,
      formValues,
      handleThumbnail,
      submit,
      backendValidation,
      bvShowErrors,
      maxLength,
      sizes,
    };
  },
};
</script>
