<template>
  <HAWPageContent :loading="isLoading" :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseBackButton />

      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">{{ $t("tm.teachers.update.title") }}</BaseHeadline>
          </BaseCol>
        </BaseRow>
        <BaseRow v-if="hasDocs" :no-gutter="true">
          <BaseCol cols="12">
            <TeacherForm :teacher="teacher" @reload="refresh" />
          </BaseCol>
        </BaseRow>
        <BaseRow v-else :no-gutter="true">
          <BaseCol cols="12" class="flex justify-content-center">
            <BaseProgressSpinner />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import useApi from "@use/useApi";
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import useNotifications from "@use/useNotifications";
import TeacherForm from "./partials/TeacherForm";
import { ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ManagementTeacherShow",

  components: {
    HAWPageContent,
    TeacherForm,
  },

  setup() {
    const { showSuccess } = useNotifications();
    const route = useRoute();
    const teacherId = ref(route.params.teacherId);

    const teacher = ref(null);

    const { hasDocs, docs, isLoading, callApi } = useApi({
      module: "tm",
      method: "showTeacher",
      params: { teacherId: teacherId.value },
      onMount: true,
      onSuccess: () => {
        teacher.value = docs.value.data;
      },
    });

    const refresh = async () => {
      await callApi({});
      showSuccess(
        "tm.teachers.update.notification.success.summary",
        "tm.teachers.update.notification.success.detail"
      );
    };

    return { teacher, hasDocs, isLoading, refresh };
  },
};
</script>
