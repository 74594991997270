<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.management.courseTopics.update.labels.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          :messages="bvShowErrors('displayName').messages"
          :error="bvShowErrors('displayName').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseTextArea
          label="acm.management.courseTopics.update.labels.description"
          v-model="formValues.description"
          :validation="v$.description"
          :messages="bvShowErrors('description').messages"
          :error="bvShowErrors('description').hasMessages"
          floatingLabel
          rows="12"
        />
      </BaseCol>
      <BaseCol cols="12" lg="9" md="8">
        <BaseFileUpload
          name="icon"
          :messages="bvShowErrors('icon').messages"
          :error="bvShowErrors('icon').hasMessages"
          :thumbnail-handler="handleFile"
          :max-file-size="sizes['2MB']"
          accept="image/png,image/jpeg"
        />
      </BaseCol>
      <BaseCol cols="12" lg="3" md="4" v-if="courseTopic.iconUrl">
        <img :src="courseTopic.iconUrl" alt="" class="mt-3 md:mt-0 w-full" />
      </BaseCol>
      <BaseCol cols="12">
        <BaseCheckbox
          label="acm.management.courseTopics.update.labels.isActive"
          v-model="formValues.isActive"
          :validation="v$.isActive"
          :messages="bvShowErrors('isActive').messages"
          :error="bvShowErrors('isActive').hasMessages"
          class="mt-2"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton
        label="acm.management.courseTopics.update.submit"
        @click="submit"
        :loading="isLoading"
      />
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import { reactive, watch } from "vue";
import useBackendValidation from "@use/useBackendValidation";
import useApi from "@use/useApi";
import { useRouter } from "vue-router";
import sizes from "@/modules/haw-components-hac/constants/sizes";

export default {
  name: "CreateForm",

  props: {
    courseTopic: {
      type: Object,
      required: true,
      default: () => ({
        displayName: null,
        description: null,
        isActive: null,
      }),
    },
  },

  setup(props) {
    const form = reactive({
      courseTopicId: props.courseTopic.id,
      displayName: props.courseTopic.displayName,
      description: props.courseTopic.description,
      icon: null,
      isActive: props.courseTopic.isActive,
    });

    const rules = {
      displayName: ["required"],
      description: ["required"],
    };

    const router = useRouter();
    const { updateValidation, bvMessage, bvHasErrors, bvShowErrors } = useBackendValidation();
    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      callApi(formValues);
      v$.value.$reset();
    };

    const handleFile = (event) => {
      formValues.icon = event.files[0];
    };

    const { isLoading, callApi, backendValidation } = useApi({
      module: "acm",
      method: "managementUpdateCourseTopic",
      onSuccess: () => {
        router.push({ name: "ManagementCourseTopicsList" });
      },
    });

    watch(backendValidation, () => {
      updateValidation(backendValidation.value);
    });

    return {
      v$,
      formValues,
      submit,
      handleFile,
      bvMessage,
      bvHasErrors,
      bvShowErrors,
      isLoading,
      sizes,
    };
  },
};
</script>
