import menuType from "@data/menuTypes";
import menuName from "@data/menuNames";

export default [
  {
    label: "shared.navigation.contextMenu.label.profile",
    to: "/profile",
    icon: "pi pi-cog",
    menuName: menuName.get().contextMenu,
    types: [menuType.get().all],
    order: 60,
  },
];
