<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseBackButton />
      <BaseCard class="p-3">
        <div v-if="loadData.isLoading.value" class="flex justify-content-center">
          <BaseProgressSpinner />
        </div>
        <div v-else>
          <BaseRow>
            <BaseCol cols="12">
              <BaseHeadline size="1" fake="4">
                {{ $t("acm.management.informationMaterial.update.title") }}
              </BaseHeadline>
            </BaseCol>

            <BaseCol cols="12">
              <ConfiguredRte
                :model-value="formValues.material"
                :validation="v$.material"
                :on-change="updateModel"
                editor-style="height: 480px"
              />
            </BaseCol>
          </BaseRow>
          <BaseRow class="justify-content-end">
            <BaseCol class="flex-grow-0">
              <BaseButton @click="submit" :loading="sendData.isLoading.value" icon="pi pi-save" />
            </BaseCol>
          </BaseRow>
        </div>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import useApi from "@use/useApi";
import useNotifications from "@use/useNotifications";
import { reactive } from "vue";
import useFormValidation from "@use/useFormValidation";
import ConfiguredRte from "@/modules/haw-components-hac/Base/ConfiguredRte";

export default {
  name: "CourseHelp",

  components: {
    ConfiguredRte,
    HAWPageContent,
  },

  setup() {
    const { showError, showSuccess } = useNotifications();

    const form = reactive({
      material: null,
    });

    // Setup validation
    const rules = {
      material: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const loadData = useApi({
      module: "acm",
      method: "showInformationMaterial",
      onMount: true,
      onSuccess: () => {
        form.material = loadData.docs.value;
      },
      onError: () => showError(loadData.backendValidation.value.message, ""),
    });

    const sendData = useApi({
      module: "acm",
      method: "updateInformationMaterial",
      data: formValues,
      onError: () => showError(sendData.backendValidation.value.message, ""),
      onSuccess: () => {
        showSuccess(
          "acm.informationMaterial.saved.summary",
          "acm.informationMaterial.saved.detail"
        );
      },
    });

    const updateModel = (event) => {
      form.material = event.htmlValue;
    };

    // Emit form values
    const submit = () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      sendData.callApi(formValues);
    };

    return {
      v$,
      loadData,
      sendData,
      formValues,
      updateModel,
      submit,
    };
  },
};
</script>
