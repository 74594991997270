<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12" class="pb-0">
        <BaseTextField
          label="acm.question.add.form.label.question"
          v-model="formValues.questionText"
          :validation="v$.questionText"
          :messages="bvShowErrors('questionText').messages"
          :error="bvShowErrors('questionText').hasMessages"
          :maxLength="maxLength.QUESTIONS.TEXT"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12" class="pb-0">
        <BaseTextField
          label="acm.question.add.form.label.tip"
          v-model="formValues.questionTooltip"
          :validation="v$.questionTooltip"
          :messages="bvShowErrors('questionTooltip').messages"
          :error="bvShowErrors('questionTooltip').hasMessages"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="12">
        <BaseCheckbox
          v-model="formValues.questionTypeId"
          label="acm.question.add.form.label.multipleChoice"
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import useBackendValidation from "@use/useBackendValidation";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "AddQuestionForm",

  setup(props, { emit }) {
    const route = useRoute();
    const { showSuccess } = useNotifications();
    const store = useStore();
    const courseRequestQuizId = ref(route.params.courseRequestQuizId);

    // Initialize form values
    const form = reactive({
      courseRequestQuizId: courseRequestQuizId.value,
      courseQuizQuestionTypeId: 1,
      questionTypeId: false,
      questionText: null,
      questionTooltip: null,
    });

    // Setup validation
    const rules = {
      courseRequestQuizId: ["required"],
      courseQuizQuestionTypeId: ["required"],
      questionText: ["required"],
      questionTooltip: [],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "storeCourseRequestChapterItemQuizQuestion",
      onSuccess: async () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        emit("refresh");
        await store.dispatch("acm/chapterItems/reloadChapterItemsList", true);
        await store.dispatch("acm/chapters/reloadChapterList", true);
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
    };

    watch(route, () => {
      if (route.params.courseRequestQuizId !== courseRequestQuizId.value) {
        courseRequestQuizId.value = route.params.courseRequestQuizId;
        formValues.courseRequestQuizId = route.params.courseRequestQuizId;
        formValues.courseQuizQuestionTypeId = false;
        formValues.questionText = null;
        formValues.questionTooltip = null;
      }
    });

    watch(formValues, () => {
      formValues.courseQuizQuestionTypeId = formValues.questionTypeId ? 2 : 1;
    });

    return {
      v$,
      isLoading,
      formValues,
      submit,
      bvShowErrors,
      maxLength,
    };
  },
};
</script>
