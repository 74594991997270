export default {
  INFORMATION_MATERIAL: {
    READ: "/information-material",
  },
  TEACHER: {
    TOPICS: {
      LIST: "/teacher-management/course-topics",
    },
  },
  COURSES: {
    PROPOSAL: {
      LIST: "/course-proposals",
      SHOW: "/course-proposals/show",
      CREATE: "/course-proposals",
      UPDATE: "/course-proposals",
      DELETE: "/course-proposals",
    },

    REQUEST: {
      LIST: "/course-requests",
      STORE: "/course-requests",
      SHOW: "/course-requests/show",
      UPDATE: "/course-requests/update",
      DELETE: "/course-requests",
      PUBLISH: "/course-requests/publish",

      CHAPTER: {
        LIST: "/course-request-chapters",
        SHOW: "/course-request-chapters/show",
        STORE: "/course-request-chapters",
        UPDATE: "/course-request-chapters",
        DELETE: "/course-request-chapters",
        FINISH: "/course-request-chapters/finish",

        ITEM: {
          LIST: "/course-request-chapter-items",
          SHOW: "/course-request-chapter-items/show",
        },
      },

      CONTENT: {
        STORE: "/course-request-contents",
        UPDATE: "/course-request-contents",
        DELETE: "/course-request-contents",
      },

      VIDEO: {
        STORE: "/course-request-videos",
        UPDATE: "/course-request-videos/update",
        DELETE: "/course-request-videos",

        EXTERNAL: {
          STORE: "/course-request-external-videos",
          UPDATE: "/course-request-external-videos",
          DELETE: "/course-request-external-videos",
          TYPES: {
            LIST: "/course-external-video-types",
          },
        },
      },

      QUIZ: {
        STORE: "/course-request-quizzes",
        UPDATE: "/course-request-quizzes",
        DELETE: "/course-request-quizzes",
        QUESTION: {
          STORE: "/course-request-quiz-questions",
          UPDATE: "/course-request-quiz-questions",
          DELETE: "/course-request-quiz-questions",
        },
        ANSWER: {
          STORE: "/course-request-quiz-question-answers",
          UPDATE: "/course-request-quiz-question-answers",
          DELETE: "/course-request-quiz-question-answers",
        },
      },
    },

    TOPICS: {
      LIST: "/course-topics",
    },
  },
  MANAGEMENT: {
    COURSES: {
      LIST: "/management/courses",
      SHOW: "/management/courses/show",
      UPDATE: "/management/courses",

      PROPOSAL: {
        LIST: "/management/course-proposals",
        SHOW: "/management/course-proposals/show",
        CREATE: "/management/course-proposals",
        UPDATE: "/management/course-proposals",
        DELETE: "/management/course-proposals",
        APPROVE: "/management/course-proposals/approve",
        DISMISS: "/management/course-proposals/dismiss",
      },

      REQUEST: {
        LIST: "/management/course-requests",
        SHOW: "/management/course-requests/show",

        APPROVE: "/management/course-requests/approve",
        DISMISS: "/management/course-requests/dismiss",

        CHAPTER: {
          ITEM: {
            LIST: "/management/course-request-chapter-items",
            SHOW: "/management/course-request-chapter-items/show",
          },
        },
      },
    },

    INFORMATION_MATERIAL: {
      UPDATE: "/management/information-material",
    },
  },
};
