<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseCard class="p-3">
        <BaseHasPermission permission="management.information_material.update">
          <div class="flex justify-content-end mb-3">
            <BaseButton label="acm.informationMaterial.edit.btn" @click="edit" />
          </div>
        </BaseHasPermission>

        <div v-if="isLoading" class="flex justify-content-center">
          <BaseProgressSpinner />
        </div>
        <div v-else-if="hasDocs">
          <BaseRteViewer :content="docs" />
        </div>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "../../../../../haw-components-hac/Base/HAWPageContent";
import useApi from "@use/useApi";
import useNotifications from "@use/useNotifications";
import { useRouter } from "vue-router";

export default {
  name: "CourseHelp",

  components: {
    HAWPageContent,
  },

  setup() {
    const { showError } = useNotifications();
    const router = useRouter();

    const { callApi, hasDocs, docs, backendValidation, isLoading } = useApi({
      module: "acm",
      method: "showInformationMaterial",
      onError: () => showError(backendValidation.value.message, ""),
    });

    const edit = () => {
      router.push({ name: "CourseHelpUpdate" });
    };

    callApi({});

    return {
      isLoading,
      hasDocs,
      docs,
      edit,
    };
  },
};
</script>
