<template>
  <div
    :class="[
      { col: !cols },
      offset && offsetClassName,
      cols && colsClassName,
      { 'col-fixed': isFixed },
      { 'grid-nogutter': noGutter },
      hasBreakpoints && bpClassName,
      { 'flex-shrink-0': shrink },
    ]"
    :style="fixedStyle"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseCol",

  props: {
    cols: {
      type: String,
      required: false,
    },

    offset: {
      type: String,
      required: false,
    },

    fixed: {
      type: String,
      required: false,
    },

    noGutter: {
      type: Boolean,
      required: false,
      default: false,
    },

    sm: {
      type: String,
      required: false,
      default: null,
    },

    md: {
      type: String,
      required: false,
      default: null,
    },

    lg: {
      type: String,
      required: false,
      default: null,
    },

    xl: {
      type: String,
      required: false,
      default: null,
    },

    shrink: {
      type: Boolean,
      require: false,
      default: false,
    },
  },

  computed: {
    colsClassName() {
      return `col-${this.cols}`;
    },

    bpClassName() {
      const breakPoints = ["sm", "md", "lg", "xl"];
      const classNames = [];

      breakPoints.forEach((bp) => {
        if (this[bp]) {
          classNames.push(`${bp}:col-${this[bp]}`);
        }
      });

      return classNames.join(" ");
    },

    hasBreakpoints() {
      return !!this.md || !!this.sm || !!this.lg || !!this.xl;
    },

    offsetClassName() {
      return `col-offset-${this.offset}`;
    },

    isFixed() {
      return !!this.fixed;
    },

    fixedStyle() {
      if (!this.isFixed) return null;

      return { width: this.fixed };
    },
  },
};
</script>
