<template>
  <BaseRow class="justify-content-end pb-4">
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="!statusFilter"
        label="acm.courseRequest.statusFilter.label.filterAll"
        @click="setStatusFilter(0)"
      />
    </BaseCol>
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="statusFilter === 1"
        label="acm.courseRequest.statusFilter.label.filterEdit"
        @click="setStatusFilter(1)"
      />
    </BaseCol>
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="statusFilter === 3"
        label="acm.courseRequest.statusFilter.label.filterOnline"
        @click="setStatusFilter(3)"
      />
    </BaseCol>
    <BaseCol cols="auto" class="mr-6">
      <StatusFilterButton
        :active="statusFilter === 4"
        label="acm.courseRequest.statusFilter.label.filterChangeRequested"
        @click="setStatusFilter(4)"
      />
    </BaseCol>
  </BaseRow>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import StatusFilterButton from "@/modules/haw-components-hac/Base/StatusFilterButton";

export default {
  name: "StatusFilter",
  components: { StatusFilterButton },
  props: {
    statusFilterMethod: {
      type: null,
      required: false,
    },
  },

  setup() {
    const store = useStore();
    const statusFilter = computed(() => store.getters["acm/courseRequests/statusFilter"]);

    const setStatusFilter = (courseRequestStatusId) => {
      store.dispatch("acm/courseRequests/setStatusFilter", courseRequestStatusId);
    };

    return {
      setStatusFilter,
      statusFilter,
    };
  },
};
</script>
