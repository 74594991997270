const Login = () => import("../views/Login");
const Register = () => import("../views/Register");
const RegisterActivation = () => import("../views/RegisterActivation");
const RequestNewPassword = () => import("../views/RequestNewPassword");
const CreateNewPassword = () => import("../views/CreateNewPassword");

const TeacherStartPage = () => import("../views/Teacher/StartPage");
const TeacherRegister = () => import("../views/Teacher/Register");

const HAWBrowseContent = () => import("@/modules/haw-components-hac/Shared/HAWBrowseContent");

export default [
  {
    path: "/login",
    component: HAWBrowseContent,
    children: [
      {
        path: "",
        name: "Login",
        component: Login,
        meta: { redirectAuth: true },
      },
    ],
  },

  {
    path: "/register",
    component: HAWBrowseContent,
    children: [
      {
        path: "",
        name: "Register",
        component: Register,
        meta: { redirectAuth: true },
      },
    ],
  },

  {
    path: "/activate",
    component: HAWBrowseContent,
    children: [
      {
        path: "",
        name: "Activate",
        component: RegisterActivation,
        meta: { redirectAuth: true },
      },
    ],
  },

  {
    path: "/request-new-password",
    component: HAWBrowseContent,
    children: [
      {
        path: "",
        name: "RequestNewPassword",
        component: RequestNewPassword,
        meta: { redirectAuth: true },
      },
    ],
  },

  {
    path: "/create-new-password",
    component: HAWBrowseContent,
    children: [
      {
        path: "",
        name: "CreateNewPassword",
        component: CreateNewPassword,
        meta: { redirectAuth: true },
      },
    ],
  },

  /**
   * Teacher
   */
  {
    path: "/teacher",
    component: HAWBrowseContent,
    children: [
      {
        path: "",
        name: "TeacherStartPage",
        component: TeacherStartPage,
      },
      {
        path: "register",
        name: "TeacherRegister",
        component: TeacherRegister,
      },
    ],
  },
];
