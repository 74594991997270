import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Retrieve a list of all course topics
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseTopics = (params) => api.get(ROUTE.LIST, { params });

/**
 * Retrieve a list of all course topics
 *
 * @param {object} params
 * @param {number} params.page
 * @param {number} params.itemsPerPage
 * @param {string} params.search
 * @param {string} params.sortedBy
 * @param {boolean} params.sortedDesc
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listCourseTopicsPreview = (params) => api.get(ROUTE.PREVIEW.LIST, { params });

/**
 * Show a specific course topic
 *
 * @param {object} params
 * @param {number} params.courseTopicId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCourseTopic = ({ courseTopicId }) =>
  api.get(ROUTE.SHOW, { params: { courseTopicId } });
