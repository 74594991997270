<template>
  <BaseButton @click="goBack" icon="pi pi-angle-left" :class="{ 'mb-3': !noMargin }" />
</template>

<script>
import { useRouter } from "vue-router";

export default {
  props: {
    routeName: {
      type: String,
      required: false,
      default: null,
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props) {
    const router = useRouter();

    const goBack = async () => {
      try {
        if (props.routeName) {
          await router.push({
            name: props.routeName,
          });
        } else {
          await router.go(-1);
        }
      } catch {
        return;
      }
    };

    return {
      goBack,
    };
  },
};
</script>
