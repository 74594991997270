import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

/**
 * Create notifications
 *
 * @method
 * @returns {object} { notify, showError, showSuccess, showWarning, showInfo }
 */
const useNotifications = () => {
  const toast = useToast();
  const { t } = useI18n();

  /**
   * Trigger toast notification
   *
   * @param {string} summary
   * @param {string} detail
   * @param {string} severity
   * @param {null | number} life
   * @returns {function}
   */
  const notify = (summary, detail, severity = "info", life = 3000) => {
    return toast.add({
      severity,
      summary: t(summary),
      detail: t(detail),
      life,
    });
  };

  /**
   * Trigger error notification
   *
   * @param {string} summary
   * @param {string} detail
   */
  const showError = (summary, detail) => {
    notify(summary, detail, "error");
  };

  /**
   * Trigger success notification
   *
   * @param {string} summary
   * @param {string} detail
   */
  const showSuccess = (summary, detail) => {
    notify(summary, detail, "success");
  };

  /**
   * Trigger warning notification
   *
   * @param {string} summary
   * @param {string} detail
   */
  const showWarning = (summary, detail) => {
    notify(summary, detail, "warning");
  };

  /**
   * Trigger info notification
   *
   * @param {string} summary
   * @param {string} detail
   */
  const showInfo = (summary, detail) => {
    notify(summary, detail);
  };

  return { notify, showError, showSuccess, showWarning, showInfo };
};

export default useNotifications;
