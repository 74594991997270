import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_auth
 */

/**
 * Authenticate into app
 * @method
 * @param {object} params
 * @param {string} params.username
 * @param {string} params.password
 * @returns {Promise<AxiosResponse<any>>}
 */
export const login = ({ username, password }) =>
  api.post(ROUTE.AUTH.LOGIN, {
    username,
    password,
  });

/**
 * Register
 * @method
 * @param {object} params
 * @param {string|null} params.invitationToken
 * @param {string} params.email
 * @param {string} params.salutation
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.password
 * @param {string} params.passwordConfirmation
 * @returns {Promise<AxiosResponse<any>>}
 */
export const register = ({
  invitationToken,
  email,
  salutation,
  firstName,
  lastName,
  password,
  passwordConfirmation,
}) =>
  api.post(ROUTE.AUTH.REGISTER, {
    invitationToken,
    salutation,
    firstName,
    lastName,
    password,
    // eslint-disable-next-line camelcase
    password_confirmation: passwordConfirmation,
    email,
  });

/**
 * Activation after register
 *
 * @method
 * @param {object} params
 * @param {string} params.token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const activation = ({ token }) =>
  api.post(ROUTE.AUTH.ACTIVATION, {
    token,
  });

/**
 * Request a new password
 * @method
 * @param {object} params
 * @param {string} params.email
 * @returns {Promise<AxiosResponse<any>>}
 */
export const requestNewPassword = ({ email }) =>
  api.post(ROUTE.AUTH.REQUEST_NEW_PASSWORD, { email });

/**
 * Create a new password
 *
 * @method
 * @param {object} params
 * @param {string} params.password,
 * @param {string} params.passwordConfirmation
 * @param {string} params.token
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createNewPassword = ({ password, passwordConfirmation, token }) =>
  api.post(ROUTE.AUTH.CREATE_NEW_PASSWORD, {
    password,
    // eslint-disable-next-line camelcase
    password_confirmation: passwordConfirmation,
    token,
  });

/**
 * Update profile password
 *
 * @method
 * @param {string} params.oldPassword
 * @param {string} params.password
 * @param {string} params.paswordConfirmation
 * @returns {Promise<AxiosResponse<object>>}
 */
export const updateProfilePassword = ({
  oldPassword = null,
  password = null,
  passwordConfirmation = null,
}) =>
  api.put(ROUTE.AUTH.UPDATE_PASSWORD, {
    oldPassword,
    password,
    passwordConfirmation,
  });

/**
 * Check if token is still valid
 *
 * @method
 * @returns {Promise<AxiosResponse<object>>}
 */
export const validateToken = () => api.get(ROUTE.AUTH.VALIDATE_TOKEN);
