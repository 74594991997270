export default {
  zipCodeGermany: new RegExp(/^[0-9]{4,6}$/),
  email: new RegExp(/.+@.+\..+/),
  mfaToken: new RegExp(/^\d{6}$/u),
  passwordPolicy: new RegExp(/(?=.*?[^\w\s])(?=.*?[0-9])(?=.{8,})(?=.*?[A-Z]).*?[a-z].*/m),
  passwordLetters: new RegExp(/(?=.*?[A-Z]).*?[a-z].*/m),
  passwordNumbers: new RegExp(/(?=.*?[0-9])/m),
  passwordSymbols: new RegExp(/(?=.*?[^\w\s])/m),
  passwordLength: new RegExp(/(?=.{8,})/m),
  whiteSpace: new RegExp(/\s/g),
  baseComponent: new RegExp(/Base[A-Z]\w+\.(vue|js)$/),
  isNumber: new RegExp(/[+-]?([0-9]*[.])?[0-9]+/m),
  hasLetters: new RegExp(/[a-zA-Z]+/g),
};
