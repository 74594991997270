import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_acm_chapter_video
 */

/**
 * Store specified course chapter item video
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @param {string} params.displayName
 * @param {Blob} params.videoFile
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeCourseRequestChapterItemVideo = ({
  courseRequestChapterId,
  displayName,
  videoFile,
}) => {
  const formData = new FormData();
  formData.append("courseRequestChapterId", courseRequestChapterId);
  formData.append("displayName", displayName);

  if (videoFile) {
    formData.append("file", videoFile);
  }

  return api.post(ROUTE.COURSES.REQUEST.VIDEO.STORE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * Update specified course chapter item video
 * @method
 * @param {object} params
 * @param {number} params.courseRequestVideoId
 * @param {string} params.displayName
 * @param {Blob} params.videoFile
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequestChapterItemVideo = ({
  courseRequestVideoId,
  displayName,
  videoFile,
}) => {
  const formData = new FormData();
  formData.append("courseRequestVideoId", courseRequestVideoId);
  formData.append("displayName", displayName);

  if (videoFile) {
    formData.append("file", videoFile);
  }

  return api.post(ROUTE.COURSES.REQUEST.VIDEO.UPDATE, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * Delete specified course chapter item video
 * @method
 * @param {object} params
 * @param {number} params.courseRequestVideoId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequestChapterItemVideo = ({ courseRequestVideoId }) =>
  api.delete(ROUTE.COURSES.REQUEST.VIDEO.DELETE, { params: { courseRequestVideoId } });

/**
 * Store specified course chapter item external video
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @param {number} params.courseExternalVideoTypeId
 * @param {string} params.url
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeCourseRequestChapterItemVideoExternal = ({
  courseRequestChapterId,
  courseExternalVideoTypeId,
  displayName,
  url,
}) =>
  api.post(ROUTE.COURSES.REQUEST.VIDEO.EXTERNAL.STORE, {
    courseRequestChapterId,
    courseExternalVideoTypeId,
    displayName,
    url,
  });

/**
 * Update specified course chapter item external video
 * @method
 * @param {object} params
 * @param {number} params.order
 * @param {number} params.courseRequestExternalVideoId
 * @param {number} params.courseExternalVideoTypeId
 * @param {string} params.url
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequestChapterItemVideoExternal = ({
  order,
  courseRequestExternalVideoId,
  courseExternalVideoTypeId,
  displayName,
  url,
}) =>
  api.put(ROUTE.COURSES.REQUEST.VIDEO.EXTERNAL.UPDATE, {
    order,
    courseRequestExternalVideoId,
    courseExternalVideoTypeId,
    displayName,
    url,
  });

/**
 * Delete specified course chapter item external video
 * @method
 * @param {object} params
 * @param {number} params.courseRequestExternalVideoId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequestChapterItemVideoExternal = ({ courseRequestExternalVideoId }) =>
  api.delete(ROUTE.COURSES.REQUEST.VIDEO.EXTERNAL.DELETE, {
    params: { courseRequestExternalVideoId },
  });

/**
 * List external video types
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const listChapterItemVideoExternalTypes = () =>
  api.get(ROUTE.COURSES.REQUEST.VIDEO.EXTERNAL.TYPES.LIST);
