import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Get the current users cart
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const showCart = () => api.get(ROUTE.SHOW);

/**
 * Clear the current users cart
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const clearCart = () => api.post(ROUTE.CLEAR);

/**
 * Checkout / "buy" the current users cart
 * @method
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkoutCart = () => api.post(ROUTE.CHECKOUT);

/**
 * Add a course to the cart
 * @method
 * @param {object} params
 * @param {number} params.courseId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const cartAddCourse = ({ courseId }) =>
  api.post(ROUTE.COURSES.ADD, {
    courseId,
  });

/**
 * Remove a course from the cart
 * @method
 * @param {object} params
 * @param {number} params.courseId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const cartRemoveCourse = ({ courseId }) =>
  api.delete(ROUTE.COURSES.REMOVE, {
    params: {
      courseId,
    },
  });
