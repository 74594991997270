<template>
  <div v-if="!isLoading" class="mt-3">
    <RteItem v-if="isType('RTE')" :item="courseRequestChapterItem" />
    <VideoItem v-if="isType('VIDEO')" :item="courseRequestChapterItem" />
    <ExternalVideoItem v-if="isType('EXTERNAL_VIDEO')" :item="courseRequestChapterItem" />
    <QuizItem v-if="isType('QUIZ')" :item="courseRequestChapterItem" />
  </div>
  <div v-else class="flex justify-content-center py-5">
    <BaseProgressSpinner style="width: 50px; height: 50px" strokeWidth="3" />
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useApi from "@use/useApi";
import COURSE_REQUEST_CHAPTER_ITEM_MODELS from "../../../../../../../constants/COURSE_REQUEST_CHAPTER_ITEM_MODELS";
import RteItem from "./items/RteItem";
import VideoItem from "./items/VideoItem";
import ExternalVideoItem from "@/modules/advanced-course-management-acm/components/ExternalVideoItem";
import QuizItem from "./items/QuizItem/index.vue";

export default {
  components: { QuizItem, RteItem, VideoItem, ExternalVideoItem },
  props: {
    courseRequestChapterItemId: {
      type: undefined,
      required: true,
      default: null,
    },
  },

  setup(props) {
    const courseRequestChapterItemId = ref(props.courseRequestChapterItemId);

    const courseRequestChapterItem = ref({});

    const { callApi, isLoading, docs } = useApi({
      module: "acm",
      method: "managementShowChapterItem",
      onMount: true,
      params: {
        courseRequestChapterItemId: courseRequestChapterItemId.value,
      },
      onSuccess() {
        courseRequestChapterItem.value = docs.value.data;
      },
    });

    watch(courseRequestChapterItemId, () => {
      callApi();
    });

    const isType = (name) => {
      return COURSE_REQUEST_CHAPTER_ITEM_MODELS[name] === courseRequestChapterItem.value.modelName;
    };

    return {
      isLoading,
      courseRequestChapterItem,
      isType,
    };
  },
};
</script>
