export default {
  ANSWERS: {
    TEXT: 250,
  },
  EXTERNAL_VIDEOS: {
    URL: 500,
  },
  QUESTIONS: {
    TEXT: 500,
  },
  COURSES: {
    SUBTITLE: 250,
  },
  USERS: {
    SALUTATION: 100,
    TITLE: 100,
    FIRST_NAME: 100,
    LAST_NAME: 100,
    EMAIL: 100,
    STREET: 100,
    ZIPCODE: 5,
    CITY: 100,
    PHONE: 100,
    MOBILE: 100,
  },
};
