<template>
  <div v-if="!isLoading" class="border-200">
    <BaseRow>
      <BaseCol>
        <BaseHeadline size="1" fake="2" :no-margin="true" class="mb-4">
          {{ courseRequest.title }}
        </BaseHeadline>

        <BaseParagraph v-if="courseRequest.subTitle">{{ courseRequest.subTitle }}</BaseParagraph>

        <BaseParagraph v-if="courseRequest.description">
          {{ courseRequest.description }}
        </BaseParagraph>

        <CourseTopics :course-topics="courseRequest.courseTopics" />
        <CustomCourseTopicsWarning
          :custom-course-topics="courseRequest.customCourseTopics"
          class="pt-3"
        />
      </BaseCol>
      <BaseCol cols="shrink">
        <Thumbnail :url="courseRequest.thumbnailUrl" />
      </BaseCol>
    </BaseRow>

    <div v-for="(chapter, index) in courseRequest.courseRequestChapters" :key="chapter.id">
      <ChapterContainer
        :index="index"
        :courseRequestChapter="chapter"
        @approved="chapterApproved"
        @dismissed="chapterDismissed"
        @clear="chapterCleared"
        class="mt-6 mb-3"
      />
    </div>

    <ApproveCourseRequestButtons
      v-if="courseRequest && courseRequest.courseRequestChapters"
      :courseRequest="courseRequest"
      :approved="approvedChapters"
      :dismissed="dismissedChapters"
    />
  </div>
  <div v-else class="py-8 flex justify-content-center">
    <BaseProgressSpinner />
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { watch, ref } from "vue";
import CourseTopics from "@/modules/haw-components-hac/Base/CourseTopics";
import ChapterContainer from "./partials/ChapterContainer";
import ApproveCourseRequestButtons from "./partials/buttons/ApproveCourseRequestButtons";
import Thumbnail from "./partials/Thumbnail";
import CustomCourseTopicsWarning from "./partials/topics/CustomCourseTopicsWarning";

export default {
  components: {
    CustomCourseTopicsWarning,
    Thumbnail,
    ApproveCourseRequestButtons,
    ChapterContainer,
    CourseTopics,
  },

  props: {
    courseRequestId: {
      type: undefined,
      required: true,
      default: null,
    },
  },

  setup(props) {
    const courseRequestId = ref(props.courseRequestId);
    const courseRequest = ref({});

    const approvedChapters = ref([]);
    const dismissedChapters = ref([]);

    const { callApi, isLoading, docs } = useApi({
      module: "acm",
      method: "managementShowCourseRequest",
      onMount: true,
      params: {
        courseRequestId: courseRequestId.value,
      },
      onSuccess() {
        courseRequest.value = docs.value.data;
      },
    });

    watch(courseRequestId, () => {
      callApi();
    });

    const removeFromArray = (refArray, value) => {
      let index = refArray.value.indexOf(value);
      if (index > -1) {
        refArray.value.splice(index, 1);
      }
    };

    const chapterApproved = (event) => {
      if (!approvedChapters.value.includes(event.value)) {
        approvedChapters.value.push(event.value);
      }
      removeFromArray(dismissedChapters, event.value);
    };

    const chapterDismissed = (event) => {
      if (!dismissedChapters.value.includes(event.value)) {
        dismissedChapters.value.push(event.value);
      }
      removeFromArray(approvedChapters, event.value);
    };

    const chapterCleared = (event) => {
      removeFromArray(approvedChapters, event.value);
      removeFromArray(dismissedChapters, event.value);
    };

    return {
      isLoading,
      courseRequest,
      chapterApproved,
      chapterDismissed,
      chapterCleared,
      approvedChapters,
      dismissedChapters,
    };
  },
};
</script>
