<template>
  <BaseRow class="pb-1">
    <BaseCol cols="shrink">
      <BaseIcon class="pl-3 p-2" :icon="answer.isCorrect ? 'pi pi-check' : 'pi pi-times'" />
    </BaseCol>
    <BaseCol>
      {{ answer.answerText }}
    </BaseCol>
  </BaseRow>
</template>

<script>
export default {
  props: {
    answer: {
      type: Object,
      required: true,
      default: () => ({
        answerText: null,
        isCorrect: false,
      }),
    },
  },
};
</script>
