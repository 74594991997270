<template>
  <BaseRteViewer :content="item.model.content" />
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({
        model: {
          content: null,
        },
      }),
    },
  },
};
</script>
