export default {
  VUEX_STORE:
    process.env.NODE_ENV === "production"
      ? `${process.env.VUE_APP_NAME}-store`
      : `${process.env.VUE_APP_NAME}-dev-store`,

  STATE_STORE:
    process.env.NODE_ENV === "production"
      ? `${process.env.VUE_APP_NAME}-state`
      : `${process.env.VUE_APP_NAME}-dev-state`,
};
