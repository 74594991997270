import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_acm_chapter_quiz
 */

/**
 * Store specified course chapter item quiz
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @param {String} params.displayName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeCourseRequestChapterItemQuiz = ({ courseRequestChapterId, displayName }) =>
  api.post(ROUTE.COURSES.REQUEST.QUIZ.STORE, { courseRequestChapterId, displayName });

/**
 * Update specified course chapter item quiz
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizId
 * @param {number} params.order
 * @param {String} params.displayName
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequestChapterItemQuiz = ({ courseRequestQuizId, order, displayName }) =>
  api.put(ROUTE.COURSES.REQUEST.QUIZ.UPDATE, { courseRequestQuizId, order, displayName });

/**
 * Delete specified course chapter item quiz
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequestChapterItemQuiz = ({ courseRequestQuizId }) =>
  api.delete(ROUTE.COURSES.REQUEST.QUIZ.DELETE, { params: { courseRequestQuizId } });

/**
 * Store specified course chapter item quiz question
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizId
 * @param {number} params.courseQuizQuestionTypeId
 * @param {String} params.questionText
 * @param {String} params.questionTooltip
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeCourseRequestChapterItemQuizQuestion = ({
  courseRequestQuizId,
  courseQuizQuestionTypeId,
  questionText,
  questionTooltip,
}) =>
  api.post(ROUTE.COURSES.REQUEST.QUIZ.QUESTION.STORE, {
    courseRequestQuizId,
    courseQuizQuestionTypeId,
    questionText,
    questionTooltip,
  });

/**
 * Update specified course chapter item quiz question
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizQuestionId
 * @param {String} params.questionText
 * @param {String} params.questionTooltip
 * @param {number} params.order
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequestChapterItemQuizQuestion = ({
  courseRequestQuizQuestionId,
  questionText,
  questionTooltip,
  order,
}) =>
  api.put(ROUTE.COURSES.REQUEST.QUIZ.QUESTION.UPDATE, {
    courseRequestQuizQuestionId,
    questionText,
    questionTooltip,
    order,
  });

/**
 * Delete specified course chapter item quiz question
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizQuestionId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequestChapterItemQuizQuestion = ({ courseRequestQuizQuestionId }) =>
  api.delete(ROUTE.COURSES.REQUEST.QUIZ.QUESTION.DELETE, {
    params: { courseRequestQuizQuestionId },
  });

/**
 * Store specified course chapter item quiz answer
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizQuestionId
 * @param {String} params.answerText
 * @param {Boolean} params.isCorrect
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeCourseRequestChapterItemQuizAnswer = ({
  courseRequestQuizQuestionId,
  answerText,
  isCorrect,
}) =>
  api.post(ROUTE.COURSES.REQUEST.QUIZ.ANSWER.STORE, {
    courseRequestQuizQuestionId,
    answerText,
    isCorrect,
  });

/**
 * Update specified course chapter item quiz answer
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizQuestionAnswerId
 * @param {String} params.answerText
 * @param {Boolean} params.isCorrect
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequestChapterItemQuizAnswer = ({
  courseRequestQuizQuestionAnswerId,
  answerText,
  isCorrect,
}) =>
  api.put(ROUTE.COURSES.REQUEST.QUIZ.ANSWER.UPDATE, {
    courseRequestQuizQuestionAnswerId,
    answerText,
    isCorrect,
  });

/**
 * Delete specified course chapter item quiz answer
 * @method
 * @param {object} params
 * @param {number} params.courseRequestQuizQuestionAnswerId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequestChapterItemQuizAnswer = ({ courseRequestQuizQuestionAnswerId }) =>
  api.delete(ROUTE.COURSES.REQUEST.QUIZ.ANSWER.DELETE, {
    params: { courseRequestQuizQuestionAnswerId },
  });
