<template>
  <BaseTag v-for="topic in courseTopics" :key="topic.id" class="py-2 mr-1 my-1">
    {{ topic.displayName }}
  </BaseTag>
</template>

<script>
export default {
  name: "CourseTopics",
  props: {
    courseTopics: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
</script>
