import i18n from "@/i18n";

const { t } = i18n.global;

export default {
  names: [
    {
      name: "mainNavigation",
      displayName: "utils.menuName.mainNavigation",
    },

    {
      name: "contextMenu",
      displayName: "utils.menuName.contextMenu",
    },

    {
      name: "user",
      displayName: "utils.menuName.user",
    },

    {
      name: "teacher",
      displayName: "utils.menuName.teacher",
    },

    {
      name: "management",
      displayName: "utils.menuName.management",
    },
  ],

  list() {
    return this.names.map(({ displayName, name }) => ({
      displayName: t(displayName),
      name,
    }));
  },

  all() {
    return this.names.map(({ name }) => name);
  },

  get() {
    const menuNames = {};
    this.names.forEach((type) => {
      menuNames[type.name] = type.name;
    });

    return menuNames;
  },
};
