<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">{{ $t("acm.courses.create.title") }}</BaseHeadline>
          </BaseCol>
          <BaseCol cols="12" v-if="showSuccess">
            <BaseMessage class="my-4" severity="success" :life="5000">
              {{ $t("acm.courses.create.form.message.success") }}
            </BaseMessage>
          </BaseCol>
          <BaseCol cols="12" v-if="!showSuccess">
            <BaseHeadline size="2" fake="6" class="text-primary">{{
              $t("acm.courses.create.form.title")
            }}</BaseHeadline>
            <CreateForm
              @submit="callApi($event)"
              :loading="isLoading"
              :backendValidation="backendValidation"
              @resetHandler="setResetHandler"
            />
          </BaseCol>
          <BaseCol cols="12" v-if="showSuccess">
            <BaseButton
              label="acm.courses.create.form.label.newForm"
              @click="showSuccess = false"
            />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import { ref } from "vue";
import useApi from "@use/useApi";
import CreateForm from "./partials/CreateForm.vue";
import HAWPageContent from "../../../../haw-components-hac/Base/HAWPageContent.vue";

export default {
  name: "CourseProposal",

  components: {
    CreateForm,
    HAWPageContent,
  },

  setup() {
    const showSuccess = ref(false);
    const reset = ref(null);

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "createCourseProposal",
      onSuccess: () => {
        showSuccess.value = true;
        reset.value();
      },
    });

    const setResetHandler = (event) => {
      reset.value = event;
    };

    return { callApi, showSuccess, isLoading, backendValidation, reset, setResetHandler };
  },
};
</script>
