<template>
  <div>
    <div class="flex justify-content-between flex-wrap">
      <div class="flex-shrink-0 align-self-center">
        <slot name="header" v-if="!loading" />
        <Skeleton height="2rem" class="p-mb-2" v-else></Skeleton>
      </div>
      <div class="flex-shrink-0 align-self-center">
        <slot name="context" />
        <ContextMenu v-if="contextMenu" class="mt-2 mb-4" />
      </div>
    </div>
    <div v-if="breadcrumb" class="mb-6">
      <BaseDivider />
      <BaseBreadcrumb />
    </div>

    <slot v-if="!loading" />
  </div>
</template>

<script>
import Skeleton from "primevue/skeleton";
import ContextMenu from "../../base-components-bc/Shared/Navigation/ContextMenu";

export default {
  name: "HAWPageContent",

  components: {
    Skeleton,
    ContextMenu,
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    breadcrumb: {
      type: Boolean,
      required: false,
      default: true,
    },
    contextMenu: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
