<template>
  <BaseFormField>
    <BaseFieldLabel :for="id" v-if="!floatingLabel">{{ labelField }}</BaseFieldLabel>
    <span :class="[{ 'p-float-label mt-3': floatingLabel }]" class="w-full">
      <Textarea
        v-bind="$attrs"
        v-model="inputValue"
        :id="id"
        :placeholder="!floatingLabel ? placeholderField || labelField : null"
        :aria-describedby="`${id}-help`"
        :class="[
          { 'p-invalid': hasErrors || error },
          { 'p-inputtext-sm': small && !large },
          { 'p-inputtext-lg': large && !small },
        ]"
        class="w-full"
        :rows="rows"
      />
      <BaseFieldLabelFloating :for="id" v-if="floatingLabel">
        {{ labelField }}
      </BaseFieldLabelFloating>
    </span>

    <div class="flex w-full">
      <div class="ml-1 w-full" :id="`${id}-help`">
        <FieldMessages :messages="messages" v-if="hasMessages && !hasErrors" :error="error" />
        <ErrorMessages :messages="errorMessages" v-if="hasErrors" />
      </div>
      <BaseMaxLengthLabel :value="modelValue" :maxLength="maxLength" />
    </div>
  </BaseFormField>
</template>

<script>
import Textarea from "primevue/textarea";
import { random } from "lodash";
import ErrorMessages from "./partials/ErrorMessages.vue";
import FieldMessages from "./partials/FieldMessages.vue";
import useErrorMessages from "@utils/use/useErrorMessages";

export default {
  name: "BaseTextArea",

  setup(props) {
    const { errorMessages, hasErrors } = useErrorMessages(props.validation);

    return { errorMessages, hasErrors };
  },

  components: {
    Textarea,
    ErrorMessages,
    FieldMessages,
  },

  props: {
    modelValue: {
      type: undefined,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
    },

    hint: {
      type: String,
      required: false,
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    small: {
      type: Boolean,
      required: false,
      default: false,
    },

    large: {
      type: Boolean,
      required: false,
      default: false,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    messages: {
      type: Array,
      required: false,
      default: () => [],
    },

    validation: {
      type: Object,
      required: false,
      default: () => {},
    },

    hideMandatory: {
      type: Boolean,
      required: false,
    },

    floatingLabel: {
      type: Boolean,
      required: false,
    },

    rows: {
      type: String,
      required: false,
    },

    maxLength: {
      type: Number,
      required: false,
      default: 2000,
    },
  },

  computed: {
    id() {
      return `textarea-${random(100)}`;
    },

    labelField() {
      const label = this.$t(this.label);
      const requiredLabel = `${label} *`;

      if (this.hideMandatory) {
        return label;
      }

      if (this.required) return requiredLabel;

      if (this.validation) {
        if ("required" in this.validation) {
          return requiredLabel;
        }
      }

      return label;
    },

    hasLabel() {
      return !!this.label;
    },

    hasMessages() {
      return this.messages.length > 0;
    },

    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>
