<template>
  <BaseHasPermission permission="role.read" redirect>
    <router-view />
  </BaseHasPermission>
</template>

<script>
export default {
  name: "Roles",
};
</script>
