<template>
  <label v-bind="$attrs" :style="{ color: 'var(--text-color)' }">
    <slot />
  </label>
</template>

<script>
export default {
  name: "BaseFieldLabel",

  inheritAttrs: false,
};
</script>
