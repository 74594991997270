import api from "@/api";
import ROUTE from "./apiRoutes";

/**
 * Check the quizzes answers
 *
 * @param {object} params
 * @param {number} params.courseQuizId
 * @param {array} params.answers
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkCourseQuiz = ({ courseQuizId = 0, answers = [] } = {}) =>
  api.post(ROUTE.CHECK, {
    courseQuizId,
    answers,
  });
