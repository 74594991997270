import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";

/**
 * Use confirm dialog
 *
 * @method
 * @param {object} params
 * @param {string} params.message
 * @param {string} params.header
 * @param {string} [params.icon=pi pi-exclamation-triangle]
 * @param {function} [params.onAccept]
 * @param {function} [params.onReject]
 * @param {string} [params.acceptLabel=utils.use.confirmDialog.label.accept]
 * @param {string} [params.rejectLabel=utils.use.confirmDialog.label.reject]
 * @returns {object} { confirmDialog }
 */
const useConfirmDialog = ({
  message,
  header,
  icon,
  onAccept,
  onReject,
  acceptLabel,
  rejectLabel,
}) => {
  const { t } = useI18n();
  const confirm = useConfirm();

  /**
   * Show confirm dialog
   *
   */
  const confirmDialog = () => {
    confirm.require({
      message: t(message),
      header: t(header),
      icon: icon || "pi pi-exclamation-triangle",
      accept: () => {
        onAccept && onAccept();
      },
      reject: () => {
        onReject && onReject();
      },
      acceptLabel: acceptLabel || t("utils.use.confirmDialog.label.accept"),
      rejectLabel: rejectLabel || t("utils.use.confirmDialog.label.reject"),
    });
  };

  return { confirmDialog };
};

export default useConfirmDialog;
