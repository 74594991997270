<template>
  <BaseDropdown
    v-bind="$attrs"
    option-label="displayName"
    optionValue="id"
    :options="videoTypes"
    :loading="isLoading"
  />
</template>

<script>
import { ref } from "vue";
import useApi from "@use/useApi";

export default {
  name: "ExternalVideoTypeSelect",

  setup() {
    const videoTypes = ref([]);
    const { docs, isLoading } = useApi({
      module: "acm",
      method: "listChapterItemVideoExternalTypes",
      onMount: true,
      onSuccess: () => {
        videoTypes.value = docs.value.data;
      },
    });

    return {
      videoTypes,
      isLoading,
    };
  },
};
</script>
