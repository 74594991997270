<template>
  <BaseFormField>
    <span class="p-float-label mt-3 w-full">
      <AutoComplete
        :id="autoCompleteId"
        v-model="user"
        :suggestions="userSuggestions"
        @complete="searchUsers($event)"
        @item-select="selectUserId(user.id)"
        @item-unselect="selectUserId(null)"
        :loading="loadUser.isLoading || loadSuggestions.isLoading"
        :field="(proxy) => getUserDisplayName(proxy)"
        dropdown
        class="w-auto-complete"
      />
      <BaseButton
        icon="pi pi-replay"
        :disabled="!canResetUser"
        @click="resetUser"
        text
        class="ml-1"
      />
      <BaseButton icon="pi pi-times" @click="clearUser" text class="ml-1" />
      <BaseFieldLabelFloating :for="autoCompleteId">
        {{ $t(label) }}
      </BaseFieldLabelFloating>
    </span>
  </BaseFormField>
</template>

<script>
import { computed, ref, watch } from "vue";
import useApi from "@use/useApi";
import AutoComplete from "primevue/autocomplete";
import { random } from "lodash";

export default {
  props: {
    modelValue: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },

  components: {
    AutoComplete,
  },

  setup(props, { emit }) {
    const autoCompleteId = `autocomplete-${random(100)}`;

    const user = ref(null);
    const userId = ref(props.modelValue);
    const userSuggestions = ref([]);

    const loadedInitial = ref(false);
    const initialUser = ref(null);

    const loadUser = useApi({
      module: "usm",
      params: {
        userId: userId.value,
      },
      method: "showUser",
      onSuccess: () => {
        user.value = loadUser.docs.value;
        if (!initialUser.value) {
          initialUser.value = loadUser.docs.value;
        }
      },
    });

    const loadSuggestions = useApi({
      module: "usm",
      method: "listUsers",
      params: {
        page: 1,
        perPage: 10,
        withoutTeacher: true,
      },
      onSuccess: () => {
        userSuggestions.value = loadSuggestions.docs.value.data;
      },
    });

    const selectUserId = (userId) => {
      emit("update:modelValue", userId);
    };

    const searchUsers = (event) => {
      loadSuggestions.callApi({
        search: event.query,
      });
    };

    const getUserDisplayName = (proxy) => {
      if (!proxy || !proxy.id) return "";

      return `${proxy.salutation} ${proxy.firstName} ${proxy.lastName} (${proxy.email})`;
    };

    const clearUser = () => {
      selectUserId(null);
      user.value = null;
    };

    const resetUser = () => {
      selectUserId(initialUser.value ? initialUser.value.id : null);
      user.value = initialUser.value;
    };

    const canResetUser = computed(() => {
      if (!user.value && !initialUser.value) {
        return false;
      }

      if (!user.value || !initialUser.value) {
        return true;
      }

      return user.value.id !== initialUser.value.id;
    });

    watch(userId, () => {
      if (!loadedInitial.value && userId.value !== user.value.id) {
        loadedInitial.value = true;
        loadUser.callApi();
      }
    });

    if (userId.value) {
      loadedInitial.value = true;
      loadUser.callApi();
    }
    loadSuggestions.callApi();

    return {
      user,
      userSuggestions,
      loadUser,
      loadSuggestions,
      searchUsers,
      selectUserId,
      getUserDisplayName,
      clearUser,
      resetUser,
      canResetUser,
      autoCompleteId,
    };
  },
};
</script>

<style lang="scss">
.w-auto-complete {
  width: calc(100% - 90px);
}
</style>
