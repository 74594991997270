<template>
  <HAWPageContent :breadcrumb="false">
    <BaseContainer fluid class="max-w-1024">
      <BaseButton class="mb-3" @click="goBack" icon="pi pi-angle-left" />
      <BaseCard class="p-3">
        <BaseRow>
          <BaseCol cols="12">
            <BaseHeadline size="1" fake="4">
              {{ $t("acm.management.courseTopics.update.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol cols="12" v-if="courseTopic">
            <UpdateForm :course-topic="courseTopic" />
          </BaseCol>
          <BaseCol cols="12" v-else class="text-center my-6">
            <BaseProgressSpinner size="48" />
          </BaseCol>
        </BaseRow>
      </BaseCard>
    </BaseContainer>
  </HAWPageContent>
</template>

<script>
import HAWPageContent from "@/modules/haw-components-hac/Base/HAWPageContent";
import { useRoute, useRouter } from "vue-router";
import useApi from "@use/useApi";
import { computed, ref, watch } from "vue";
import UpdateForm from "./partials/UpdateForm";

export default {
  components: { UpdateForm, HAWPageContent },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const courseTopic = ref(null);
    const courseTopicId = computed(() => route.params.courseTopicId);

    const { docs, callApi } = useApi({
      module: "acm",
      method: "managementShowCourseTopic",
      onMount: true,
      params: {
        courseTopicId: courseTopicId.value,
      },
      onSuccess: () => {
        courseTopic.value = docs.value.data;
      },
    });

    watch(courseTopicId, () => {
      if (courseTopicId.value) {
        callApi({
          courseTopicId: courseTopicId.value,
        });
      }
    });

    const goBack = () => {
      router.push({ name: "ManagementCourseTopicsList" });
    };

    return {
      goBack,
      courseTopic,
    };
  },
};
</script>
