import hasPermission from "@helper/hasPermission";
import i18n from "@/i18n";

const { t } = i18n.global;

export default {
  types: [
    {
      name: "common",
      displayName: "utils.menuTypes.common",
      permission: "menu_type.common",
      switchable: true,
    },

    {
      name: "governance",
      displayName: "utils.menuTypes.governance",
      permission: "menu_type.governance",
      switchable: true,
    },

    {
      name: "administration",
      displayName: "utils.menuTypes.administration",
      permission: "menu_type.administration",
      switchable: true,
    },

    {
      name: "user",
      displayName: "utils.menuTypes.user",
      permission: "menu_type.user",
      switchable: true,
    },

    {
      name: "public",
      displayName: "utils.menuTypes.public",
      permission: null,
      switchable: false,
    },
  ],

  list() {
    const types = this.types.filter(({ permission }) => hasPermission(permission) || !permission);

    return types.map(({ displayName, name, switchable }) => ({
      name: t(displayName),
      value: name,
      switchable,
    }));
  },

  all() {
    return this.types.map(({ name }) => name);
  },

  get() {
    const menuTypes = {};
    this.types.forEach((type) => {
      menuTypes[type.name] = type.name;
    });

    return menuTypes;
  },
};
