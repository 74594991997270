<template>
  <ol v-bind="$attrs" class="list-decimal mb-4">
    <slot />
  </ol>
</template>

<script>
export default {
  name: "BaseUl",
};
</script>
