export const namespaced = true;

export const state = {
  reloadChapterItemList: false,
};

export const getters = {
  reloadChapterItemList: (state) => state.reloadChapterItemList,
};

export const mutations = {
  SET_RELOAD_CHAPTER_ITEM_LIST(state, { reloadChapterItemList }) {
    state.reloadChapterItemList = reloadChapterItemList;
  },
};

export const actions = {
  reloadChapterItemList({ commit }, reloadChapterItemList) {
    return new Promise((resolve) => {
      commit("SET_RELOAD_CHAPTER_ITEM_LIST", { reloadChapterItemList });
      resolve();
    });
  },
};
