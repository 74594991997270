<template>
  <BaseButton v-bind="$attrs" :class="{ 'active-filter': active }" text />
</template>

<script>
export default {
  name: "StatusFilterButton",
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.p-button {
  border-radius: 0;
  padding: 0.5rem 0;
  position: relative;
  box-shadow: none;

  &::before {
    content: "";
    display: block;
    background-color: var(--primary-color);
    width: 20%;
    height: 2px;
    position: absolute;
    top: initial;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }

  &.p-button-text {
    color: var(--text-color);

    &:enabled:hover {
      color: var(--primary-color);
      background-color: transparent;

      &:before {
        opacity: 1;
        width: 100%;
      }
    }
  }

  &.active-filter {
    color: var(--primary-color);

    &:before {
      opacity: 1;
      width: 100%;
    }
  }
}
</style>
