<template>
  <li v-bind="$attrs" class="ml-2 line-height-3" :style="{ color: 'var(--text-color-secondary)' }">
    <slot />
  </li>
</template>

<script>
export default {
  name: "BaseLi",
};
</script>
