<template>
  <BaseForm :validation="v$">
    <BaseRow>
      <BaseCol cols="12">
        <BaseTextField
          label="acm.courseChapterItems.displayName"
          v-model="formValues.displayName"
          :validation="v$.displayName"
          :messages="bvShowErrors('displayName').messages"
          :error="bvShowErrors('displayName').hasMessages"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>

    <template #submit>
      <BaseButton label="" @click="submit" :loading="isLoading" icon="pi pi-save" />
    </template>
  </BaseForm>
</template>

<script>
import useApi from "@use/useApi";
import useFormValidation from "@use/useFormValidation";
import useNotifications from "@use/useNotifications";
import { reactive, watch } from "vue";
import { useStore } from "vuex";
import useBackendValidation from "@use/useBackendValidation";

export default {
  name: "UpdateQuizForm",

  props: {
    quizInformation: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { showSuccess } = useNotifications();
    const store = useStore();

    // Initialize form values
    const form = reactive({
      courseRequestQuizId: props.quizInformation.courseRequestQuizId,
      displayName: props.quizInformation.displayName,
    });

    // Setup validation
    const rules = {
      courseRequestQuizId: ["required"],
      displayName: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const { callApi, isLoading, backendValidation } = useApi({
      module: "acm",
      method: "updateCourseRequestChapterItemQuiz",
      onSuccess: async () => {
        showSuccess(
          "acm.courses.update.notification.saved.summary",
          "acm.courses.update.notification.saved.detail"
        );
        emit("refresh");
        await store.dispatch("acm/chapterItems/reloadChapterItemsList", true);
        await store.dispatch("acm/chapters/reloadChapterList", true);
      },
    });

    const { bvShowErrors, updateValidation } = useBackendValidation();
    watch(backendValidation, () => updateValidation(backendValidation.value));

    // Emit form values
    const submit = async () => {
      v$.value.$touch();
      if (v$.value.$error) return;
      await callApi(formValues);
    };

    watch(props.quizInformation, () => {
      formValues.courseRequestQuizId = props.quizInformation.courseRequestQuizId;
      formValues.displayName = props.quizInformation.displayName;
    });

    return {
      v$,
      isLoading,
      formValues,
      submit,
      bvShowErrors,
    };
  },
};
</script>
