import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_auth
 */

/**
 * Create teacher
 * @method
 * @param {object} params
 * @param {string} params.shortApplication
 * @returns {Promise<AxiosResponse<any>>}
 */
export const create = ({ shortApplication }) =>
  api.post(ROUTE.AUTH.CREATE, {
    shortApplication,
  });

/**
 * Register teacher
 * @method
 * @param {object} params
 * @param {string|null} params.invitationToken
 * @param {string} params.email
 * @param {string} params.salutation
 * @param {string} params.title
 * @param {string} params.firstName
 * @param {string} params.lastName
 * @param {string} params.password
 * @param {string} params.passwordConfirmation
 * @param {string} params.shortApplication
 * @returns {Promise<AxiosResponse<any>>}
 */
export const register = ({
  invitationToken,
  email,
  salutation,
  title,
  firstName,
  lastName,
  password,
  passwordConfirmation,
  shortApplication,
}) =>
  api.post(ROUTE.AUTH.REGISTER, {
    invitationToken,
    salutation,
    title,
    firstName,
    lastName,
    password,
    // eslint-disable-next-line camelcase
    password_confirmation: passwordConfirmation,
    email,
    shortApplication,
  });

/**
 * Register teacher with user
 * @method
 * @param {object} params
 * @param {string} params.shortApplication
 * @returns {Promise<AxiosResponse<any>>}
 */
export const registerWithUser = ({ shortApplication }) =>
  api.post(ROUTE.AUTH.REGISTER_WITH_USER, {
    shortApplication,
  });
