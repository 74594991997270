import i18n from "../i18n";

const { locale } = i18n.global;
const { value } = locale;

const regex = new RegExp(/(lang_)([a-z]*)([_]*)([A-Z]*)(\.json*)/);

const getLocalizedConfig = () => {
  const languageFile = require.context("../lang/", false, /(lang_)([a-z]*)([_]*)([A-Z]*)(\.json*)/);
  let config;

  languageFile.keys().forEach((key) => {
    const matches = key.match(regex);
    const locale = matches[2];

    if (locale === value) {
      config = languageFile(key);

      return;
    }
  });

  return config.primeVue;
};

export default {
  ripple: true,
  locale: getLocalizedConfig(),
};
