import { ref } from "vue";

/**
 * Process backend validation data
 *
 * @method
 * @returns {object} { updateValidation, bvMessage, bvShowErrors, bvMessages, bvHasErrors }
 */
const useBackendValidation = () => {
  const bvMessage = ref(null);
  const bvMessages = ref(null);
  const bvHasErrors = ref(false);

  const updateValidation = (validationData) => {
    bvMessage.value = validationData.message;
    bvMessages.value = validationData.errors;
    bvHasErrors.value = !!bvMessages.value || !!bvMessage.value;
  };

  /**
   * Returns error messages for specified form field
   *
   * @param {string} fieldName
   * @returns {object} {messages, hasMessages}
   */
  const bvShowErrors = (fieldName) => {
    const response = { messages: [], hasMessages: false };

    if (bvMessages.value) {
      if (fieldName in bvMessages.value) {
        response.messages = bvMessages.value[fieldName];
        response.hasMessages = true;
      }
    }

    return response;
  };

  return { updateValidation, bvMessage, bvShowErrors, bvMessages, bvHasErrors };
};

export default useBackendValidation;
