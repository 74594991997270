import { helpers } from "@vuelidate/validators";
import regex from "@utils/data/regex";

/**
 * Password validator
 *
 * @param {string} value
 * @returns {boolean}
 * @ignore
 */
export const password = helpers.withParams(
  { type: "password" },
  (value) => regex.passwordPolicy.test(value) || !value
);

/**
 * Password validator
 *
 * @param {string} value
 * @returns {boolean}
 * @ignore
 */
export const mfaToken = helpers.withParams(
  { type: "mfaToken" },
  (value) => regex.mfaToken.test(value) || !value
);

/**
 * Checkbox must be true
 *
 * @param {string} value
 * @returns {boolean}
 * @ignore
 */
export const checkBoxTrue = helpers.withParams(
  { type: "checkBoxTrue" },
  (value) => value === true && value !== false
);

export const passwordConfirmation = (param) =>
  helpers.withParams({ type: "passwordConfirmation", value: param }, (value) => value === param);
