<template>
  <div v-if="!isLoading" class="border-200">
    <BaseRow>
      <BaseCol>
        <BaseHeadline size="1" fake="2" :no-margin="true" class="mb-4">
          {{ courseRequest.title }}
        </BaseHeadline>

        <BaseParagraph v-if="courseRequest.subTitle">{{ courseRequest.subTitle }}</BaseParagraph>

        <BaseParagraph v-if="courseRequest.description">
          {{ courseRequest.description }}
        </BaseParagraph>

        <CourseTopics :course-topics="courseRequest.courseTopics" />
        <CustomCourseTopics :custom-course-topics="courseRequest.customCourseTopics" />
      </BaseCol>
      <BaseCol cols="shrink">
        <Thumbnail :url="courseRequest.thumbnailUrl" />
      </BaseCol>
    </BaseRow>

    <div v-for="(chapter, index) in courseRequest.courseRequestChapters" :key="chapter.id">
      <ChapterContainer :index="index" :courseRequestChapter="chapter" class="mt-6 mb-3" />
    </div>
  </div>
  <div v-else class="py-8 flex justify-content-center">
    <BaseProgressSpinner />
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { watch, ref } from "vue";
import CourseTopics from "@/modules/haw-components-hac/Base/CourseTopics";
import CustomCourseTopics from "./partials/topics/CustomCourseTopics";
import ChapterContainer from "./partials/ChapterContainer";
import Thumbnail from "./partials/Thumbnail";

export default {
  components: {
    Thumbnail,
    ChapterContainer,
    CourseTopics,
    CustomCourseTopics,
  },

  props: {
    courseRequestId: {
      type: undefined,
      required: true,
      default: null,
    },
  },

  setup(props) {
    const courseRequestId = ref(props.courseRequestId);
    const courseRequest = ref({});

    const { callApi, isLoading, docs } = useApi({
      module: "acm",
      method: "showCourseRequest",
      onMount: true,
      params: {
        courseRequestId: courseRequestId.value,
      },
      onSuccess() {
        courseRequest.value = docs.value.data;
      },
    });

    watch(courseRequestId, () => {
      callApi();
    });

    return {
      isLoading,
      courseRequest,
    };
  },
};
</script>
