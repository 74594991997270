<template>
  <BaseCard class="p-card--flat">
    <BaseRow class="justify-content-end p-2">
      <BaseCol cols="12">
        <BaseHeadline size="1" fake="4" :noMargin="true" class="my-3">
          {{ $t("acm.management.courseRequests.show.chapter") }} {{ index + 1 }}:
          {{ courseRequestChapter.displayName }}
        </BaseHeadline>
      </BaseCol>
      <BaseCol cols="12">
        <p class="mb-0 line-height-3" style="color: var(--text-color-secondary)">
          {{ courseRequestChapter.description }}
        </p>
      </BaseCol>
      <BaseCol cols="12">
        <CourseRequestChapterItems :course-request-chapter-id="courseRequestChapter.id" />
      </BaseCol>
    </BaseRow>
  </BaseCard>
</template>

<script>
import CourseRequestChapterItems from "./CourseRequestChapterItems";

export default {
  props: {
    courseRequestChapter: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        displayName: null,
        description: null,
      }),
    },
    index: {
      type: Number,
      required: true,
    },
  },

  components: {
    CourseRequestChapterItems,
  },
};
</script>

<style lang="scss" scoped>
.p-card--flat {
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>
