import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_usm_authUser
 */

/**
 * Retrieve auth user data
 *
 * @method
 * @returns {Promise<AxiosResponse<object>>}
 */
export const showAuthUser = () => api.get(ROUTE.AUTH_USER.SHOW);
