import { upperFirst, camelCase } from "lodash";

/**
 * Register base components
 *
 * @param {any} app
 */
const registerBaseComponents = (app) => {
  const requireComponent = require.context(
    "../../base-components-bc/Base",
    false,
    /Base[A-Z]\w+\.(vue|js)$/
  );

  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);

    const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1")));

    app.component(componentName, componentConfig.default || componentConfig);
  });
  Promise.resolve();
};

export default registerBaseComponents;
