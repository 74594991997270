import api from "@/api";
import ROUTE from "../routes/apiRoutes";

/**
 * @module API_acm_chapter_content
 */

/**
 * Store specified course chapter item content
 * @method
 * @param {object} params
 * @param {number} params.courseRequestChapterId
 * @param {string} params.content
 * @returns {Promise<AxiosResponse<any>>}
 */
export const storeCourseRequestChapterItemContent = ({
  courseRequestChapterId,
  displayName,
  content,
}) =>
  api.post(ROUTE.COURSES.REQUEST.CONTENT.STORE, { courseRequestChapterId, displayName, content });

/**
 * Update specified course chapter item content
 * @method
 * @param {object} params
 * @param {number} params.order
 * @param {number} params.courseRequestContentId
 * @param {string} params.content
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateCourseRequestChapterItemContent = ({
  order,
  courseRequestContentId,
  displayName,
  content,
}) =>
  api.put(ROUTE.COURSES.REQUEST.CONTENT.UPDATE, {
    order,
    courseRequestContentId,
    displayName,
    content,
  });

/**
 * Delete specified course chapter item content
 * @method
 * @param {object} params
 * @param {number} params.courseRequestContentId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteCourseRequestChapterItemContent = ({ courseRequestContentId }) =>
  api.delete(ROUTE.COURSES.REQUEST.CONTENT.DELETE, { params: { courseRequestContentId } });
